export class PartnerRefModel {
  id: string;
  name: string;
  slug: string;
}

export class PartnerRef {
  static create(model: PartnerRefModel): PartnerRef {
    const { id, name, slug } = model;
    return new PartnerRef(id, name, slug);
  }

  constructor(public id: string, public name: string, public slug: string) {}
}
