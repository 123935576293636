import Errors from 'modules/shared/error/errors';
import { setUrlPagination } from 'utils';

const getListActions = ({ service, selectors, prefix }) => {
  const actions = {
    FETCH_STARTED: `${prefix}_FETCH_STARTED`,
    FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
    FETCH_ERROR: `${prefix}_FETCH_ERROR`,

    RESETED: `${prefix}_RESETED`,

    PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
    SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,
    // TODO remove all below, because these are not common actions, and specific for partner
    TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
    TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,

    EXPORT_ALL_PARTNER_STARTED: `${prefix}_EXPORT_ALL_PARTNER_STARTED`,
    EXPORT_ALL_PARTNER_SUCCESS: `${prefix}_EXPORT_ALL_PARTNER_SUCCESS`,
    EXPORT_ALL_PARTNER_ERROR: `${prefix}_EXPORT_ALL_PARTNER_ERROR`,
  };
  const doFetch =
    (filter = {}, keepPagination = false, options = {}) =>
    async (dispatch, getState) => {
      const { orderBy, limit, offset } = options;

      try {
        dispatch({
          type: actions.FETCH_STARTED,
          payload: { filter, keepPagination, options },
        });

        const response = await service.list(
          filter,
          orderBy || selectors.selectOrderBy(getState()),
          limit || selectors.selectLimit(getState()),
          offset || selectors.selectOffset(getState()),
        );

        dispatch({
          type: actions.FETCH_SUCCESS,
          payload: {
            rows: response.rows,
            count: response.count,
          },
        });
      } catch (error) {
        const errorData = Errors.getErrorData(error);
        dispatch({
          type: actions.FETCH_ERROR,
          payload: errorData,
        });
      }
    };
  const doReset = () => async (dispatch) => {
    dispatch({
      type: actions.RESETED,
    });
  };
  const doToggleOneSelected = (id) => async (dispatch) => {
    dispatch({
      type: actions.TOGGLE_ONE_SELECTED,
      payload: id,
    });
  };
  const doToggleAllSelected = () => async (dispatch) => {
    dispatch({
      type: actions.TOGGLE_ALL_SELECTED,
    });
  };
  const doChangePagination = (pagination) => async (dispatch, getState) => {
    dispatch({
      type: actions.PAGINATION_CHANGED,
      payload: pagination,
    });
    setUrlPagination(pagination);

    let filter = {};
    if (selectors && selectors.selectFilter) {
      filter = selectors.selectFilter(getState());
    }
    dispatch(doFetch(filter, true));
  };
  const doChangeSort = (sorter) => async (dispatch, getState) => {
    dispatch({
      type: actions.SORTER_CHANGED,
      payload: sorter,
    });

    let filter = {};
    if (selectors && selectors.selectFilter) {
      filter = selectors.selectFilter(getState());
    }
    dispatch(doFetch(filter, true));
  };

  return {
    ...actions,
    doReset,
    doToggleOneSelected,
    doToggleAllSelected,
    doChangePagination,
    doChangeSort,
    doFetch,
  };
};

export default getListActions;
