import { parseDateFromString } from '~/utils/dateUtil';
import { BasicRef } from '../campaign';
import { Plan } from '~/types/models/plan';

export enum TrackingPixelWebpageType {
  Landing_Page = 'landing',
  Conversion_Page = 'conversion',
}

export enum ExportStatus {
  In_Progress = 'inProgress',
  Finished = 'finished',
}

export interface UserSiteURLs {
  fullReferer: string;
}

export interface TrackingPixelPlan {
  startDate: string;
  endDate: string;
  name: string;
  documentId: string;
}
export interface TrackingPixelModel {
  id: string;
  plans: TrackingPixelPlan[];
  createdAt: string;
  webpageName: string;
  webpageType: string;
  company: BasicRef<string>;
  partnerId: string;
  exportStatus: ExportStatus;
  userSiteURLs: UserSiteURLs[];
}

// Find the enum key for the given value
function getWebPageTypeKeyByValue(value: string): string | undefined {
  return Object.keys(TrackingPixelWebpageType).find(
    (key) => TrackingPixelWebpageType[key] === value,
  );
}

export class TrackingPixel {
  static create(model: TrackingPixelModel): TrackingPixel {
    const {
      id,
      company,
      plans,
      createdAt,
      webpageName,
      webpageType: webpageTypeValue,
      exportStatus,
      userSiteURLs,
    } = model;

    const webpageTypeKey = getWebPageTypeKeyByValue(webpageTypeValue);
    const webpageType = webpageTypeKey ? TrackingPixelWebpageType[webpageTypeKey] : undefined;

    return new TrackingPixel(
      id,
      company?.name,
      company?.id,
      plans,
      createdAt,
      webpageName,
      webpageType,
      exportStatus,
      userSiteURLs,
    );
  }

  public constructor(
    public id: string,
    public clientName: string,
    public companyId: string,
    public plans: TrackingPixelPlan[],
    public createdAtString: string,
    public webpageName: string,
    public webpageType: TrackingPixelWebpageType,
    public exportStatus: ExportStatus,
    public userSiteURLs: UserSiteURLs[],
  ) {}

  public get webpageTypeLabel(): string | undefined {
    return this.webpageType
      ? getWebPageTypeKeyByValue(this.webpageType)?.replaceAll('_', ' ')
      : undefined;
  }

  public get createdAt(): Date {
    return parseDateFromString(this.createdAtString);
  }
}
