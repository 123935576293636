import { set } from 'lodash';
import * as yup from 'yup';

export default class ExporterSchema {
  constructor(fields) {
    this.fields = fields;
    this.yupSchema = this.buildSchema();
  }

  get labels() {
    return this.fields.map((field) => field.label);
  }

  labelOf(name) {
    const field = this.#findFieldByName(this.fields, name);

    if (field) {
      return field.label;
    }

    return name;
  }

  buildSchema() {
    const shape = {};

    this.fields.forEach((field) => {
      shape[field.name] = field.forExport();
    });

    return yup.object().shape(shape).noUnknown(true);
  }

  cast(row) {
    try {
      const obj = {};
      const rawObject = this.yupSchema.cast(row);
      for (const [key, value] of Object.entries(rawObject)) {
        set(obj, key, value);
      }
      return obj;
    } catch (e) {
      console.log(e);
    }
  }

  #findFieldByName(fields, fieldName) {
    const field = fields.find((field) => {
      if (field.exportableFields && field.fields) {
        const nestedFieldsToArray = Object.values(field.fields);
        const nestedFilteredFields = nestedFieldsToArray.filter((nestedField) =>
          field.exportableFields.includes(nestedField.name),
        );
        return this.#findFieldByName(nestedFilteredFields, fieldName).name === fieldName;
      }
      return field.name === fieldName;
    });

    return field;
  }
}
