import moment from 'moment';
import * as yup from 'yup';

import GenericField from '../../../modules/shared/fields/genericField';

export default class DateTimeField extends GenericField {
  required: boolean;
  errorMessage: string;

  constructor(name: string, label: string, { required = false, errorMessage = undefined } = {}) {
    super(name, label);

    this.required = required;
    this.errorMessage = errorMessage;
  }

  forInput: undefined;

  forView(value: any) {
    return value ? moment(value).format('YYYY-MM-DD HH:mm') : null;
  }

  forFormInitialValue(value: any) {
    return value ? moment(value).toDate() : null;
  }

  forFilter() {
    return yup.mixed().label(this.label);
  }

  forForm() {
    let yupChain = yup.mixed().nullable(true).label(this.label);

    if (this.required) {
      yupChain = yupChain.required(this.errorMessage);
    }

    return yupChain;
  }

  forExport() {
    return yup.mixed().label(this.label);
  }

  forImport() {
    let yupChain = yup
      .mixed()
      .nullable(true)
      .label(this.label)
      .transform((value, originalValue) =>
        originalValue ? moment(originalValue, 'YYYY-MM-DD HH:mm').toISOString() : null,
      );

    if (this.required) {
      yupChain = yupChain.required();
    }

    return yupChain;
  }

  literalMapToValue: undefined;
}
