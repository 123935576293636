import authAxios from 'modules/shared/axios/authAxios';
import { AuditLog, AuditLogModel } from '~/types/models/auditlog/AuditLog';
import { WithRowsAndCount } from '~/types/modules/common';
import { AuditLogFilter } from '~/view/geopathSyncReport/hooks/useGetAuditLogsByParams';

export default class AuditLogService {
  static async fetch(
    filter: AuditLogFilter,
    orderBy: any,
    limit: number,
    offset: number,
  ): Promise<AuditLog[]> {
    const query = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get<WithRowsAndCount<AuditLogModel>>('/auditLog', {
      params: query,
    });

    return response.data.rows.map(AuditLog.create);
  }
}
