import form from 'modules/ooh/form/oohFormReducers';
import importer from 'modules/ooh/importer/oohImporterReducers';
import view from 'modules/ooh/view/oohViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  view,
  form,
  importer,
});
