import { isString } from 'lodash';
import * as yup from 'yup';

import { i18n } from '../../../i18n';
import GenericField from '../../../modules/shared/fields/genericField';
import { EnumeratorFieldValueItem, IEnumeratorFieldOptions, Options } from './fieldTypes';

export default class EnumeratorField extends GenericField {
  values: EnumeratorFieldValueItem[];

  required: boolean;

  delimiter?: string;

  constructor(
    name: string,
    label: string,
    values: EnumeratorFieldValueItem[],
    {
      required = false,
      dependentFieldSettings,
      delimiter = undefined,
    }: Options<IEnumeratorFieldOptions> = {},
  ) {
    super(name, label);

    this.values = values || [];
    this.required = required;
    this.dependentFieldSettings = dependentFieldSettings;
    this.delimiter = delimiter;
  }

  _id(value: any) {
    if (!value) {
      return value;
    }

    if (isString(value)) {
      return value;
    }

    return value.id;
  }

  _label(value: any) {
    if (!value) {
      return value;
    }

    if (isString(value)) {
      return value;
    }

    return value.label;
  }

  forView(value: any) {
    const option = this.values.find((valueItem: any) => valueItem.id === this._id(value));

    if (option) {
      return this._label(option);
    }

    return value;
  }

  forFormInitialValue(value: any) {
    if (Array.isArray(value)) {
      return value.map((option) => this._id(option));
    }

    return this._id(value);
  }

  forInput: undefined;

  forFilter() {
    return yup
      .string()
      .label(this.label)
      .oneOf([null, ...this.values.map((value: any) => this._id(value))]);
  }

  forForm() {
    let yupChain = yup.string().nullable(true).label(this.label);

    if (this.required) {
      yupChain = yupChain.required(i18n('validation.string.selected'));
    }

    if (this.dependentFieldSettings) {
      for (const dependentFieldSetting of this.dependentFieldSettings) {
        const [fieldName, settings] = Object.entries(dependentFieldSetting)[0];
        yupChain = yupChain.when(fieldName, settings);
      }
    }

    return yupChain;
  }

  forExport() {
    let yupChain;
    if (this.delimiter) {
      yupChain = yup
        .string()
        .nullable(true)
        .label(this.label)
        .oneOf([null, ...this.values.map((value: any) => this._id(value))] as const);

      const delimiter = this.delimiter;
      yupChain = yupChain.transform((value, originalValue) => {
        if (!originalValue) {
          return null;
        }

        if (Array.isArray(originalValue)) {
          return originalValue.join(delimiter ?? ' ');
        }

        return originalValue;
      });
    } else {
      yupChain = yup
        .string()
        .nullable(true)
        .label(this.label)
        .oneOf([null, ...this.values.map((value: any) => this._id(value))] as const);
    }

    if (this.dependentFieldSettings) {
      for (const dependentFieldSetting of this.dependentFieldSettings) {
        const [fieldName, settings] = Object.entries(dependentFieldSetting)[0];
        yupChain = yupChain.when(fieldName, settings);
      }
    }

    return yupChain;
  }

  forImport() {
    let yupChain;
    if (this.delimiter) {
      yupChain = yup
        .array()
        .nullable(true)
        .label(this.label)
        .of(
          yup.string().oneOf([null, ...this.values.map((value: any) => this._id(value))] as const),
        );

      const delimiter = this.delimiter;
      yupChain = yupChain.transform((value, originalValue) => {
        if (!originalValue) {
          return null;
        }

        if (Array.isArray(originalValue)) {
          return originalValue;
        }

        return originalValue
          .trim()
          .split(delimiter ?? ' ')
          .map((value: string) => value?.trim());
      });
    } else {
      yupChain = yup
        .string()
        .nullable(true)
        .label(this.label)
        .oneOf([null, ...this.values.map((value: any) => this._id(value))] as const);
    }

    if (this.required) {
      yupChain = yupChain.required(i18n('validation.string.selected'));
    }

    if (this.dependentFieldSettings) {
      for (const dependentFieldSetting of this.dependentFieldSettings) {
        const [fieldName, settings] = Object.entries(dependentFieldSetting)[0];
        yupChain = yupChain.when(fieldName, settings);
      }
    }

    return yupChain;
  }

  literalMapToValue(literal: any) {
    if (!literal) {
      return null;
    }

    if (this.delimiter && literal.includes(this.delimiter)) {
      const delimitedLiterals: string[] = literal.split(this.delimiter);
      return delimitedLiterals
        .map((literal) => this.literalMapToValue(literal))
        .join(this.delimiter);
    }

    const _stringifiedNormalizedLiteral = String(literal).trim().toLowerCase();

    for (const option of this.values) {
      const { literalValues } = option;
      const matches = literalValues && literalValues.includes(_stringifiedNormalizedLiteral);
      if (matches) {
        return option.id;
      }
    }
    return literal;
  }
}
