import { useEffect, useState } from 'react';
import { off, on } from '~/utils/events';
import { usePrevious } from 'react-use';

export const useRegisterEventOnce = (eventType: string, handler: () => void) => {
  const previousEventType = usePrevious<string>(eventType);
  const previousHandler = usePrevious<() => void>(handler);
  const [registered, setRegistered] = useState<boolean>(false);

  useEffect(() => {
    if (!registered || previousEventType !== eventType) {
      if (previousEventType !== eventType) {
        off(previousEventType, previousHandler);
      }

      on(eventType, handler);
      setRegistered(true);
    }
  }, [eventType, handler]);
};
