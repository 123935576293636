// Regexp
export const phoneRegExp =
  /^\+?\(?(\d{2,3})?[- ]?(\d{3})\)?[- ]?(\d{3})?[- ]?(\d{2})?[- ]?(\d{2})$/;
export const urlRegExp = new RegExp(
  '^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)/index.html/|/^$z/)?',
);
export const emailRegExp =
  /(?:[\u00A0-\uD7FF\uE000-\uFFFF-a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[\u00A0-\uD7FF\uE000-\uFFFF-a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[\u00A0-\uD7FF\uE000-\uFFFF-a-z0-9](?:[\u00A0-\uD7FF\uE000-\uFFFF-a-z0-9-]*[\u00A0-\uD7FF\uE000-\uFFFF-a-z0-9])?\.)+[\u00A0-\uD7FF\uE000-\uFFFF-a-z0-9](?:[\u00A0-\uD7FF\uE000-\uFFFF-a-z0-9-]*[\u00A0-\uD7FF\uE000-\uFFFF-a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi;
export const latitudeRegExp = new RegExp(/^[+-]?(([1-8]?[0-9])(\.[0-9]{1,8})?|90(\.0{1,8})?)$/);
export const longitudeRegExp = new RegExp(
  /^[+-]?((([1-9]?[0-9]|1[0-7][0-9])(\.[0-9]{1,8})?)|180(\.0{1,8})?)$/,
);

export const streetViewURLRegExp = new RegExp(
  /^(http[s]?:\/\/)(google.com|maps.google.com|www.google.com\/).*/,
);

// Yup
export const phoneModelOptions = {
  errorMessage: 'Incorrect Phone Number',
  min: 7,
  max: 35,
  required: false,
};

export const urlModelOptions = {
  matches: urlRegExp,
  errorMessage: 'Incorrect URL',
};

export const emailModelOptions = {
  matches: emailRegExp,
  errorMessage: 'Incorrect Email',
};

export const latitudeModelOptions = {
  matches: latitudeRegExp,
  errorMessage: 'Incorrect latitude',
  decimals: 6,
  allowNegative: true,
  type: 'number',
  tester: function (value: string) {
    const isValid = +value >= -85 && +value <= 85; // map shows markers from latitude -85 to 85
    return isValid;
  },
};

export const longitudeModelOptions = {
  matches: longitudeRegExp,
  errorMessage: 'Incorrect longitude',
  decimals: 6,
  allowNegative: true,
  type: 'number',
  tester: function (value: string) {
    const isValid = +value >= -180 && +value <= 180; // map shows markers from longitude -180 to 180
    return isValid;
  },
};

export const streetViewURLModelOptions = {
  matches: streetViewURLRegExp,
  errorMessage:
    'Incorrect google street view link, example link (http://maps.google.com/maps?q=&layer=c&cbll=000000,-000000)',
  min: 0,
  max: 1000,
  type: 'string',
  required: false,
};
