import { action, makeObservable, observable } from 'mobx';
import { TrackingPixel } from '~/types/models/analytics/TrackingPixel';
import { RootStoreInterface } from './root.store.interface';

export class AnalyticsStore {
  rootStore: RootStoreInterface;

  constructor(rootStore: RootStoreInterface) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable
  public pixelList: TrackingPixel[] = [];

  @action
  public setPixelList = (newPixelList: TrackingPixel[]) => {
    this.pixelList = newPixelList;
  };
}
