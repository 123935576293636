import { i18n } from 'i18n';
import _values from 'lodash/values';
import PermissionService from 'modules/permission/permissionService';

class Roles {
  static get values() {
    return {
      owner: 'owner',
      editor: 'editor',
      viewer: 'viewer',
      auditLogViewer: 'auditLogViewer',
      iamSecurityReviewer: 'iamSecurityReviewer',
      entityEditor: 'entityEditor',
      entityViewer: 'entityViewer',
      contentEditor: 'contentEditor',
      contentViewer: 'contentViewer',
      storefrontEditor: 'storefrontEditor',
      storefrontViewer: 'storefrontViewer',
      organizationEditor: 'organizationEditor',
      organizationViewer: 'organizationViewer',
      customerProfileEditor: 'customerProfileEditor',
      customerProfileViewer: 'customerProfileViewer',
      customerAudienceEditor: 'customerAudienceEditor',
      customerAudienceViewer: 'customerAudienceViewer',
      callToActionEditor: 'callToActionEditor',
      callToActionViewer: 'callToActionViewer',
      analyticsEventEditor: 'analyticsEventEditor',
      analyticsEventViewer: 'analyticsEventViewer',
      transactionEditor: 'transactionEditor',
      transactionViewer: 'transactionViewer',
      marketEditor: 'marketEditor',
      marketViewer: 'marketViewer',
      channelEditor: 'channelEditor',
      channelViewer: 'channelViewer',
      tagEditor: 'tagEditor',
      tagViewer: 'tagViewer',
      statusEditor: 'statusEditor',
      statusViewer: 'statusViewer',
      deviceEditor: 'deviceEditor',
      deviceViewer: 'deviceViewer',
      deviceProfileEditor: 'deviceProfileEditor',
      deviceProfileViewer: 'deviceProfileViewer',
      deviceCapabilityEditor: 'deviceCapabilityEditor',
      deviceCapabilityViewer: 'deviceCapabilityViewer',
      deviceNetworkEditor: 'deviceNetworkEditor',
      deviceNetworkViewer: 'deviceNetworkViewer',
      organizationTypeEditor: 'organizationTypeEditor',
      organizationTypeViewer: 'organizationTypeViewer',
      categoryEditor: 'categoryEditor',
      categoryViewer: 'categoryViewer',
      sectorEditor: 'sectorEditor',
      sectorViewer: 'sectorViewer',
      partnerViewer: 'partnerViewer',
      partnerEditor: 'partnerEditor',
      companyViewer: 'partnerViewer',
      companyEditor: 'partnerEditor',
      contactViewer: 'partnerViewer',
      contactEditor: 'partnerEditor',
      campaignViewer: 'campaignViewer',
      campaignEditor: 'campaignEditor',
      agencyCampaignEditor: 'agencyCampaignEditor',
      agencyCampaignViewer: 'agencyCampaignViewer',
      preferredVendorContactViewer: 'preferredVendorContactViewer',
      toolsOverviewPage: 'toolsOverviewPage',
      toolsPersonaBuilder: 'toolsPersonaBuilder',
      toolsPlaceRank: 'toolsPlaceRank',
      toolsRecipes: 'toolsRecipes',
      toolsSlideBuilder: 'toolsSlideBuilder',
      toolsCampaignPixelTracking: 'toolsCampaignPixelTracking',
      toolsThreads: 'toolsThreads',
      toolsFindMarketsByProfessions: 'toolsFindMarketsByProfessions',
      toolsTracker: 'toolsTracker',
      toolsMaps: 'toolsMaps',
      toolsDeals: 'toolsDeals',
      leadGenerator: 'leadGenerator',
      reportABug: 'reportABug',
      inventoryDirectoryV2: 'inventoryDirectoryV2',
      inventoryDirectoryV3: 'inventoryDirectoryV3',
      locationGroupViewer: 'locationGroupViewer',
      permissionSecurityReviewer: 'permissionSecurityReviewer',
      permissionSecurityEdit: 'permissionSecurityEdit',
      locationGroupEditor: 'locationGroupEditor',
      webliftAttribution: 'webliftAttribution',
    };
  }

  static labelOf(roleId) {
    if (!this.values[roleId]) {
      return roleId;
    }

    return i18n(`roles.${roleId}.label`);
  }

  static descriptionOf(roleId) {
    if (!this.values[roleId]) {
      return roleId;
    }

    return i18n(`roles.${roleId}.description`);
  }

  static get selectOptions() {
    return PermissionService.findAllRoles().then((roles) =>
      _values(roles).map((item) => ({
        id: item.id,
        value: item.label,
        title: this.descriptionOf(item.label),
        label: this.labelOf(item.label),
      })),
    );
  }
}

export default Roles;
