import { set } from 'lodash';
import statuses from 'modules/shared/importer/importerStatuses';
import * as yup from 'yup';
import { GenericField } from '../fields';

export default class ImporterSchema {
  fields: GenericField[] = [];
  yupSchema: yup.ObjectSchema;

  constructor(fields) {
    this.fields = fields;
    this.yupSchema = this.buildSchema();
  }

  buildSchema() {
    const shape = {};

    this.fields?.forEach((field) => {
      shape[field.name] = field.forImport && field.forImport();
    });

    return yup.object().shape(shape).noUnknown(true);
  }

  async castForDisplay(row, index) {
    const rowWithColumnNames = {};

    rowWithColumnNames._status = statuses.PENDING;
    rowWithColumnNames._line = index + 1;

    const rowValues = Object.values(row);

    this.fields.forEach((field, index) => {
      if (field.type === 'boolean') {
        rowWithColumnNames[field.name] = !!+field.literalMapToValue(rowValues[index]);
      } else {
        rowWithColumnNames[field.name] = field.literalMapToValue
          ? field.literalMapToValue(rowValues[index])
          : rowValues[index];
      }
    });

    try {
      const validatableRow = await this.castForValidation(rowWithColumnNames);
      await this.yupSchema.validate(validatableRow);
    } catch (error) {
      rowWithColumnNames._status = statuses.ERROR;
      rowWithColumnNames._errorMessage = error.message;
    }

    return rowWithColumnNames;
  }

  castFieldsForMap() {
    const fieldsWithCheckState = this.fields.map((field) => {
      field._checked = true;
      return field;
    });

    return fieldsWithCheckState;
  }

  async castForImport(row) {
    const obj = {};
    const rawObject = this.yupSchema.cast(row);
    for (const [key, value] of Object.entries(rawObject)) {
      set(obj, key, value);
    }
    return obj;
  }

  async validate(row) {
    return await this.yupSchema.validate(row, { abortEarly: false });
  }

  async castForValidation(row) {
    return this.yupSchema.cast(row);
  }

  get labels() {
    return this.fields.map((field) => field.label);
  }
}
