import { ThunkAction } from 'redux-thunk';
import { RootState } from '~/types/models/rootState';
import { BasicAction, BasicErrorAction } from '../../common';
import { PreviewEmail } from '~/view/campaign/agency/wizard/steps/EmailPreview';
import {
  AgencyCampaign,
  AgencyCampaignParameters,
  RequestForProposal,
} from '~/types/models/campaign';
import { Company } from '~/types/models/company';

const path = `AGENCY_CAMPAIGN`;

// String action labels
export const AgencyCampaignActionTypes = {
  CLEAR_SELECTION: `${path}_CLEAR_SELECTION`,
  SUCCESSFULLY_REDIRECTED: `${path}_SUCCESSFULLY_REDIRECTED`,
  FETCH_START: `${path}_FETCH_START`,
  FETCH_SUCCESS: `${path}_FETCH_SUCCESS`,
  FETCH_ERROR: `${path}_FETCH_ERROR`,
  FETCH_AGENCY_CAMPAIGN_START: `${path}_FETCH_AGENCY_CAMPAIGN_START`,
  FETCH_AGENCY_CAMPAIGN_SUCCESS: `${path}_FETCH_AGENCY_CAMPAIGN_SUCCESS`,
  FETCH_AGENCY_CAMPAIGN_ERROR: `${path}_FETCH_AGENCY_CAMPAIGN_ERROR`,
  FETCH_STEP_TWO_START: `${path}_FETCH_STEP_TWO_START`,
  FETCH_STEP_TWO_SUCCESS: `${path}_FETCH_STEP_TWO_SUCCESS`,
  FETCH_STEP_TWO_ERROR: `${path}_FETCH_STEP_TWO_ERROR`,
  FETCH_EMAIL_PREVIEW_START: `${path}_FETCH_EMAIL_PREVIEW_START`,
  FETCH_EMAIL_PREVIEW_SUCCESS: `${path}_FETCH_EMAIL_PREVIEW_SUCCESS`,
  FETCH_EMAIL_PREVIEW_ERROR: `${path}_FETCH_EMAIL_PREVIEW_ERROR`,
  FETCH_RFP_START: `${path}_FETCH_RFP_START`,
  FETCH_RFP_SUCCESS: `${path}_FETCH_RFP_SUCCESS`,
  FETCH_RFP_ERROR: `${path}_FETCH_RFP_ERROR`,
  FETCH_PARAMETERS_START: `${path}_FETCH_PARAMETERS_START`,
  FETCH_PARAMETERS_SUCCESS: `${path}_FETCH_PARAMETERS_SUCCESS`,
  FETCH_PARAMETERS_ERROR: `${path}_FETCH_PARAMETERS_ERROR`,
  SEND_EMAIL_START: `${path}_SEND_EMAIL_START`,
  SEND_EMAIL_SUCCESS: `${path}_SEND_EMAIL_SUCCESS`,
  SEND_EMAIL_ERROR: `${path}_SEND_EMAIL_ERROR`,
  SELECT_START: `${path}_SELECT_START`,
  SELECT_SUCCESS: `${path}_SELECT_SUCCESS`,
  SELECT_ERROR: `${path}_SELECT_ERROR`,
  FETCH_COMPANY_START: `${path}_FETCH_COMPANY_START`,
  FETCH_COMPANY_SUCCESS: `${path}_FETCH_COMPANY_SUCCESS`,
  FETCH_COMPANY_ERROR: `${path}_FETCH_COMPANY_ERROR`,
  CREATE_START: `${path}_CREATE_START`,
  CREATE_SUCCESS: `${path}_CREATE_SUCCESS`,
  CREATE_ERROR: `${path}_CREATE_ERROR`,
  REPLACE_RFP_START: `${path}_REPLACE_RFP_START`,
  REPLACE_RFP_SUCCESS: `${path}_REPLACE_RFP_SUCCESS`,
  REPLACE_RFP_ERROR: `${path}_REPLACE_RFP_ERROR`,
  UPDATE_START: `${path}_UPDATE_START`,
  UPDATE_SUCCESS: `${path}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${path}_UPDATE_ERROR`,
  REPLACE_PARAMETERS_START: `${path}_REPLACE_PARAMETERS_START`,
  REPLACE_PARAMETERS_SUCCESS: `${path}_REPLACE_PARAMETERS_SUCCESS`,
  REPLACE_PARAMETERS_ERROR: `${path}_REPLACE_PARAMETERS_ERROR`,
  AGENCY_CAMPAIGN_UPDATE_SUCCESS: `${path}_AGENCY_CAMPAIGN_UPDATE_SUCCESS`,
} as const;

type AgencyCampaignActionTypeLiterals = typeof AgencyCampaignActionTypes;

type BasicAgencyCampaignActions =
  | BasicAction<
      AgencyCampaignActionTypeLiterals,
      AgencyCampaign | Company[] | RequestForProposal | AgencyCampaignParameters | PreviewEmail
    >
  | BasicErrorAction<AgencyCampaignActionTypeLiterals, unknown>;

export interface AgencyCampaignActions {
  doClearSelection: () => ThunkAction<void, RootState, unknown, BasicAgencyCampaignActions>;
  doSuccessfullyRedirected: () => ThunkAction<void, RootState, unknown, BasicAgencyCampaignActions>;
  doFetch: () => ThunkAction<void, RootState, unknown, BasicAgencyCampaignActions>; // TODO Create types for single actions better
  doFetchAgencyCampaign: (
    agencyCampaignId: string,
  ) => ThunkAction<void, RootState, unknown, BasicAgencyCampaignActions>;
  doFetchEmailPreview: (
    agencyCampaignId: string,
  ) => ThunkAction<void, RootState, unknown, BasicAgencyCampaignActions>;
  doFetchStepTwo: (
    agencyCampaignId: string,
  ) => ThunkAction<void, RootState, unknown, BasicAgencyCampaignActions>;
  doSendEmail: (agencyCampaignId: string) => ThunkAction<void, RootState, unknown, void>;
  doFetchRFPs: (
    agencyCampaignId: string,
  ) => ThunkAction<void, RootState, unknown, BasicAgencyCampaignActions>; // TODO Create types for single actions better
  doFetchParameters: (
    agencyCampaignId: string,
  ) => ThunkAction<void, RootState, unknown, BasicAgencyCampaignActions>; // TODO Create types for single actions better
  doFetchCompanies: () => ThunkAction<void, RootState, unknown, BasicAgencyCampaignActions>;
  selectRows: (selectRows) => ThunkAction<void, RootState, unknown, BasicAgencyCampaignActions>; // TODO Create types for single actions better
  doCreate: (
    data: AgencyCampaign,
  ) => ThunkAction<void, RootState, unknown, BasicAgencyCampaignActions>; // TODO Create types for single actions better
  doUpdate: (
    agencyCampaignId: string,
    data: AgencyCampaign,
  ) => ThunkAction<void, RootState, unknown, BasicAgencyCampaignActions>;
  doCreateAndReplaceRFPs: (
    agencyCampaignId: string,
    createRfpData: string[],
    replaceParameters: AgencyCampaignParameters,
    isSendAdditionalRFPsMode: boolean,
  ) => ThunkAction<void, RootState, unknown, BasicAgencyCampaignActions>;
}
