export interface AuditLogModel {
  id: string;
  entityName: string;
  entityId: string;
  action: string;
  createdById: string;
  createdByEmail: string;
  timestamp: string;
  values: Record<string, any>;
  previousValues: Record<string, any>;
}

export class AuditLog {
  static create(model: AuditLogModel): AuditLog {
    const {
      id,
      entityName,
      entityId,
      action,
      createdById,
      createdByEmail,
      timestamp,
      values,
      previousValues,
    } = model;

    return new AuditLog(
      id,
      entityName,
      entityId,
      action,
      createdById,
      createdByEmail,
      timestamp,
      values,
      previousValues,
    );
  }

  public constructor(
    public id: string,
    public entityName: string,
    public entityId: string,
    public action: string,
    public createdById: string,
    public createdByEmail: string,
    public timestamp: string,
    public values: Record<string, any>,
    public previousValues: Record<string, any>,
  ) {}
}
