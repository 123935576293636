import { FC, forwardRef, PropsWithChildren } from 'react';

import { generateUtilityClasses, SxProps, Theme } from '@mui/material';
import clsx from 'clsx';
import { BaseAlert, BaseAlertClasses, BaseAlertProps } from '~/view/shared/OSAIAlert/BaseAlert';

const classes = {
  ...generateUtilityClasses('InfoAlert', ['root']),
};

const styles = {
  [`&.${classes.root}`]: {
    background: (theme) => theme.palette.info.background,
    color: (theme) => theme.palette.info.dark,
    [`& svg`]: {
      fill: (theme) => theme.palette.info.dark,
    },
  },
  [`.${BaseAlertClasses.close}`]: {
    [`& svg`]: {
      fill: (theme) => theme.palette.info.dark,
    },
  },
};

export interface InfoAlertProps extends BaseAlertProps {
  sx?: SxProps<Theme>;
  className?: string;
}

const InfoAlert: FC<InfoAlertProps> = forwardRef<HTMLDivElement, InfoAlertProps>(
  ({ className, sx, children, ...props }: PropsWithChildren<InfoAlertProps>, ref) => {
    return (
      <BaseAlert
        ref={ref}
        {...props}
        variant="outlined"
        severity="info"
        className={clsx(className, classes.root)}
        sx={Array.isArray(sx) ? sx.concat([styles]) : { ...styles, ...sx }}
      >
        {children}
      </BaseAlert>
    );
  },
);

InfoAlert.displayName = 'InfoAlert';
export { InfoAlert };

export { classes as InfoAlertClasses };
