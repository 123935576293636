import {
  Backdrop,
  Box,
  CircularProgress,
  generateUtilityClasses,
  SxProps,
  Theme,
} from '@mui/material';
import clsx from 'clsx';

const classes = {
  ...generateUtilityClasses('OSAISpinner', ['root', 'spinner', 'backdrop']),
};

const styles = {
  [`& .${classes.backdrop}`]: {
    color: '#fff',
    zIndex: (theme) => theme.zIndex.drawer + 1,
  },
};

export interface OSAISpinnerProps {
  sx?: SxProps<Theme>;
  className?: string;
  color?: string;
  hasBackdrop?: boolean;
  openBackdrop?: boolean;
  size?: number;
  onBackdropClick?: () => void;
}

export function OSAISpinner({
  className,
  sx,
  color,
  hasBackdrop = false,
  openBackdrop = true,
  size = 100,
  onBackdropClick,
}: OSAISpinnerProps) {
  const renderSpinner = () => (
    <CircularProgress
      className={classes.spinner}
      sx={color ? { color } : { color: 'primary.main' }}
      size={size}
    />
  );

  const onBackdropClicked = () => onBackdropClick?.();

  return (
    <Box
      className={clsx(className, classes.root)}
      sx={Array.isArray(sx) ? sx.concat([styles]) : { ...styles, ...sx }}
    >
      {hasBackdrop ? (
        <Backdrop className={classes.backdrop} open={openBackdrop} onClick={onBackdropClicked}>
          {renderSpinner()}
        </Backdrop>
      ) : (
        renderSpinner()
      )}
    </Box>
  );
}

export { classes as OSAISpinnerClasses };
