import authAxios from 'modules/shared/axios/authAxios';
import { trigger } from '~/utils/events';

export default class CartService {
  static async addItem(inventoryId) {
    const response = await authAxios.put(`/user/cart/${inventoryId}`);
    trigger('updatedCart');
    return response.data;
  }

  static async deleteItems(inventoryIds) {
    const response = await authAxios.post(`/user/cart/delete`, { ids: inventoryIds });
    trigger('updatedCart');
    return response.data;
  }

  static async addItems(data) {
    const response = await authAxios.post('/user/cart', data);
    trigger('updatedCart');
    return response.data;
  }

  static async getCart() {
    const response = await authAxios.get('/cart/user');
    return response.data;
  }

  static async getCartCount() {
    const response = await authAxios.get(`/cart/count`);
    return response.data;
  }

  static async cartTo(toComponent) {
    const response = await authAxios.post('/user/cart/to', { toComponent });
    return response.data;
  }

  static async clearCart() {
    const response = await authAxios.delete('/user/cart');
    return response.data;
  }
}
