import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Badge from '@mui/material/Badge';
import { Alert as AlertClassic } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import CartService from '~/modules/cart/cartService';
import React, { useState } from 'react';
import { trigger } from '../../utils/events';
import { OSAIButton } from '~/view/shared/osaiButton';
import { ClearCartPopover } from '~/view/cart/ClearCartPopover';
import { OnCloseTarget } from '~/view/shared/dialogs/osaiPopover';
import { OSAISpinner } from '~/view/shared/osaiSpinner';
import { useRegisterEventOnce } from '~/utils/hooks/useRegisterEventOnce';
import { useLoadInitialCartItems } from '~/view/cart/hooks/useLoadInitialCartItems';
import Spinner from '~/view/shared/Spinner';
import { useIsAnonymous } from '~/utils/hooks';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
}))(MuiAccordionDetails);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '0px 0',
    },
    margin: '0px 0 0',
    padding: '0px 0 0',
  },
  expanded: {},
})(MuiAccordionSummary);

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    anchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(0),
  },
}));

const Cart = () => {
  const classes = useStyles();
  const [cartItems, setCartitems] = useState([]);
  const [cartItemCount, setCartitemCount] = useState(0);
  const [openCart, setOpenCart] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openClearCart, setOpenClearCart] = useState(false);
  const [cartClearingInProgress, setCartClearingInProgress] = useState(false);
  const inventoryIds = cartItems.map(({ inventoryId }) => inventoryId);
  const isAnonymous = useIsAnonymous();

  useRegisterEventOnce('updatedCart', async (doOpenCart = false) => {
    if (!isAnonymous) {
      const cartCount = await CartService.getCartCount();
      setCartitemCount(cartCount);
      if (doOpenCart === true) {
        setOpenCart(true);
      }
    }
  });

  useLoadInitialCartItems();

  React.useEffect(() => {
    if (cartClearingInProgress) {
      setCartClearingInProgress(false);
    }
  }, [cartItems]);

  const toggleOpenCart = async () => {
    setOpenCart(!openCart);
    setLoading(true);
    const newCart = await CartService.getCart();
    setCartitemCount(newCart?.items?.length);
    setCartitems(newCart?.items);
    setLoading(false);
  };

  async function deleteCartItem(itemId) {
    setCartClearingInProgress(true);
    await CartService.deleteItems([itemId]);
    trigger('updatedCartPage');
    trigger('updatedCart', false);
  }

  const dmasCount = new Set([...cartItems.map((b) => b?.dmaId)])?.size;
  const countyCount = new Set([...cartItems.map((b) => b?.countyId)])?.size;
  const zipCount = new Set([...cartItems.map((b) => b?.zip)])?.size;
  const impressionsWeekly = cartItems?.length
    ? [
        ...cartItems
          .filter((b) => b?.weeklyAdultImpressionsCount)
          .map((b) => b?.weeklyAdultImpressionsCount),
        0,
      ].reduce((prev, curr) => prev + curr)
    : 0;

  const handleOnClearCartClick = () => {
    setOpenClearCart(true);
  };

  const reloadOnCartPage = () => {
    if (window.location.pathname === '/cart') {
      window.location.reload();
    }
  };

  const handleOnClearCart = async (target) => {
    if (target === OnCloseTarget.Close) {
      setCartClearingInProgress(true);
      await CartService.clearCart();
      trigger('updatedCart', false);

      reloadOnCartPage();
    }
    setOpenClearCart(false);
  };

  return (
    <div
      style={{
        fontFamily:
          'proxima-nova,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji',
      }}
    >
      <MenuItem onClick={toggleOpenCart}>
        <IconButton aria-label="Notifications" size="large" style={{ color: 'black' }}>
          <Badge badgeContent={cartItemCount} color="secondary">
            <ShoppingCartIcon />
          </Badge>
        </IconButton>
      </MenuItem>
      <Drawer
        anchor="right"
        PaperProps={{
          sx: { maxWidth: '450px' },
        }}
        open={openCart}
        onClose={toggleOpenCart}
      >
        {cartItemCount !== 0 ? (
          <>
            <AlertClassic severity="success">{`${cartItemCount} Items reaching ${impressionsWeekly.toLocaleString()} across ${dmasCount} DMA(s), ${countyCount} counties, ${zipCount} zip(s)`}</AlertClassic>
            {cartClearingInProgress ? (
              <OSAISpinner hasBackdrop={true} />
            ) : (
              <ClearCartPopover open={openClearCart} onClose={handleOnClearCart} />
            )}
            <OSAIButton
              active={true}
              className={classes.button}
              onClick={() => {
                window.location = '/cart';
              }}
            >
              View Cart
            </OSAIButton>
            <OSAIButton
              disabled={inventoryIds?.length === 0}
              active={false}
              className={classes.button}
              onClick={handleOnClearCartClick}
            >
              Clear Cart
            </OSAIButton>
            {cartItems.map((inventory) => (
              <>
                <Accordion
                  square
                  style={{
                    paddingBottom: '0px',
                    paddingTop: '0px',
                    marginBottom: '0px',
                    marginTop: '0px',
                    margin: 0,
                    padding: 0,
                  }}
                  key={`${inventory.id}`}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <div style={{ alignItems: 'left', width: '70%', marginTop: '10px' }}>
                      <div style={{ fontSize: '19px' }}>
                        <Typography>
                          <a href={`/location/${inventory.id}`} target="_blank" rel="noreferrer">
                            {inventory.name}
                          </a>{' '}
                          {inventory.weeklyAdultImpressionsCount !== undefined &&
                          inventory.weeklyAdultImpressionsCount !== null
                            ? `Reaching ${inventory.weeklyAdultImpressionsCount}`
                            : ``}
                        </Typography>
                      </div>
                      <div style={{ fontSize: '10px' }}>
                        <Typography style={{ fontSize: '14px' }}>
                          {`${inventory.description !== null ? inventory.description : ''}`} from{' '}
                          <a
                            href={`/directory/company/${inventory.partnerSlug}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {inventory.partnerName}
                          </a>{' '}
                        </Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        alignItems: 'right',
                        width: '30%',
                        textAlign: 'right',
                        marginTop: '20px',
                      }}
                    >
                      <span style={{ fontSize: '17px', marginRight: '-1px' }}>
                        <Typography>
                          <b>{inventory.cost === null ? `Cost TBD` : `$${inventory.cost}`}</b>
                        </Typography>
                      </span>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: 0, margin: 0 }}>
                    <Typography>
                      <List dense={true} style={{ marginBottom: '-8px' }}>
                        <ListItem
                          style={{ minWidth: '350px', maxWidth: '500px' }}
                          key={inventory.id}
                          divider
                          role="listitem"
                        >
                          <ListItemText
                            style={{ marginTop: '-14px' }}
                            primary={
                              <React.Fragment>
                                {inventory['dateFrom'] !== null && inventory['dateTo'] !== null
                                  ? inventory['dateFrom'] + '-' + inventory['dateTo']
                                  : ``}
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>{`${
                                inventory.physicalAddress !== null ? inventory.physicalAddress : ``
                              } ${inventory.city} ${inventory.state} ${
                                inventory.zip
                              }`}</React.Fragment>
                            }
                          />
                          <ListItemSecondaryAction>
                            <div style={{ marginBottom: '0px' }}>
                              <span style={{ fontSize: '15px', marginRight: '6px' }}>
                                <b></b>
                              </span>
                              <React.Fragment>
                                <IconButton
                                  aria-label="delete"
                                  size="small"
                                  style={{ top: '-16px', right: '-3px' }}
                                  onClick={() => {
                                    deleteCartItem(inventory.id);
                                  }}
                                >
                                  <DeleteIcon style={{ marginTop: '4px', marginBottom: '-6px' }} />
                                </IconButton>
                              </React.Fragment>
                            </div>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </List>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </>
            ))}
          </>
        ) : (
          <></>
        )}
        {cartItemCount === 0 ? (
          <ListItem style={{ minWidth: '350px' }} divider>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography>
                    <center>Your cart is empty</center>
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        ) : (
          <>
            {false && (
              <>
                <ListItem style={{ minWidth: '350px', textAlign: 'right' }} divider>
                  <ListItemText primary={`Platform Fee: 0`} secondary="" />
                </ListItem>
                <ListItem style={{ minWidth: '350px', textAlign: 'right' }} divider>
                  <ListItemText primary={`Total: 0`} secondary="" />
                </ListItem>
              </>
            )}
          </>
        )}
        {loading ? <Spinner /> : null}
      </Drawer>
    </div>
  );
};

export default Cart;
