export const flattenObject = (input: Record<string, any>, keyName: string) => {
  let result = {};
  for (const key in input) {
    const newKey = keyName ? `${keyName}.${key}` : key;
    if (typeof input[key] === 'object' && !Array.isArray(input[key])) {
      result = { ...result, ...flattenObject(input[key], newKey) };
    } else {
      result[newKey] = input[key];
    }
  }
  return result;
};
