import PermissionChecker from 'modules/auth/permissionChecker';
import { FC, ReactNode } from 'react';
import { Redirect, Route } from 'react-router-dom';
import RouteLayout from './RouteLayout';
import { RouteComponentProps } from 'react-router';

interface PrivateRouteProps extends RouteComponentProps {
  component: ReactNode;
  currentUser: unknown;
  permissionRequired: unknown;
  hideBars: boolean;
  hideBarButtons: boolean;
}

const PrivateRoute: FC<PrivateRouteProps> = ({
  component: Component,
  currentUser,
  permissionRequired,
  hideBars,
  hideBarButtons,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const permissionChecker = new PermissionChecker(currentUser);

        if (!permissionChecker.isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: '/auth/signin',
                state: { from: props.location },
              }}
            />
          );
        }

        if (!permissionChecker.isEmailVerified) {
          return <Redirect to="/auth/email-unverified" />;
        }
        if (!currentUser.tosAgreed) {
          return <Redirect to="/terms" />;
        }

        if (permissionChecker.isEmptyPermissions) {
          return <Redirect to="/auth/empty-permissions" />;
        }

        if (!permissionChecker.match(permissionRequired)) {
          return <Redirect to="/auth/signin" />;
        }

        return (
          <RouteLayout
            {...props}
            component={Component}
            hideBarsDefault={true}
            hideBars={hideBars}
            hideBarButtons={hideBarButtons}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
