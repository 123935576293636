import { BasicMediaModel } from '../shared/BasicMedia';

export class FilestackRefModel {
  handle: string;
  filename: string;
  mimetype: string;
  url: string;
  virusScanStatus: string;
}

export class FilestackRef {
  static create(model: FilestackRefModel): FilestackRef {
    const { handle, filename, mimetype, url } = model;
    return new FilestackRef(handle, filename, mimetype, url);
  }

  static createFromBasicMedia(model: BasicMediaModel): FilestackRef {
    const { handle, name, type, url } = model;
    return new FilestackRef(handle, name, type, url);
  }

  constructor(
    public handle: string,
    public filename: string,
    public mimetype: string,
    public url: string,
  ) {}
}
