import Axios from 'axios';
import config from 'config';
import { getLanguageCode } from 'i18n';
import { AuthToken } from 'modules/auth/authToken';
import moment from 'moment';
// do we really need this library?
// looking at how we are using it here, it doesn't seem necessary to me
import Qs from 'qs';

const authAxios = Axios.create({
  baseURL: config.backendUrl,
  paramsSerializer(params) {
    return Qs.stringify(params, {
      arrayFormat: 'brackets',
      filter: (prefix, value) => {
        if (value instanceof moment || value instanceof Date) {
          return value.toISOString();
        }

        return value;
      },
    });
  },
});

authAxios.interceptors.request.use(
  async (options) => {
    const token = AuthToken.get();

    if (token) {
      options.headers.Authorization = `Bearer ${token}`;
    }

    options.headers['Accept-Language'] = getLanguageCode();

    return options;
  },
  (error) => {
    console.log('Request error: ', error);
    return Promise.reject(error);
  },
);

window.authAxios = authAxios;

export default authAxios;
