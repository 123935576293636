import { generateUtilityClasses, SxProps, Theme } from '@mui/material';
import clsx from 'clsx';
import { OSAIPopover } from '~/view/shared/dialogs/osaiPopover';

const classes = {
  ...generateUtilityClasses('ClearCartPopover', ['root', 'instructions']),
};

const styles: SxProps<Theme> = {
  [`&.${classes.root}`]: {
    left: '30%',
    top: '20%',
  },
  [`& .${classes.instructions}`]: {
    padding: '2em 1em',
  },
} as SxProps<Theme>;

export interface ClearCartPopoverProps {
  sx?: SxProps<Theme>;
  className?: string;
  open: boolean;
  onClose?: () => void;
}

export function ClearCartPopover({ className, sx, open, onClose }: ClearCartPopoverProps) {
  return (
    <OSAIPopover
      className={clsx(className, classes.root)}
      sx={(Array.isArray(sx) ? sx.concat([styles]) : { ...styles, ...sx }) as SxProps<Theme>}
      open={open}
      title="Empty the Cart"
      closeButtonText="Ok"
      onClose={onClose}
      cancelButtonText="Cancel"
    >
      Are you sure you want to clear your cart?
    </OSAIPopover>
  );
}

export { classes as ClearCartPopoverClasses };
