import { formattingNumberIntoPercentages } from '~/view/shared/utils';

export type AttributionReportTypeModel = 'midReview' | 'endReview';

export type PlanBasicRefModel = {
  id: string;
  name: string;
  documentId: string;
};

export type AttributionReportModel = {
  id: string;
  reportType: AttributionReportTypeModel;
};

export type InventoryAttributionModel = {
  plan: PlanBasicRefModel;
  attributionReport: AttributionReportModel;
  averageExposedMaids: number;
  visitationRate: number;
  landingPageLiftRate: number;
  overallLandingPageLiftRate: number;
};

export class InventoryAttribution {
  static create(model: InventoryAttributionModel) {
    return new InventoryAttribution(
      model.plan.documentId,
      model.plan.name,
      model.attributionReport.id,
      model.attributionReport.reportType,
      model.averageExposedMaids,
      model.visitationRate,
      model.landingPageLiftRate,
      model.overallLandingPageLiftRate,
    );
  }

  public constructor(
    private planDocumentId: string,
    private planNameRaw: string,
    private attributionReportIdRaw: string,
    private attributionReportTypeRaw: AttributionReportTypeModel,
    private averageExposedMaidsRaw: number,
    private visitationRateRaw: number,
    private landingPageLiftRateRaw: number,
    private overallLandingPageLiftRateRaw: number,
  ) {}

  public get documentId(): string {
    return this.planDocumentId;
  }

  public get planName(): string {
    return this.planNameRaw;
  }

  public get attributionReportId(): string {
    return this.attributionReportIdRaw;
  }

  public get attributionReportName(): string {
    return `${this.planName}`;
  }

  public get attributionReportTypeEnum(): AttributionReportTypeModel {
    return this.attributionReportTypeRaw;
  }

  public get attributionReportType(): string {
    let reportType = '';
    switch (this.attributionReportTypeRaw) {
      case 'midReview':
        reportType = 'Mid of Campaign Review';
        break;
      case 'endReview':
        reportType = 'End of Campaign Review';
        break;
      default:
        break;
    }
    return reportType;
  }

  public get averageExposedMaids(): number {
    return this.averageExposedMaidsRaw;
  }

  public get visitationRate(): string {
    return formattingNumberIntoPercentages(this.visitationRateRaw);
  }

  public get landingPageLiftRate(): string {
    return formattingNumberIntoPercentages(this.landingPageLiftRateRaw);
  }

  public get overallLandingPageLiftRate(): string {
    return formattingNumberIntoPercentages(this.overallLandingPageLiftRateRaw);
  }
}
