import { RootStoreInterface } from './root.store.interface';
import { AnalyticsStore } from './analytics.store';
import { inject, observer } from 'mobx-react';
import { IReactComponent } from 'mobx-react/dist/types/IReactComponent';

export class RootStore implements RootStoreInterface {
  public analyticsStore: AnalyticsStore = new AnalyticsStore(this);
}

export const rootStore = new RootStore();
export const withRootStore = (element: IReactComponent) => inject('rootStore')(observer(element));
