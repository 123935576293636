import XLSX from 'xlsx';

import { i18n } from '../../../i18n';
import { Excel } from '../excel/excel';
import { GenericField } from '../fields';
import ImporterSchema from './importerSchema';

import { Buffer } from 'buffer';
import csv from 'csvtojson';

window.Buffer = Buffer;

type ConfigOptions = {
  raw?: boolean;
  sheet?: string;
  skipRows?: number;
};

interface IJsonDataElement {
  [key: string]: string | undefined;
}

type JsonDataType = IJsonDataElement[] | undefined;

export default class Importer {
  schema;

  constructor(fields?: GenericField[]) {
    this.schema = new ImporterSchema(fields);
  }

  downloadTemplate(templateFileName: any) {
    return Excel.exportAsExcelFile([], this.schema.labels, templateFileName);
  }

  async castForDisplay(row: any, index: number) {
    return this.schema.castForDisplay(row, index);
  }

  async castForImport(row: any) {
    return this.schema.castForImport(row);
  }

  async validateImportRow(row: any) {
    return await this.schema.validate(row);
  }

  castFieldsForMap() {
    return this.schema.castFieldsForMap();
  }

  async convertExcelFileToJson(file: File, options?: ConfigOptions): Promise<JsonDataType> {
    const aoa = await this._xlsx_to_aoa(file, options);

    if (!aoa) {
      return;
    }

    const json = this._aoa_to_json(aoa);

    return json;
  }

  async convertExcelFileToCsvToJson(file: File, options?: ConfigOptions): Promise<JsonDataType> {
    const json = await this._xlsx_to_json(file, options);

    return json as unknown as JsonDataType;
  }

  async CSVFileToJson(file: File, options?: ConfigOptions): Promise<JsonDataType> {
    return await this._csv_to_json(file, options);
  }

  async sheetToCSV(file: File): Promise<string> {
    return this.sheet_to_csv(file);
  }

  async convertCSVFileToJson(file: File): Promise<JsonDataType> {
    const aoa = await this._csv_to_aoa(file);

    if (!aoa) {
      return;
    }

    const json = this._aoa_to_json(aoa);

    return json;
  }

  async _convertFileToWorkbook(file: File, options?: ConfigOptions) {
    try {
      const data = await this._readFile(file);
      return XLSX.read(data, {
        type: 'array',
        ...(options ?? {}),
      });
    } catch (error) {
      throw new Error(i18n('importer.errors.invalidFileUpload'));
    }
  }

  async _readFile(file: File) {
    if (!file) {
      return null;
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        try {
          if (e.target) {
            const data = new Uint8Array(e.target.result as Buffer);
            resolve(data);
          }
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = (e) => {
        reject();
      };

      reader.readAsArrayBuffer(file);
    });
  }

  _skipRows(sheet: XLSX.WorkSheet, skipRows) {
    const newSheet = {} as XLSX.WorkSheet;
    for (const [key, value] of Object.entries(sheet)) {
      const row = Number(key.replace(/\D/g, ''));
      let skipRow = false;
      for (let i = 1; i <= skipRows; i++) {
        if (row === i) {
          skipRow = true;
          break;
        }
      }
      if (!skipRow) {
        newSheet[key] = value;
      }
    }
    return newSheet;
  }

  async _xlsx_to_json(xlsx: File, options?: ConfigOptions): Promise<any[] | undefined> {
    const workbook: XLSX.WorkBook = await this._convertFileToWorkbook(xlsx, options);
    const sheet = workbook.Sheets[options?.sheet ?? workbook.SheetNames[0]];

    const csvString = XLSX.utils.sheet_to_csv(
      options?.skipRows ? this._skipRows(sheet, options.skipRows) : sheet,
      {
        blankrows: false,
        forceQuotes: true,
      },
    );

    return csv({ output: 'json', ignoreEmpty: true }).fromString(csvString);
  }

  async _xlsx_to_aoa(xlsx: File, options?: ConfigOptions): Promise<any[][] | undefined> {
    const workbook: XLSX.WorkBook = await this._convertFileToWorkbook(xlsx, options);
    const aoa: any[][] = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
      header: 1,
      defval: null,
      raw: true,
    });

    if (!Array.isArray(aoa) || aoa.length < 2) {
      return undefined;
    }

    return aoa;
  }

  async _csv_to_aoa(csv: File): Promise<any[][] | undefined> {
    const workbook: XLSX.WorkBook = await this._convertFileToWorkbook(csv);
    const csvString: string = XLSX.utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]]);
    const lines: string[] = csvString.split(/\r\n|\n/);

    if (lines.length < 2) {
      return;
    }

    const [headerString, ...contentStringArr] = lines.filter((line: any) => Boolean(line.length));
    const rows: any[][] = contentStringArr.map((contentString: string) =>
      this._split_csvString(contentString).map((v: string) => {
        try {
          return JSON.parse(v);
        } catch (e) {
          return v;
        }
      }),
    );
    const headers: string[] = this._split_csvString(headerString);
    const aoa: any[][] = [headers, ...rows];

    return aoa;
  }

  async _csv_to_json(
    csv: File,
    options?: ConfigOptions,
  ): Promise<Record<string, string | undefined>[] | undefined> {
    const workbook: XLSX.WorkBook = await this._convertFileToWorkbook(csv, options);
    const sheet = workbook.Sheets[workbook.SheetNames[0]];

    return XLSX.utils.sheet_to_json(
      options?.skipRows ? this._skipRows(sheet, options.skipRows) : sheet,
    );
  }

  async sheet_to_csv(file: File): Promise<string> {
    const arrayBuffer = await file.arrayBuffer();
    const workbook = XLSX.read(arrayBuffer, { type: 'array' });
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];

    return XLSX.utils.sheet_to_csv(worksheet, { FS: ',', RS: '\n' });
  }

  _split_csvString(str: string): string[] {
    // try {
    //   // More details on how this regex works https://regex101.com/r/AvSDHy/1
    //   delimitedStrArr = str.match(/(?<=,|\n|^)("(?:(?:"")*[^"]*)*"|[^",\n]*|(?:\n|$))/g) || [];
    // }
    // catch (e) { // fallback until safari upgrades regexp engine
    const delimitedStrArr = str
      .replace(/".*?"/g, (str) => str.replace(/,/g, '~|||~'))
      .split(',')
      .map((str) => str.replace(/~\|\|\|~/g, ','));
    //}

    return delimitedStrArr;
  }

  _aoa_to_json([headers, ...rows]: any[][]): JsonDataType {
    const res: JsonDataType = [];

    for (const row of rows) {
      const obj: IJsonDataElement = {};
      row.forEach((value: any, index: number) => {
        if (!headers[index]) {
          return;
        }
        obj[headers[index]] = this._castValueAsNullable(value);

        if (headers[index] === 'Address Country') {
          obj[headers[index]] = obj[headers[index]] || 'US';
        }
      });
      res.push(obj);
    }

    return res;
  }

  _castValueAsNullable = (value: any) => (!value ? null : value);
}
