/* eslint-disable import/no-anonymous-default-export */
import chunk from 'lodash/chunk';
import md5 from 'md5';
import { AnyAction, Dispatch } from 'redux';

import { i18n } from '~/i18n';
import Errors from '~/modules/shared/error/errors';
import { CSV_TYPE, EXCELS_TYPE, EXCEL_TYPE } from '~/modules/shared/excel/excel';
import Importer from '~/modules/shared/importer/importer';
import { GenericField } from '../fields';
import { IFieldMapItemForField } from './importerTypes';
import statuses from '~/modules/shared/importer/importerStatuses';

type FieldMapItem = IFieldMapItemForField<GenericField>;

interface IImportActions {
  doUpdateDestinationField: (destinationField: GenericField, fieldMapItem: FieldMapItem) => any;
  [x: string]: any;
}

export type { IImportActions };

const allowedFileTypes = [EXCEL_TYPE, EXCELS_TYPE, CSV_TYPE];

async function importRow(
  dispatch: Dispatch,
  actions: any,
  importer: any,
  importFn: any,
  row: any,
  id: any,
) {
  try {
    const importableRow = await importer.castForImport(row);
    const importHash = md5(JSON.stringify(importableRow));

    await importFn(importableRow, importHash, id);

    dispatch({
      type: actions.IMPORT_BATCH_SUCCESS,
      payload: {
        line: row._line,
      },
    });
  } catch (error) {
    dispatch({
      type: actions.IMPORT_BATCH_ERROR,
      payload: {
        line: row._line,
        errorMessage: Errors.selectMessage(error),
      },
    });
  }
}

export default (
  prefix: any,
  selectors: any,
  importFn: any,
  importFields: GenericField[],
  templateFileName: any,
  batchSize = 10,
): IImportActions => {
  const actions = {
    RESETED: `${prefix}_RESETED`,

    CREATE_MAP_FIELD_SUCCESS: `${prefix}_CREATE_MAP_FIELD_SUCCESS`,
    SWITCH_MAP_FIELD_CHECKSTATE: `${prefix}_SWITCH_MAP_FIELD_CHECKSTATE`,
    UPDATE_DESTINATION_FIELD: `${prefix}_UPDATE_DESTINATION_FIELD`,
    SUBMIT_DESTINATION_FIELDS: `${prefix}_SUBMIT_DESTINATION_FIELDS`,

    FILE_READ_STARTED: `${prefix}_FILE_READ_STARTED`,
    FILE_READ_ERROR: `${prefix}_FILE_READ_ERROR`,
    FILE_READ_SUCCESS: `${prefix}_FILE_READ_SUCCESS`,

    FIELD_UPDATE_STARTED: `${prefix}_FIELD_UPDATE_STARTED`,
    FIELD_UPDATE_ERROR: `${prefix}_FIELD_UPDATE_ERROR`,
    FIELD_UPDATE_SUCCESS: `${prefix}_FIELD_UPDATE_SUCCESS`,

    FIELD_DELETE_STARTED: `${prefix}_FIELD_DELETE_STARTED`,
    FIELD_DELETE_ERROR: `${prefix}_FIELD_DELETE_ERROR`,
    FIELD_DELETE_SUCCESS: `${prefix}_FIELD_DELETE_SUCCESS`,

    PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
    SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

    IMPORT_STARTED: `${prefix}_IMPORT_STARTED`,
    IMPORT_UPDATED: `${prefix}_IMPORT_UPDATED`,
    IMPORT_ERROR: `${prefix}_IMPORT_ERROR`,
    IMPORT_PAUSED: `${prefix}_IMPORT_PAUSED`,
    IMPORT_SUCCESS: `${prefix}_IMPORT_SUCCESS`,

    IMPORT_BATCH_ERROR: `${prefix}_IMPORT_BATCH_ERROR`,
    IMPORT_BATCH_SUCCESS: `${prefix}_IMPORT_BATCH_SUCCESS`,

    doChangePagination: (pagination: any) => ({
      type: actions.PAGINATION_CHANGED,
      payload: pagination,
    }),

    doChangeSort:
      (rows: any, sorter: { columnKey: any; order: any }) =>
      async (
        dispatch: (arg0: { type: string; payload: { sortedRows: any[]; sorter: any } }) => void,
        getState: any,
      ) => {
        const { columnKey, order } = sorter;

        let sortFn = (a: { [x: string]: any }, b: { [x: string]: any }) =>
          (String(a[columnKey]) || '').localeCompare(String(b[columnKey]) || '');

        if (columnKey === '_line') {
          sortFn = (a, b) => a._line - b._line;
        }

        if (columnKey === '_status') {
          sortFn = (a, b) => (a._status || '').localeCompare(b._status || '');
        }

        let sortedRows = [...rows].sort(sortFn);

        if (order === 'desc') {
          sortedRows = sortedRows.reverse();
        }

        dispatch({
          type: actions.SORTER_CHANGED,
          payload: {
            sortedRows,
            sorter,
          },
        });
      },

    doReset: () => ({
      type: actions.RESETED,
    }),

    doPause: () => ({
      type: actions.IMPORT_PAUSED,
    }),

    doImport:
      (id: any, fields: GenericField[]) =>
      async (dispatch: Dispatch<AnyAction>, getState: () => any) => {
        try {
          dispatch({
            type: actions.IMPORT_STARTED,
          });

          let pendingRows = selectors.selectPendingRows(getState());
          pendingRows = pendingRows.filter((row: any) => row._status === statuses.PENDING);
          const importer = new Importer(fields);
          const pendingBatches = chunk(pendingRows, batchSize);

          for (const batch of pendingBatches) {
            const paused = !selectors.selectImporting(getState());

            if (paused) {
              return;
            }

            const pendingRows = [];

            for (const row of batch) {
              const pendingRow = importRow(dispatch, actions, importer, importFn, row, id);

              pendingRows.push(pendingRow);
            }

            await Promise.all(pendingRows);
          }

          dispatch({
            type: actions.IMPORT_SUCCESS,
          });
        } catch (error) {
          Errors.handle(error);

          dispatch({
            type: actions.IMPORT_ERROR,
          });
        }
      },

    doFieldMap: () => async (dispatch: Dispatch<AnyAction>, getState: () => any) => {
      const fieldMap: FieldMapItem[] = [];
      const unassignedRows = selectors.selectUnassignedRows(getState());

      if (!unassignedRows || !unassignedRows.length) {
        return;
      }

      const unassignedRowsForPreview = unassignedRows.slice(
        0,
        unassignedRows.length >= 3 ? 3 : unassignedRows.length,
      );
      const unassignedRowsForFieldMap = unassignedRows[0];
      const rowEntries = Object.entries(unassignedRowsForFieldMap);

      for (const [header, value] of rowEntries) {
        const correspondingField = importFields.find((field) => field.label === header);
        const previewValues = unassignedRowsForPreview.map(
          (row: { [x: string]: any }) => row[header],
        );
        const obj: FieldMapItem = {
          correspondingField,
          previewValues,
          id: header,
          _checked: Boolean(correspondingField),
        };

        fieldMap.push(obj);
      }

      dispatch({
        type: actions.CREATE_MAP_FIELD_SUCCESS,
        payload: {
          fieldMap,
        },
      });
    },

    doSwitchFieldState:
      (selectedFieldMapItem: FieldMapItem) =>
      (dispatch: Dispatch<AnyAction>, getState: () => any) => {
        const fieldMap = selectors.selectFieldMap(getState());
        const updatedFieldMap = fieldMap.map((fieldMapItem: FieldMapItem) => {
          const { correspondingField, id, _checked } = selectedFieldMapItem;
          if (fieldMapItem.id === id) {
            return {
              ...fieldMapItem,
              _checked: correspondingField ? !_checked : false,
            };
          }

          return fieldMapItem;
        });

        dispatch({
          type: actions.SWITCH_MAP_FIELD_CHECKSTATE,
          payload: {
            fieldMap: updatedFieldMap,
          },
        });
      },

    doUpdateDestinationField:
      (destinationField: GenericField, touchedFieldMapItem: FieldMapItem) =>
      (dispatch: Dispatch<AnyAction>, getState: () => any) => {
        const fieldMap = selectors.selectFieldMap(getState());
        const updatedFieldMap = fieldMap.map((fieldMapItem: FieldMapItem) => {
          // Finding existing field that triggered to be changed
          if (fieldMapItem.id === touchedFieldMapItem.id) {
            return {
              ...fieldMapItem,
              correspondingField: destinationField,
              _checked: true,
            };
          }
          // Finding existing corresponding field into map to disable dup
          if (
            fieldMapItem.correspondingField &&
            fieldMapItem.correspondingField.label === destinationField.label
          ) {
            return {
              ...fieldMapItem,
              correspondingField: undefined,
              _checked: false,
            };
          }

          return fieldMapItem;
        });

        dispatch({
          type: actions.UPDATE_DESTINATION_FIELD,
          payload: {
            fieldMap: updatedFieldMap,
          },
        });
      },

    doUpdate:
      (row: any, submittedFields: any) =>
      async (dispatch: Dispatch<AnyAction>, getState: () => any) => {
        try {
          const data = selectors.selectRows(getState());
          const importer = new Importer(submittedFields);

          dispatch({
            type: actions.FIELD_UPDATE_STARTED,
          });

          const rows = [...data];
          const index = rows.findIndex((record) => record.id === row.id);
          //rows[index] = row;
          rows[index] = { id: row.id, ...(await importer.castForDisplay(row, index)) };

          dispatch({
            type: actions.FIELD_UPDATE_SUCCESS,
            payload: {
              rows,
            },
          });
        } catch (error) {
          dispatch({
            type: actions.FIELD_UPDATE_ERROR,
            payload: error,
          });
        }
      },

    doDestroy: (rowId: any) => async (dispatch: Dispatch<AnyAction>, getState: () => any) => {
      try {
        const data = selectors.selectRows(getState());

        dispatch({
          type: actions.FIELD_DELETE_STARTED,
        });

        const rows = [...data];
        const index = rows.findIndex((record) => record.id === rowId);
        rows.splice(index, 1);
        dispatch({
          type: actions.FIELD_DELETE_SUCCESS,
          payload: {
            rows,
          },
        });
      } catch (error) {
        dispatch({
          type: actions.FIELD_DELETE_ERROR,
          payload: error,
        });
      }
    },

    doSubmitDestinationFields: () => async (dispatch: Dispatch<AnyAction>, getState: () => any) => {
      const unassignedRows = selectors.selectUnassignedRows(getState());
      const fieldMap = selectors.selectFieldMap(getState());
      const submittedFieldMap = fieldMap.filter(
        (fieldMapItem: any) => fieldMapItem.correspondingField && fieldMapItem._checked,
      );
      const submittedFieldIds: string[] = submittedFieldMap.map(
        (submittedField: any) => submittedField.id,
      );
      const submittedFields: any[] = submittedFieldMap.map(
        (submittedFieldMapItem: any) => submittedFieldMapItem.correspondingField,
      );
      // Importer with all fields
      const importer = new Importer(submittedFields);
      const rows =
        (await Promise.all(
          unassignedRows.map(async (unassignedRow: any, index: number) => {
            const row: any = {};
            for (const submittedFieldId of submittedFieldIds) {
              row[submittedFieldId] = unassignedRow[submittedFieldId];
            }
            return await importer.castForDisplay(row, index);
          }),
        )) || ([] as any[]); // TODO change any

      const rowsWithId: any[] = rows.map((row: any, idx) =>
        row.id ? row : { ...row, id: idx + 1 },
      ); // TODO change any

      dispatch({
        type: actions.SUBMIT_DESTINATION_FIELDS,
        payload: {
          rows: rowsWithId,
          submittedFields,
        },
      });
    },

    doDownloadTemplate: () => async (dispatch: any) => {
      const importer = new Importer(importFields);
      importer.downloadTemplate(templateFileName);
    },

    doReadFile:
      (file: File, fields: any) =>
      async (dispatch: (arg0: { type: string; payload?: unknown }) => void) => {
        try {
          dispatch({
            type: actions.FILE_READ_STARTED,
          });
          let isValidExt = false;
          allowedFileTypes.forEach((type) => {
            if (file.type === type) {
              isValidExt = true;
            }
          });

          if (!isValidExt) {
            throw new Error(i18n('importer.errors.invalidFileExcelOrCSV'));
          }

          // Importer with all fields
          const importer = new Importer(importFields);

          let json;

          switch (file.type) {
            case CSV_TYPE:
              json = await importer.convertCSVFileToJson(file);
              break;
            case EXCELS_TYPE:
            case EXCEL_TYPE:
              json = await importer.convertExcelFileToJson(file);
              break;
            default:
              throw new Error(i18n('importer.errors.invalidFileExcelOrCSV'));
          }

          if (!json || !json.length) {
            throw new Error(i18n('importer.errors.invalidFileEmpty'));
          }

          json = json.map((keys: any) => {
            const obj: any = {};
            for (const key in keys) {
              if (typeof keys[key] === 'string') {
                obj[key] = keys[key].replace(/[^\x00-\x80]+/g, ' ');
                obj[key] = keys[key].replace('\t', '');
              } else {
                obj[key] = keys[key];
              }
            }

            return obj;
          });

          dispatch({
            type: actions.FILE_READ_SUCCESS,
            payload: json,
          });
        } catch (error) {
          console.error(error);
          dispatch({
            type: actions.FILE_READ_ERROR,
            payload: error,
          });
        }
      },
  };

  return actions;
};
