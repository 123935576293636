import { i18n } from 'i18n';
import Errors from 'modules/shared/error/errors';
import { getHistory } from 'modules/store';
import Message from '~/view/shared/message';
import authAxios from 'modules/shared/axios/authAxios';
import { CSV_EXTENSION, EXCEL_EXTENSION } from '../excel/excel';
import Exporter from '../exporter/exporter';

const getCommonActions = ({ service, path, prefix = 'VIEW' }) => {
  const actions = {
    RESET: `${path}_${prefix}_RESET`,

    FIND_STARTED: `${path}_${prefix}_FIND_STARTED`,
    FIND_SUCCESS: `${path}_${prefix}_FIND_SUCCESS`,
    FIND_ERROR: `${path}_${prefix}_FIND_ERROR`,

    EXPORT_STARTED: `${path}_${prefix}_EXPORT_STARTED`,
    EXPORT_SUCCESS: `${path}_${prefix}_EXPORT_SUCCESS`,
    EXPORT_ERROR: `${path}_${prefix}_EXPORT_ERROR`,
  };
  const goBack = () => {
    if (path) {
      getHistory().push(`/${path}`);
    }
  };
  const go404 = () => {
    getHistory().push('/404');
  };
  const reset = () => ({
    type: actions.RESET,
  });
  const doFind = (id) => async (dispatch) => {
    if (service && service.find) {
      try {
        dispatch({
          type: actions.FIND_STARTED,
        });

        const record = await service.find(id);
        dispatch({
          type: actions.FIND_SUCCESS,
          payload: record,
        });
      } catch (error) {
        const errorData = Errors.getErrorData(error);
        dispatch({
          type: actions.FIND_ERROR,
          payload: errorData,
        });
        go404();
      }
    } else {
      goBack();
    }
  };
  const doExport =
    ({ key = undefined, rows = undefined, fields, fileName = 'export', type }) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: actions.EXPORT_STARTED,
        });

        let data;
        let items;

        if (rows) {
          data = rows;
        }
        // apparently storefronts data is available on load, so no fetching needed
        if (key) {
          const state = getState();
          data = state[path].view.record || state[path].form.record;
          if (data) {
            data = data[key];
          } else {
            // except for when we're not on a partner record and don't have storefronts preloaded, then we fetch
            if (key === 'storefront') {
              const response = await authAxios.get(
                `/partner/${state?.auth?.currentUser?.partnerName?.slug}`,
              );
              data = response?.data?.[key];
            }
          }
        }

        if (!rows && !key) {
          data = getState()[path].view.record;
        }

        if (Array.isArray(data)) {
          items = data;
        } else {
          items = [data];
        }

        switch (type) {
          case CSV_EXTENSION:
            new Exporter(fields, fileName).transformAndExportAsCSV(items);
            break;
          case EXCEL_EXTENSION:
            new Exporter(fields, fileName).transformAndExportAsExcelFile(items);
            break;
          default:
            throw new Error('Please, specify type of file to export');
        }
      } catch (error) {
        console.log('error:', error);
        const errorData = Errors.getErrorData(error);
        const errorMessage = (errorData && errorData.message) || i18n('errors.defaultErrorMessage');

        Message.error(errorMessage);

        dispatch({
          type: actions.EXPORT_ERROR,
          payload: errorData,
        });
      }
    };

  return {
    ...actions,
    doFind,
    doExport,
    reset,
  };
};

export default getCommonActions;
