import { changeDateFormattingFromSimpleDate } from '~/utils/dateUtil';
import { BasicRef, FileBasicRef } from '.';
import { RequestForProposal, RequestForProposalModel } from './RequestForProposal';
import { Storefront, StorefrontModel } from '../storefront/Storefront';

export interface RFPInventoryModelFile {
  key: string;
  url: string;
  size: number;
  handle: string;
  source: string;
  status: string;
  filename: string;
  mimetype: string;
  uploadId: string;
  container: string;
  originalFile: FileBasicRef;
  originalPath: string;
}

export interface RFPInventoryModel {
  id: string;
  requestForProposal: RequestForProposalModel;
  event: {
    id: string;
    date: string;
    event: string;
  };
  storefront: StorefrontModel;
  numberOfUnits: number;
  availableStartDate: string;
  availableEndDate: string;
  fourWeekPeriodCount: number;
  fourWeekRateCardCost: string;
  fourWeekRateNegotiatedCost: string;
  negotiatedCost: string;
  installCost: string;
  totalProgramCost: string;
  artworkDueDate: string;
  materialDueDate: string;
  additionalNotes: string;
  metadata: {
    storefront: {
      original: unknown;
      unparsed: unknown;
    };
    response: {
      original: unknown;
      unparsed: unknown;
    };
  };
  holdExpirationDate: string;
  comment: {
    id: string;
    comment: string;
    createdAt: string;
    updatedAt: string;
  };
  specFile: RFPInventoryModelFile;
  status: BasicRef<number>;
  availability: BasicRef<number>;
  mediaFiles: RFPInventoryModelFile[];
  mediaFilesZipLink: string;
  productionCost: string;
}

export class RFPInventory {
  static create(model: RFPInventoryModel): RFPInventory {
    const {
      id,
      availableStartDate,
      availableEndDate,
      availability,
      installCost,
      fourWeekRateCardCost,
      fourWeekRateNegotiatedCost,
      fourWeekPeriodCount,
      mediaFiles,
      numberOfUnits,
      productionCost,
      requestForProposal: requestForProposalModel,
      status,
      storefront: storefrontModel,
      totalProgramCost,
      mediaFilesZipLink,
    } = model;
    return new RFPInventory(
      id,
      availableStartDate,
      availableEndDate,
      availability?.name,
      installCost,
      fourWeekRateCardCost,
      fourWeekRateNegotiatedCost,
      fourWeekPeriodCount,
      mediaFiles,
      storefrontModel.name,
      numberOfUnits,
      productionCost,
      RequestForProposal.create(requestForProposalModel),
      status,
      storefrontModel ? Storefront.create(storefrontModel) : null,
      totalProgramCost,
      mediaFilesZipLink,
    );
  }
  public constructor(
    public id: string,
    protected availableStartDateString?: string,
    protected availableEndDateString?: string,
    public availabilityStatus?: unknown,
    public installCost?: string,
    public fourWeekRateCardCost?: string,
    public fourWeekRateNegotiatedCost?: string,
    public fourWeekPeriodCount?: number,
    public mediaFiles?: RFPInventoryModelFile[],
    public nameOfInventory?: string,
    public numberOfUnits?: number,
    public productionCost?: string,
    public requestForProposal?: RequestForProposal,
    public status?: BasicRef<number>,
    public storefront?: Storefront,
    public totalProgramCost?: string,
    public mediaFilesZipLink?: string,
  ) {}

  public get availableStartDate(): string {
    return changeDateFormattingFromSimpleDate(this.availableStartDateString);
  }

  public get availableEndDate(): string {
    return changeDateFormattingFromSimpleDate(this.availableEndDateString);
  }

  public get campaignName(): string {
    return this.requestForProposal?.agencyCampaign.name;
  }

  public get format(): string {
    return this.storefront?.inventoryTypes?.map((x) => x.name)?.join(',');
  }

  public get geopathId(): number {
    return this.storefront?.geopathId;
  }

  public get statusName(): string {
    return this.status?.name;
  }

  public get unitNumber(): string {
    return this.storefront?.unitNumber;
  }

  public get vendorName(): string {
    return this.requestForProposal?.supplyPartner.name;
  }

  public get campaignStartDate(): string {
    return this.requestForProposal?.agencyCampaign?.startDateString;
  }

  public get campaignEndDate(): string {
    return this.requestForProposal?.agencyCampaign?.endDateString;
  }

  public get campaignDueDate(): string {
    return this.requestForProposal?.agencyCampaign?.dueDateString;
  }
}
