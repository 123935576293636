import { HubSpot } from '~/utils/hubSpot';

let inMemoryToken = null;

export class AuthToken {
  static get() {
    const jwt = inMemoryToken || localStorage.getItem('jwt') || null;

    if (!jwt) {
      HubSpot.hideWidget();

      return jwt;
    }

    return jwt;
  }

  static getSessionToken() {
    const jwt = sessionStorage.getItem('jwt') || null;

    if (!jwt) {
      HubSpot.hideWidget();

      return jwt;
    }

    return jwt;
  }

  static set(token, rememberMe) {
    if (rememberMe) {
      localStorage.setItem('jwt', token || '');
      HubSpot.showWidget();
    } else {
      inMemoryToken = token;
      localStorage.setItem('jwt', '');
      HubSpot.hideWidget();
    }
  }

  static setSessionToken(token) {
    sessionStorage.setItem('jwt', token || '');
  }
}
