import FileSaver from 'file-saver';
import XLSX from 'xlsx';

export const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const EXCEL_TYPE_WITH_CHARSET = `${EXCEL_TYPE};charset=UTF-8`;
export const EXCEL_EXTENSION = '.xlsx';

export const EXCELS_TYPE = 'application/vnd.ms-excel';
export const EXCELS_TYPE_WITH_CHARSET = `${EXCELS_TYPE};charset=UTF-8`;
export const EXCELS_EXTENSION = '.xls';

export const CSV_TYPE = 'text/csv';
export const CSV_TYPE_WITH_CHARSET = `${CSV_TYPE};charset=UTF-8`;
export const CSV_EXTENSION = '.csv';

export class Excel {
  static exportAsExcelFile(json, header, fileName) {
    const worksheet = XLSX.utils.json_to_sheet(json, {
      header,
      skipHeader: false,
    });

    const workbook = {
      Sheets: { data: worksheet },
      SheetNames: ['data'],
    };

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    this.saveAsExcelFile(excelBuffer, fileName);
  }

  static exportAsCSV(json, header, fileName) {
    const worksheet = XLSX.utils.json_to_sheet(json, {
      header,
      skipHeader: false,
    });

    const workbook = {
      Sheets: { data: worksheet },
      SheetNames: ['data'],
    };

    const csvBuffer = XLSX.write(workbook, {
      bookType: 'csv',
      type: 'array',
    });

    this.saveAsCSVFile(csvBuffer, fileName);
  }

  static saveAsExcelFile(buffer, fileName) {
    const data = new Blob([buffer], {
      type: EXCEL_TYPE_WITH_CHARSET,
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  static saveAsCSVFile(buffer, fileName) {
    const data = new Blob([buffer], {
      type: CSV_TYPE_WITH_CHARSET,
    });
    FileSaver.saveAs(data, fileName + CSV_EXTENSION);
  }
}
