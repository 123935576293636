export function formattingNumberIntoPercentages(value: number) {
  return new Intl.NumberFormat('default', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
}

export function formatHoursInDaysAndHours(value: number) {
  const days = Math.floor(value / 24);
  const hours = Math.round(value % 24);
  const daysString = days > 0 ? `${days}d` : '';
  const hoursString = hours > 0 ? `${hours}h` : '';

  return [daysString, hoursString].join(' ').trim();
}
