const en = {
  common: {
    alreadySignIn: 'User already signed in',
    areYouSure: 'Are you sure?',
    assignGroup: 'Assign group',
    cancel: 'Cancel',
    destroy: 'Delete',
    archive: 'Archive',
    discard: 'Discard',
    approve: 'Approve',
    approved: 'Approved',
    deny: 'Deny',
    denied: 'Denied',
    expired: 'Expired',
    requestAvails: 'Request Avails',
    edit: 'Edit',
    end: 'End',
    export: 'Export',
    import: 'Import',
    inventory: 'Inventory',
    packages: 'Packages',
    importInventoryStep3: 'Preview and Import',
    confirm: 'Confirm',
    confirmImportLocations: 'Map Inventory Fields',
    fileFormat: {
      asCSV: '.CSV',
      asXLSX: '.XLSX',
    },
    invite: 'Invite user',
    mustSelectARow: 'Must select a row',
    new: 'New',
    newGroup: 'New group',
    newRole: 'New role',
    no: 'No',
    noDataToExport: 'No data to export',
    or: 'or',
    pause: 'Pause',
    remove: 'Remove',
    reset: 'Reset',
    save: 'Save',
    search: 'Search',
    send: 'Send',
    sign: 'Sign',
    start: 'Start',
    submit: 'Submit',
    view: 'View',
    yes: 'Yes',
    streetView: 'Street View',
    embedSectionOnSite: 'Embed Section On Your Website',
  },

  app: {
    title: 'OneScreen.ai',
  },

  entities: {
    common: {
      create: {
        success: 'Created successfully',
        error: 'Failed submitting request!',
      },
      update: {
        success: 'Updated successfully',
      },
      destroy: {
        success: 'Deleted successfully',
      },
    },
    scheduler: {
      name: 'scheduler',
      label: 'Scheduler',
      fields: {
        id: 'Id',
        content: 'Content',
        device: 'Device',
        contents: 'Content(s)',
        devices: 'Device(s)',
        startDate: 'Start Date',
        endDate: 'End Date',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
      },
    },
    crm: {
      leads: 'Leads',
      contacts: 'Contacts',
      companies: 'Companies',
      customers: 'Customers',
      preferredVendorContacts: 'Preferred Vendor Contacts',
    },
    plan: {
      persona: 'Persona',
      demographics: 'Demographics',
      inventory: 'Inventory',
      proposal: 'Proposal',
      explore: 'Explore',
    },
    content: {
      name: 'content',
      label: 'Content',
      menu: 'Content',
      exporterFileName: 'content_export',
      list: {
        menu: 'Content',
        title: 'Content',
      },
      create: {
        success: 'Content saved successfully',
      },
      update: {
        success: 'Content saved successfully',
      },
      destroy: {
        success: 'Content deleted successfully',
      },
      destroyAll: {
        success: 'Content(s) deleted successfully',
      },
      edit: {
        title: 'Edit Content',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        description: 'Description',
        media: 'Media',
        tags: 'Tags',
        organizationOwner: 'Advertiser',
        cta: 'Cta',
        transactions: 'Transactions',
        analyticsEvents: 'Analytics Events',
        minBidAmountRange: 'Min Bid Amount',
        minBidAmount: 'Min Bid Amount',
        category: 'Category',
        maxBidAmountRange: 'Max Bid Amount',
        maxBidAmount: 'Max Bid Amount',
        mediaUrl: 'Media URL',
        mediaJSON: 'Media JSON',
        ongoing: 'Ongoing',
        private: 'Private',
        isAd: 'Is this Ad Content?',
        status: 'Status',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Content',
      },
      view: {
        title: 'View Content',
      },
      importer: {
        title: 'Import Contents',
        fileName: 'content_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    storefront: {
      name: 'Discover Locations',
      label: 'Discover Locations',
      menu: 'Discover Locations',
      exporterFileName: 'storefront_export',
      list: {
        menu: 'Discover Locations',
        title: 'Discover Locations',
      },
      create: {
        success: 'Locations saved successfully',
      },
      update: {
        success: 'Locations saved successfully',
      },
      updatePackage: {
        success: 'Package saved successfully',
      },
      destroy: {
        success: 'Locations deleted successfully',
      },
      destroyAll: {
        success: 'Locations(s) deleted successfully',
      },
      edit: {
        title: 'Edit Locations',
      },
      fields: {
        id: 'Id',
        packageName: 'Name',
        name: 'Location Name',
        quantity: '# of Units',
        minimumOrderQuantity: 'Minimum Order Quantity',
        url: 'Url',
        test: 'Test Location',
        hoo: 'Hours of Operation',
        physicalAddress: 'Address Street',
        city: 'Address City',
        state: 'Address State',
        zip: 'Address Zip',
        country: 'Address Country',
        timezone: 'Timezone',
        timezoneName: 'Timezone Name',
        content: 'Content',
        customerProfiles: 'Customer Profiles',
        device: 'Device',
        currentBalanceRange: 'Current Balance',
        currentBalance: 'Current Balance',
        tags: 'Tags',
        users: 'Users',
        owner: 'Owner',
        techsupport: 'Tech Support',
        organizationType: 'Organization Type',
        organizationCategory: 'Organization Categories',
        totalRevenueRange: 'Total Revenue',
        totalRevenue: 'Total Revenue',
        transactions: 'Transactions',
        status: 'Status',
        analyticsEvents: 'Analytics Events',
        organizationSector: 'Organization Sector',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
        availability: 'Available dates',
        nextAvailableDate: 'Next Available Date',
        geopathId: 'Geopath ID',
        unitNumber: 'Unit #',
        description: 'Description',
        notes: 'Additional Notes',
        dimensions: 'Dimensions',
        inventoryType: 'Inventory Type',
        inventoryTypes: 'Inventory Type',
        market: 'Market',
        facing: {
          label: 'Facing',
          N: 'North',
          NW: 'NorthWest',
          W: 'West',
          SW: 'SouthWest',
          S: 'South',
          SE: 'SouthEast',
          E: 'East',
          NE: 'NorthEast',
        },
        position: {
          label: 'LHR/RHR',
          LHR: 'Left',
          lhr: 'Left',
          center: 'Center',
          parallel: 'Parallel',
          'head-on': 'Head-on',
          various: 'Various',
          RHR: 'Right',
          rhr: 'Right',
        },
        weeklyAdultImpressionsCount: 'Weekly A18+ Impressions',
        uniqueAdultAdReachedCount: 'A18+ Reach',
        averageAdultFrequencyReach: 'A18+ Frequency',
        grp: 'GRP (Gross Rating Points)',
        spotLength: 'Spot Length',
        loopLength: 'Loop Length',
        hoursPerDay: 'Hours Per Day',
        sov: 'SOV%',
        advertisersCount: 'Current # of Advertisers',
        fourWeekRateNegotiatedCost: '4 Week Rate Negotiated Cost',
        fourWeekRateCardCost: '4 Week Rate Card Cost',
        productionCost: 'Production Cost',
        installCost: 'Install Cost',
        panelSize: 'Panel Size',
        vinylSize: 'Vinyl Size',
        shippingAddress: 'Shipping Address',
        noOfDaysMaterialIsDuePrior: 'No of days Material Is Due Prior',
        noOfDaysArtworkIsDuePrior: 'No of days Artwork Is Due Prior',
        illuminated: {
          label: 'Illuminated',
          yes: 'Yes',
          no: 'No',
        },
        fullMotion: {
          label: 'Full Motion',
          yes: 'Yes',
          no: 'No',
        },
        artworkFormats: {
          label: 'Artwork formats Accepted',
        },
        obstructions: {
          unObstructed: 'Unobstructed',
          obstructed: 'Obstructed',
          label: 'Obstructions',
          unknown: 'Unknown',
        },
        condition: {
          good: 'Good',
          bad: 'Bad',
          label: 'Condition',
          unknown: 'Unknown',
        },
        prominence: {
          prominent: 'Prominent',
          crowded: 'Crowded',
          label: 'Prominence',
          unknown: 'Unknown',
        },
        extensionCostPerSqft: {
          label: 'Extension Cost Per Sqft($)',
        },
        creativeExtension: {
          label: 'Is Creative Extension Possible',
          yes: 'Yes',
          no: 'No',
          unknown: 'Unknown',
        },
        staticImageAd: {
          label: 'Static Image Ad',
          yes: 'Yes',
          no: 'No',
        },
        motionGraphicAd: {
          label: 'Motion Graphic Ad',
          yes: 'Yes',
          no: 'No',
        },
        audioAd: {
          label: 'Audio Ad',
          yes: 'Yes',
          no: 'No',
        },
        lengthLimit: 'Length Limit',
        fileSizeLimitMB: 'Size Limit',
        categoryRestrictions: 'Category Restrictions',
      },
      enumerators: {},
      new: {
        title: 'New Screen Locations',
      },
      view: {
        title: 'View Screen Locations',
      },
      export: {
        success: 'Inventory exported successfully',
        error: 'Inventory not found',
      },
      importer: {
        title: 'Add Inventory',
        fileName: 'storefront_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
        fieldMapperPage: 'Check all the fields you want to import',
      },
    },

    availability: {
      fields: {
        id: 'Id',
        status: 'Status', //
        clientName: 'Company Name',
        storefrontId: 'Storefront Id',
        price: 'Price',
        startDate: 'Start Date',
        endDate: 'End Date',

        available: 'Available',
        date: 'Date',
        purchased: 'Purchased',
      },
      create: {
        success: 'Flight saved successfully',
      },
      update: {
        success: 'Flight saved successfully',
      },
      destroy: {
        success: 'Flight deleted successfully',
      },
    },

    locationGroup: {
      name: 'Location Groups',
      label: 'Location Groups',
      menu: 'Location Groups',
      exporterFileName: 'locationGroup_export',
      list: {
        menu: 'Location Groups',
        title: 'Location Groups',
      },
      create: {
        success: 'Location Group saved successfully',
      },
      update: {
        success: 'Location Group saved successfully',
      },
      destroy: {
        success: 'Location Group deleted successfully',
      },
      destroyAll: {
        success: 'Location Group(s) deleted successfully',
      },
      edit: {
        title: 'Edit Location Group',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        url: 'Url',
        test: 'Test Location',
        hoo: 'Hours of Operation',
        physicalAddress: 'Street Address',
        city: 'City',
        state: 'State',
        zip: 'Zip',
        timezone: 'Timezone',
        timezoneName: 'Timezone Name',
        locations: 'Locations',
        content: 'Content',
        customerProfiles: 'Customer Profiles',
        device: 'Device',
        currentBalanceRange: 'Current Balance',
        currentBalance: 'Current Balance',
        tags: 'Tags',
        users: 'Users',
        owner: 'Owner',
        techsupport: 'Tech Support',
        organization: 'Advertiser Organization',
        storefront: 'Location(s)',
        active: 'Locked (IOs Launched from Location Group)',
        organizationType: 'Organization Type',
        organizationCategory: 'Organization Categories',
        totalRevenueRange: 'Total Revenue',
        totalRevenue: 'Total Revenue',
        transactions: 'Transactions',
        status: 'Status',
        analyticsEvents: 'Analytics Events',
        organizationSector: 'Organization Sector',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Location Group',
      },
      view: {
        title: 'View Location Group',
      },
      importer: {
        title: 'Import Location Group',
        fileName: 'locationGroup_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    ooh: {
      name: 'Directory',
      label: 'Directory',
      menu: 'Directory',
      exporterFileName: 'ooh_export',
      list: {
        menu: 'Directory',
        title: 'Directory',
        notCompanyListed: "Don't see your company listed?",
      },
      create: {
        success: 'Partner Directory Page saved successfully',
      },
      update: {
        success: 'Partner Directory Page saved successfully',
      },
      destroy: {
        success: 'Partner Directory Page deleted successfully',
      },
      destroyAll: {
        success: 'Partner Directory Page(s) deleted successfully',
      },
      edit: {
        title: 'Edit Partner Directory Page',
      },
      carousel: {
        upMediaFilePosition: 'Up media file position',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        slug: 'Slug',
        logo: 'Logo',
        url: 'Url',
        description: 'Description',

        street: 'Address Street',
        city: 'Address City',
        state: 'Address State',
        zip: 'Address Zip',
        country: 'Address Country',

        facebook: 'Facebook URL',
        twitter: 'Twitter URL',
        linkedin: 'LinkedIn URL',
        instagram: 'Instagram URL',
        youtube: 'YouTube URL',
        businessPhone: 'Business Phone',
        other: 'Other URL',

        address: 'Address',
        phone: 'Phone',
        contact: 'Contact',
        locations: 'Locations',
        markets: 'Markets',
        dsp: 'Demand Side Platform (DSP)',
        staticImageAd: 'Supports Static Ad',
        motionGraphicAd: 'Supports Video Ad',
        audioAd: 'Supports Audio in Ad',
        budgetRequirements: 'Budget Requirements',
        campaignType: 'Campaign Type',
        campaignTypes: 'Campaign Types',
        inventoryType: 'Inventory Type',
        inventoryTypes: 'Inventory Types',
        tier: 'TierTypes',
        serviceType: 'Service Type',
        serviceTypes: 'Service Types',
        creativeRequirements: 'Creative Reqs',
        benefits: 'Benefits', // Renamed from 'pros' TODO
        createdAt: 'Created at',
        tags: 'Tags',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
        multiple: 'Created at',
        partnerRegionLocationStates: 'Partner Region Location States',
        timeToLaunchDetails: 'Launch campaign details',
        internalNotes: 'Internal Notes',
        timeToLaunch: 'Time to launch the campaign',
        cc: 'Carbon copy emails',

        contactName: 'Contact name',
        contactEmail: 'Contact email',
        contactTitle: 'Contact Job Title',
        contactPhone: 'Contact phone',
      },
      enumerators: {
        timeToLaunch: {
          lessThanWeek: 'Less than week',
          moreThanWeek: 'More than week',
        },
      },
      new: {
        title: 'New OOH',
      },
      view: {
        title: 'View OOH',
        yourCompany: 'Your company?',
        claimIt: 'Claim it!',
      },
      importer: {
        title: 'Import Partner Inventory Template File',
        fileName: 'locationGroup_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    budgetRequirement: {
      label: 'Budget Requirement',
      fields: {
        id: 'Id',
        name: 'Name',
      },
    },

    campaignType: {
      label: 'Campaign Type',
      fields: {
        id: 'Id',
        name: 'Name',
      },
    },

    inventoryTypes: {
      label: 'Inventory Type',
      fields: {
        id: 'Id',
        dma: 'Name',
      },
    },

    serviceType: {
      label: 'Service Type',
      fields: {
        id: 'Id',
        dma: 'Name',
      },
    },

    locationState: {
      label: 'Location State',
      fields: {
        id: 'Id',
        state: 'State',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
    },

    partnerContact: {
      title: 'Partner Contact',
      fields: {
        partnerId: 'Partner Id',
        name: 'Your Name',
        phone: 'Phone',
        email: 'Email',
        title: 'Title',
        description: 'Anything Specific',
      },
      create: {
        success: 'Contact saved successfully',
      },
    },

    organization: {
      name: 'Advertiser',
      label: 'Advertiser',
      menu: 'Advertiser',
      exporterFileName: 'organization_export',
      list: {
        menu: 'Advertisers',
        title: 'Advertisers',
      },
      create: {
        success: 'Advertiser saved successfully',
      },
      update: {
        success: 'Advertiser saved successfully',
      },
      destroy: {
        success: 'Advertiser deleted successfully',
      },
      destroyAll: {
        success: 'Advertiser deleted successfully',
      },
      edit: {
        title: 'Edit Advertiser',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        url: 'Url',
        balanceRange: 'Balance',
        balance: 'Balance',
        users: 'Users',
        owner: 'Owner',
        email: 'Email',
        organizationCategory: 'Categories',
        organizationType: 'Advertiser Type',
        status: 'Status',
        active: 'Active',
        contentOwned: 'Content Owned',
        transactions: 'Transactions',
        analyticsEvents: 'Analytics Events',
        organizationSector: 'Advertiser Sector',
        budgetAccountBalance: 'Account Balance',
        budgetTotalSpent: 'Total Spent',
        budgetAvailable: 'Budget Available',
        budgetRemainingAllocated: 'Remaining Allocated',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
        totalSpent: 'Total Spent',
        remainingBudgetAllocated: 'Remaining Budget Allocated',
      },
      enumerators: {},
      new: {
        title: 'New Advertiser',
      },
      view: {
        title: 'View Advertiser',
      },
      importer: {
        title: 'Import Advertiser',
        fileName: 'organization_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
      loadBudget: {
        title: 'Load Budget',
      },
      viewLedger: {
        title: 'Ledger',
      },
      campaigns: {
        title: 'Campaigns',
      },
    },

    campaign: {
      status: {
        description: {
          'On Hold':
            'Use “On Hold” status when the prospect has shown interest to purchase the billboards.',
          Pending:
            'Use “Pending“ status when the prospect has confirmed to purchase the billboards.',
          Contracted:
            'Use “Contracted“ status when the prospect has signed the contract or an Insertion order.',
          Active: 'Use “Active“ status when the campaign has already started.',
          Completed: 'Use “Completed“ status for all the successful past campaigns.',
        },
      },
    },

    budgetManagement: {
      name: 'Budget Management',
      label: 'Budget Management',
      menu: 'Budget Management',
      exporterFileName: 'organization_export',
      list: {
        menu: 'Budgets',
        title: 'Budgets',
      },
      create: {
        success: 'Budget saved successfully',
      },
      loadBudget: {
        success: 'Budget loaded successfully',
        title: 'Load Budget',
      },
      update: {
        success: 'Budget saved successfully',
      },
      destroy: {
        success: 'Budget deleted successfully',
      },
      destroyAll: {
        success: 'Budget deleted successfully',
      },
      edit: {
        title: 'Edit Budget',
      },
      budgetAccountBalance: '',
      fields: {
        amount: 'Amount',
        stripeId: 'Stripe ID',
        memo: 'Memo',
        id: 'Id',
        name: 'Name',
        url: 'Url',
        balanceRange: 'Balance',
        balance: 'Balance',
        users: 'Users',
        owner: 'Owner',
        organizationCategory: 'Advertiser Categories',
        organizationType: 'Advertiser Type',
        status: 'Status',
        active: 'Active',
        contentOwned: 'Content Owned',
        transactions: 'Transactions',
        analyticsEvents: 'Analytics Events',
        organizationSector: 'Advertiser Sector',
        budgetAccountBalance: 'Account Balance',
        budgetTotalSpent: 'Total Spent',
        budgetAvailable: 'Budget Available',
        budgetRemainingAllocated: 'Remaining Allocated',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
        totalSpent: 'Total Spent',
        remainingBudgetAllocated: 'Remaining Budget Allocated',
      },
      enumerators: {},
      new: {
        title: 'New Budget',
      },
      view: {
        title: 'View Budget',
      },
      importer: {
        title: 'Import Budget',
        fileName: 'organization_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
      viewLedger: {
        title: 'View Ledger',
      },
    },

    customerProfile: {
      name: 'customerProfile',
      label: 'CustomerProfiles',
      menu: 'CustomerProfiles',
      exporterFileName: 'customerProfile_export',
      list: {
        menu: 'CustomerProfiles',
        title: 'CustomerProfiles',
      },
      create: {
        success: 'Customer Profile saved successfully',
      },
      update: {
        success: 'Customer Profile saved successfully',
      },
      destroy: {
        success: 'Customer Profile deleted successfully',
      },
      destroyAll: {
        success: 'Customer Profile(s) deleted successfully',
      },
      edit: {
        title: 'Edit Customer Profile',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        birthdateRange: 'Birthdate',
        birthdate: 'Birthdate',
        gender: 'Gender',
        tags: 'Interest Tags',
        audienceProfile: 'Customer Audience Profile',
        analyticsEvents: 'Analytics Events',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        gender: {
          male: 'Male',
          female: 'Female',
        },
      },
      new: {
        title: 'New Customer Profile',
      },
      view: {
        title: 'View Customer Profile',
      },
      importer: {
        title: 'Import CustomerProfiles',
        fileName: 'customerProfile_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    customerAudience: {
      name: 'customerAudience',
      label: 'Customer Audiences',
      menu: 'Customer Audiences',
      exporterFileName: 'customerAudience_export',
      list: {
        menu: 'Customer Audiences',
        title: 'Customer Audiences',
      },
      create: {
        success: 'Customer Audience saved successfully',
      },
      update: {
        success: 'Customer Audience saved successfully',
      },
      destroy: {
        success: 'Customer Audience deleted successfully',
      },
      destroyAll: {
        success: 'Customer Audience(s) deleted successfully',
      },
      edit: {
        title: 'Edit Customer Audience',
      },
      fields: {
        id: 'Id',
        slug: 'Slug',
        name: 'Name',
        demographic: 'Demographic',
        income: 'Income',
        active: 'Active',
        tags: 'Audience Tags',
        sector: 'Sector',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Customer Audience',
      },
      view: {
        title: 'View Customer Audience',
      },
      importer: {
        title: 'Import Customer Audiences',
        fileName: 'customerAudience_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    callToAction: {
      name: 'callToAction',
      label: 'Customer Calls To Action',
      menu: 'Customer Calls To Action',
      exporterFileName: 'callToAction_export',
      list: {
        menu: 'Customer Calls To Action',
        title: 'Customer Calls To Action',
      },
      create: {
        success: 'Customer Call To Action saved successfully',
      },
      update: {
        success: 'Customer Call To Action saved successfully',
      },
      destroy: {
        success: 'Customer Call To Action deleted successfully',
      },
      destroyAll: {
        success: 'Customer Call To Action(s) deleted successfully',
      },
      edit: {
        title: 'Edit Customer Call To Action',
      },
      fields: {
        id: 'Id',
        type: 'Type',
        label: 'Label',
        details: 'Details',
        url: 'Url',
        action: 'Action',
        tags: 'Tags',
        analyticsEvents: 'Analytics Events',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        type: {
          phone: 'Phone',
          sms: 'Sms',
          url: 'Url',
          qrc: 'Qrc',
        },
      },
      new: {
        title: 'New Customer Call To Action',
      },
      view: {
        title: 'View Customer Call To Action',
      },
      importer: {
        title: 'Import Customer Calls To Action',
        fileName: 'callToAction_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    analyticsEvent: {
      name: 'analyticsEvent',
      label: 'AnalyticsEvents',
      menu: 'AnalyticsEvents',
      exporterFileName: 'analyticsEvent_export',
      list: {
        menu: 'AnalyticsEvents',
        title: 'AnalyticsEvents',
      },
      create: {
        success: 'Analytics Event saved successfully',
      },
      update: {
        success: 'Analytics Event saved successfully',
      },
      destroy: {
        success: 'Analytics Event deleted successfully',
      },
      destroyAll: {
        success: 'Analytics Event(s) deleted successfully',
      },
      edit: {
        title: 'Edit Analytics Event',
      },
      fields: {
        id: 'Id',
        event: 'Event',
        payload: 'Payload',
        type: 'Type',
        device: 'Device',
        channel: 'Channel',
        storefront: 'Screen Locations',
        content: 'Content',
        organization: 'Organization',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Analytics Event',
      },
      view: {
        title: 'View Analytics Event',
      },
      importer: {
        title: 'Import AnalyticsEvents',
        fileName: 'analyticsEvent_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    transaction: {
      name: 'transaction',
      label: 'Transactions',
      menu: 'Transactions',
      exporterFileName: 'transaction_export',
      list: {
        menu: 'Transactions',
        title: 'Transactions',
      },
      create: {
        success: 'Transaction saved successfully',
      },
      update: {
        success: 'Transaction saved successfully',
      },
      destroy: {
        success: 'Transaction deleted successfully',
      },
      destroyAll: {
        success: 'Transaction(s) deleted successfully',
      },
      edit: {
        title: 'Edit Transaction',
      },
      fields: {
        id: 'Id',
        amountRange: 'Amount',
        amount: 'Amount',
        whenRange: 'When',
        when: 'When',
        content: 'Content',
        organization: 'Advertiser',
        market: 'Campaign',
        storefront: 'Screen Locations',
        device: 'Device',
        analyticsEvent: 'AnalyticsEvent',
        category: 'Category',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Transaction',
      },
      view: {
        title: 'View Transaction',
      },
      importer: {
        title: 'Import Transactions',
        fileName: 'transaction_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    market: {
      name: 'Campaign',
      label: 'Campaigns',
      menu: 'Campaigns',
      exporterFileName: 'campaign_export',
      list: {
        menu: 'Campaigns',
        title: 'Campaigns',
        ended: 'Ended',
        running: 'Running',
      },
      create: {
        success: 'Campaign saved successfully',
      },
      update: {
        success: 'Campaign saved successfully',
      },
      destroy: {
        success: 'Campaign deleted successfully',
      },
      destroyAll: {
        success: 'Campaign(s) deleted successfully',
      },
      edit: {
        title: 'Edit Campaigns',
      },
      fields: {
        id: 'Id',
        content: 'Content',
        storefront: 'Screen Locations',
        contentOwned: 'Content',
        device: 'Device',
        budget: 'Budget',
        spent: 'Spent',
        cpm: 'CPM',
        cpmMax: 'Maximum CPC bid limit',
        cpmMax_s: 'Max CPM',
        actualCPM: 'Actual CPM',
        visitors: 'Impressions',
        naAmount: 'Non-visitor Adjusted Cost',
        plays: 'Plays',
        startDate: 'Start Date',
        endDate: 'End Date',
        active: 'Is Campaign Still Active',
        active_s: 'Active?',
        name: 'Campaign Name',
        organization: 'Advertiser',
        spendType: 'Spend Type',
        transactions: 'Transactions',
        analytics: 'Analytics',
        netValueRange: 'Net Value',
        netValue: 'Net Value',
        notes: 'Additional Notes',
        contentBidDateRange: 'Content Bid Date',
        contentBidDate: 'Content Bid Date',
        contentBidValueRange: 'Content Bid Value',
        contentBidValue: 'Content Bid Value',
        elapsedLiveTimeRange: 'Elapsed Live Time',
        elapsedLiveTime: 'Elapsed Live Time',
        payload: 'Payload',
        locationGroups: 'Location Group(s)',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Campaign',
      },
      view: {
        title: 'View Campaign',
      },
      importer: {
        title: 'Import Campaigns',
        fileName: 'market_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    permission: {
      title: 'Permission Groups',
      name: 'Permission',
      label: 'Permissions',
      menu: 'Permissions',
      create: {
        success: 'Permission saved successfully',
      },
      update: {
        success: 'Permission saved successfully',
      },
      destroy: {
        success: 'Permission deleted successfully',
      },
      destroyAll: {
        success: 'Permission(s) deleted successfully',
      },
      edit: {
        title: 'Edit Permission',
      },
      fields: {
        id: 'Id',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
    },

    weblift: {
      reportType: {
        midReview: 'Mid-campaign Review',
        endReview: 'End-of-campaign Review',
      },
    },

    channel: {
      name: 'channel',
      label: 'Channels',
      menu: 'Channels',
      exporterFileName: 'channel_export',
      list: {
        menu: 'Channels',
        title: 'Channels',
      },
      create: {
        success: 'Channel saved successfully',
      },
      update: {
        success: 'Channel saved successfully',
      },
      destroy: {
        success: 'Channel deleted successfully',
      },
      destroyAll: {
        success: 'Channel(s) deleted successfully',
      },
      edit: {
        title: 'Edit Channel',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        slug: 'Slug',
        url: 'Url',
        socketStream: 'SocketStream',
        devices: 'Devices',
        content: 'Content',
        marketplaceListing: 'Marketplace Listing',
        analyticsEvents: 'Analytics Events',
        fromRange: 'From',
        from: 'From',
        toRange: 'To',
        to: 'To',
        type: 'Type',
        active: 'Active',
        tags: 'Tags',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Channel',
      },
      view: {
        title: 'View Channel',
      },
      importer: {
        title: 'Import Channels',
        fileName: 'channel_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    tag: {
      name: 'tag',
      label: 'Tags',
      menu: 'Tags',
      exporterFileName: 'tag_export',
      list: {
        menu: 'Tags',
        title: 'Tags',
      },
      create: {
        success: 'Tag saved successfully',
      },
      update: {
        success: 'Tag saved successfully',
      },
      destroy: {
        success: 'Tag deleted successfully',
      },
      destroyAll: {
        success: 'Tag(s) deleted successfully',
      },
      edit: {
        title: 'Edit Tag',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        label: 'Label',
        type: 'Type',
        analyticsTriggers: 'Analytics Triggers',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        type: {
          general: 'General',
          restricted: 'Restricted',
          internal: 'Internal',
          content: 'Content',
          customer: 'Customer',
          device: 'Device',
          stream: 'Stream',
          partner: 'Partner',
        },
      },
      new: {
        title: 'New Tag',
      },
      view: {
        title: 'View Tag',
      },
      importer: {
        title: 'Import Tags',
        fileName: 'tag_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    status: {
      name: 'status',
      label: 'Statuses',
      menu: 'Statuses',
      exporterFileName: 'status_export',
      list: {
        menu: 'Statuses',
        title: 'Statuses',
      },
      create: {
        success: 'Status saved successfully',
      },
      update: {
        success: 'Status saved successfully',
      },
      destroy: {
        success: 'Status deleted successfully',
      },
      destroyAll: {
        success: 'Status(s) deleted successfully',
      },
      edit: {
        title: 'Edit Status',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        value: 'Value',
        role: 'Role',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        role: {
          Role1: 'Role1',
          Role2: 'Role2',
          Role3Role3: 'Role3Role3',
        },
      },
      new: {
        title: 'New Status',
      },
      view: {
        title: 'View Status',
      },
      importer: {
        title: 'Import Statuses',
        fileName: 'status_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    device: {
      name: 'device',
      label: 'Devices',
      menu: 'Devices',
      exporterFileName: 'device_export',
      list: {
        menu: 'Devices',
        title: 'Devices',
      },
      create: {
        success: 'Device saved successfully',
      },
      update: {
        success: 'Device saved successfully',
      },
      destroy: {
        success: 'Device deleted successfully',
      },
      destroyAll: {
        success: 'Device(s) deleted successfully',
      },
      edit: {
        title: 'Edit Device',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        channels: 'Channels',
        assigned: 'Assigned',
        status: 'Status',
        lastSeenRange: 'LastSeen',
        lastSeen: 'LastSeen',
        adContentRatio: 'Ad/Non-Ad Content Ratio',
        ip: 'Ip',
        gps: 'Gps',
        deviceProfile: 'Device Profile',
        deviceNetwork: 'Device Network',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
        exclusionaryCategories: 'Exclusionary Categories',
        inclusionaryCategories: 'Inclusionary Categories',
      },
      enumerators: {},
      new: {
        title: 'New Device',
      },
      view: {
        title: 'View Device',
      },
      importer: {
        title: 'Import Devices',
        fileName: 'device_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    deviceProfile: {
      name: 'deviceProfile',
      label: 'Device Profiles',
      menu: 'Device Profiles',
      exporterFileName: 'deviceProfile_export',
      list: {
        menu: 'Device Profiles',
        title: 'Device Profiles',
      },
      create: {
        success: 'Device Profile saved successfully',
      },
      update: {
        success: 'Device Profile saved successfully',
      },
      destroy: {
        success: 'Device Profile deleted successfully',
      },
      destroyAll: {
        success: 'Device Profile(s) deleted successfully',
      },
      edit: {
        title: 'Edit Device Profile',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        productImage: 'Product Image',
        capabilitiesAvailable: 'Capabilities Available',
        status: 'Status',
        version: 'Version',
        capabilitiesEnabled: 'Capabilities Enabled',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Device Profile',
      },
      view: {
        title: 'View Device Profile',
      },
      importer: {
        title: 'Import Device Profiles',
        fileName: 'deviceProfile_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    deviceCapability: {
      name: 'deviceCapability',
      label: 'Device Capabilities',
      menu: 'Device Capabilities',
      exporterFileName: 'deviceCapability_export',
      list: {
        menu: 'Device Capabilities',
        title: 'Device Capabilities',
      },
      create: {
        success: 'Device Capability saved successfully',
      },
      update: {
        success: 'Device Capability saved successfully',
      },
      destroy: {
        success: 'Device Capability deleted successfully',
      },
      destroyAll: {
        success: 'Device Capability(s) deleted successfully',
      },
      edit: {
        title: 'Edit Device Capability',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        value: 'Value',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Device Capability',
      },
      view: {
        title: 'View Device Capability',
      },
      importer: {
        title: 'Import Device Capabilities',
        fileName: 'deviceCapability_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    deviceNetwork: {
      name: 'deviceNetwork',
      label: 'Device Networks',
      menu: 'Device Networks',
      exporterFileName: 'deviceNetwork_export',
      list: {
        menu: 'Device Networks',
        title: 'Device Networks',
      },
      create: {
        success: 'Device Network saved successfully',
      },
      update: {
        success: 'Device Network saved successfully',
      },
      destroy: {
        success: 'Device Network deleted successfully',
      },
      destroyAll: {
        success: 'Device Network(s) deleted successfully',
      },
      edit: {
        title: 'Edit Device Network',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        twilioSid: 'Twilio SIM ID',
        twilioRatePlan: 'Twilio Rate Plan',
        type: 'Type',
        status: 'Status',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Device Network',
      },
      view: {
        title: 'View Device Network',
      },
      importer: {
        title: 'Import Device Networks',
        fileName: 'deviceNetwork_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    organizationType: {
      name: 'organizationType',
      label: 'Organization Types',
      menu: 'Organization Types',
      exporterFileName: 'organizationType_export',
      list: {
        menu: 'Organization Types',
        title: 'Organization Types',
      },
      create: {
        success: 'Organization Type saved successfully',
      },
      update: {
        success: 'Organization Type saved successfully',
      },
      destroy: {
        success: 'Organization Type deleted successfully',
      },
      destroyAll: {
        success: 'Organization Type(s) deleted successfully',
      },
      edit: {
        title: 'Edit Organization Type',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        value: 'Value',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Organization Type',
      },
      view: {
        title: 'View Organization Type',
      },
      importer: {
        title: 'Import Organization Types',
        fileName: 'organizationType_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    category: {
      name: 'category',
      label: 'Categories',
      menu: 'Categories',
      exporterFileName: 'category_export',
      list: {
        menu: 'Categories',
        title: 'Categories',
      },
      create: {
        success: 'Category saved successfully',
      },
      update: {
        success: 'Category saved successfully',
      },
      destroy: {
        success: 'Category deleted successfully',
      },
      destroyAll: {
        success: 'Category(s) deleted successfully',
      },
      edit: {
        title: 'Edit Category',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        tags: 'Tags',
        status: 'Status',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Category',
      },
      view: {
        title: 'View Category',
      },
      importer: {
        title: 'Import Categories',
        fileName: 'category_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    sector: {
      name: 'sector',
      label: 'Sectors',
      menu: 'Sectors',
      exporterFileName: 'sector_export',
      list: {
        menu: 'Sectors',
        title: 'Sectors',
      },
      create: {
        success: 'Sector saved successfully',
      },
      update: {
        success: 'Sector saved successfully',
      },
      destroy: {
        success: 'Sector deleted successfully',
      },
      destroyAll: {
        success: 'Sector(s) deleted successfully',
      },
      edit: {
        title: 'Edit Sector',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        value: 'Value',
        classification: 'Classification',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Sector',
      },
      view: {
        title: 'View Sector',
      },
      importer: {
        title: 'Import Sectors',
        fileName: 'sector_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    priceRequest: {
      fields: {
        id: 'Id',
        unadjustedPrice: 'Initial Price',
        requestedPrice: 'Requested price',
        storefrontName: 'Inventory name',
        createdAt: 'Requested At',
        requestExpiresAt: 'Discount Available Until',
        ownerApproved: 'Approve/Deny',
      },
      submit: {
        success: 'Request successfully submitted!',
        error: 'Failed submitting request!',
      },
    },

    invoice: {
      paymentOptions: {
        ach_credit_transfer: 'ACH',
        card: 'Credit Card',
        paper_check: 'Paper Check',
        osai_financing: 'OneScreen.AI Financing',
      },
      create: {
        success: 'A New Invoice has been created',
        error: 'Failed submitting request!',
      },
      update: {
        success: 'Invoice saved successfully',
      },
      destroy: {
        success: 'Invoice deleted successfully',
      },
    },
  },

  auth: {
    profile: {
      title: 'Edit Profile',
      success: 'Profile updated successfully',
      settings: 'Settings',
      changePassword: 'Change Password',
    },
    notification: {
      title: 'Setup notification',
      device: 'Device notification',
    },
    createAnAccount: 'Create an account',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot password',
    changePassword: 'Change password',
    signin: 'Sign in',
    signup: 'Sign up',
    signout: 'Sign out',
    alreadyHaveAnAccount: 'Already have an account? Sign in.',
    signinWithAnotherAccount: 'Sign in with another account',
    emailUnverified: {
      message: 'Please confirm your email at <strong>{0}</strong> to continue.',
      submit: 'Resend email verification',
    },
    emptyPermissions: {
      message:
        'Thank you for signing up. A OneScreen account representative will follow up shortly to get you set up!',
    },
    passwordResetEmail: {
      message: 'Send password reset email',
      error: 'Email not recognized',
    },
    passwordReset: {
      message: 'Reset password',
    },
    emailAddressVerificationEmail: {
      error: 'Email not recognized',
    },
    verificationEmailSuccess: 'Verification email sent successfully',
    passwordResetEmailSuccess: 'Password reset email sent successfully, please check your email.',
    passwordResetSuccess: 'Password changed successfully',
    verifyEmail: {
      success: 'Email successfully verified',
      message: 'Just a moment, your email is being verified...',
    },
  },

  roles: {
    owner: {
      label: 'Owner',
      description: 'Full access to all resources',
    },
    editor: {
      label: 'Editor',
      description: 'Edit access to all resources',
    },
    viewer: {
      label: 'Viewer',
      description: 'View access to all resources',
    },
    auditLogViewer: {
      label: 'Audit Log Viewer',
      description: 'Access to view audit logs',
    },
    iamSecurityReviewer: {
      label: 'Security Reviewer',
      description: 'Full access to manage users roles',
    },
    entityEditor: {
      label: 'Entity Editor',
      description: 'Edit access to all entities',
    },
    entityViewer: {
      label: 'Entity Viewer',
      description: 'View access to all entities',
    },
    contentEditor: {
      label: 'Content Editor',
      description: 'Edit access to Contents',
    },
    contentViewer: {
      label: 'Content Viewer',
      description: 'View access to Contents',
    },
    storefrontEditor: {
      label: 'Screen Locations Editor',
      description: 'Edit access to Screen Locations',
    },
    storefrontViewer: {
      label: 'Screen Locations Viewer',
      description: 'View access to Screen Locations',
    },
    locationGroupEditor: {
      label: 'Location Group Editor',
      description: 'Edit access to Location Groups',
    },
    locationGroupViewer: {
      label: 'Location Group Viewer',
      description: 'View access to Location Groups',
    },
    organizationEditor: {
      label: 'Organization Editor',
      description: 'Edit access to Organizations',
    },
    organizationViewer: {
      label: 'Organization Viewer',
      description: 'View access to Organizations',
    },
    customerProfileEditor: {
      label: 'Customer Profile Editor',
      description: 'Edit access to CustomerProfiles',
    },
    customerProfileViewer: {
      label: 'Customer Profile Viewer',
      description: 'View access to CustomerProfiles',
    },
    customerAudienceEditor: {
      label: 'Customer Audience Editor',
      description: 'Edit access to Customer Audiences',
    },
    customerAudienceViewer: {
      label: 'Customer Audience Viewer',
      description: 'View access to Customer Audiences',
    },
    callToActionEditor: {
      label: 'Customer Call To Action Editor',
      description: 'Edit access to Customer Calls To Action',
    },
    callToActionViewer: {
      label: 'Customer Call To Action Viewer',
      description: 'View access to Customer Calls To Action',
    },
    analyticsEventEditor: {
      label: 'Analytics Event Editor',
      description: 'Edit access to AnalyticsEvents',
    },
    analyticsEventViewer: {
      label: 'Analytics Event Viewer',
      description: 'View access to AnalyticsEvents',
    },
    transactionEditor: {
      label: 'Transaction Editor',
      description: 'Edit access to Transactions',
    },
    transactionViewer: {
      label: 'Transaction Viewer',
      description: 'View access to Transactions',
    },
    marketEditor: {
      label: 'Market Editor',
      description: 'Edit access to Markets',
    },
    marketViewer: {
      label: 'Market Viewer',
      description: 'View access to Markets',
    },
    channelEditor: {
      label: 'Channel Editor',
      description: 'Edit access to Channels',
    },
    channelViewer: {
      label: 'Channel Viewer',
      description: 'View access to Channels',
    },
    webliftAttribution: {
      label: 'Weblift Attribution',
      description: 'Access to Weblift Attribution',
    },
    tagEditor: {
      label: 'Tag Editor',
      description: 'Edit access to Tags',
    },
    tagViewer: {
      label: 'Tag Viewer',
      description: 'View access to Tags',
    },
    inventoryDirectoryV2: {
      label: 'InventoryDirectoryV2',
      description: 'View access to Inventory Directory V2',
    },
    inventoryDirectoryV3: {
      label: 'InventoryDirectoryV3',
      description: 'View access to Inventory Directory V3',
    },
    statusEditor: {
      label: 'Status Editor',
      description: 'Edit access to Statuses',
    },
    statusViewer: {
      label: 'Status Viewer',
      description: 'View access to Statuses',
    },
    deviceEditor: {
      label: 'Device Editor',
      description: 'Edit access to Devices',
    },
    deviceViewer: {
      label: 'Device Viewer',
      description: 'View access to Devices',
    },
    deviceProfileEditor: {
      label: 'Device Profile Editor',
      description: 'Edit access to Device Profiles',
    },
    deviceProfileViewer: {
      label: 'Device Profile Viewer',
      description: 'View access to Device Profiles',
    },
    deviceCapabilityEditor: {
      label: 'Device Capability Editor',
      description: 'Edit access to Device Capabilities',
    },
    deviceCapabilityViewer: {
      label: 'Device Capability Viewer',
      description: 'View access to Device Capabilities',
    },
    deviceNetworkEditor: {
      label: 'Device Network Editor',
      description: 'Edit access to Device Networks',
    },
    deviceNetworkViewer: {
      label: 'Device Network Viewer',
      description: 'View access to Device Networks',
    },
    organizationTypeEditor: {
      label: 'Organization Type Editor',
      description: 'Edit access to Organization Types',
    },
    organizationTypeViewer: {
      label: 'Organization Type Viewer',
      description: 'View access to Organization Types',
    },
    categoryEditor: {
      label: 'Category Editor',
      description: 'Edit access to Categories',
    },
    companyViewer: {
      label: 'Company Viewer',
      description: 'View access to Company',
    },
    companyEditor: {
      label: 'Company Editor',
      description: 'Edit access to Company',
    },
    contactViewer: {
      label: 'Contact Viewer',
      description: 'View access to Contact',
    },
    contactEditor: {
      label: 'Contact Editor',
      description: 'Edit access to Contact',
    },
    categoryViewer: {
      label: 'Category Viewer',
      description: 'View access to Categories',
    },
    sectorEditor: {
      label: 'Sector Editor',
      description: 'Edit access to Sectors',
    },
    sectorViewer: {
      label: 'Sector Viewer',
      description: 'View access to Sectors',
    },
    partnerViewer: {
      label: 'Partner Viewer',
      description: 'View access to OOH',
    },
    partnerEditor: {
      label: 'Partner Editor',
      description: 'Edit access to OOH',
    },
    agencyCampaignEditor: {
      label: 'Agency Campaign Editor',
      description: 'Edit access to Campaign Agency',
    },
    agencyCampaignViewer: {
      label: 'Agency Campaign Viewer',
      description: 'View access to Campaign Agency',
    },
    preferredVendorContactViewer: {
      label: 'Preferred Vendor Contact Viewer',
      description: 'View access to preferred vendor contacts',
    },
    toolsOverviewPage: {
      label: 'Tools - Overview Page',
      description: 'Tools - Overview Page',
    },
    toolsPersonaBuilder: {
      label: 'Tools - Persona Builder',
      description: 'Tools - Persona Builder',
    },
    toolsCampaignPixelTracking: {
      label: 'Tools - Pixel Tracking',
      description: 'Tools, - Pixel Tracking',
    },
    toolsPlaceRank: {
      label: 'Tools - PlaceRank',
      description: 'Tools - Place Rank',
    },
    toolsRecipes: {
      label: 'Tools - Recipes',
      description: 'Tools - Recipes',
    },
    toolsSlideBuilder: {
      label: 'Tools - PlanBuilder',
      description: 'Tools - Plan Builder',
    },
    toolsThreads: {
      label: 'Tools - Threads',
      description: 'Tools - Threads',
    },
    toolsTracker: {
      label: 'Tools - Tracker',
      description: 'Tools - Tracker',
    },
    toolsMaps: {
      label: 'Tools - Map Studio',
      description: 'Tools - Map Studio',
    },
    toolsDeals: {
      label: 'Tools - Deals',
      description: 'Tools - Deals',
    },
    reportABug: {
      label: 'Report a Bug',
      description: 'Report a Bug',
    },
  },

  iam: {
    title: 'User Management',
    menu: 'IAM',
    disable: 'Disable',
    disabled: 'Disabled',
    enabled: 'Enabled',
    enable: 'Enable',
    doEnableSuccess: 'User enabled successfully',
    doDisableSuccess: 'User disabled successfully',
    doDisableAllSuccess: 'User(s) disabled successfully',
    doEnableAllSuccess: 'User(s) enabled successfully',
    doAddSuccess: 'User(s) saved successfully',
    doUpdateSuccess: 'User saved successfully',
    viewBy: 'View By',
    users: {
      name: 'users',
      label: 'Users',
      exporterFileName: 'users_export',
      doRemoveAllSelectedSuccess: 'Permissions removed successfully',
    },
    roles: {
      label: 'Roles',
      doRemoveAllSelectedSuccess: 'Permissions removed successfully',
    },
    edit: {
      title: 'Edit User',
    },
    new: {
      title: 'Create New User',
      titleModal: 'New User',
      emailsHint: 'Separate multiple email addresses using the comma character.',
    },
    view: {
      title: 'View User',
      activity: 'Activity',
    },
    importer: {
      title: 'Import Users',
      fileName: 'users_import_template',
      hint: 'Files/Images columns must be the URLs of the files separated by space. Relationships must be the ID of the referenced records separated by space. Roles must be the role ids separated by space.',
    },
    errors: {
      userAlreadyExists: 'User with this email already exists',
      userNotFound: 'User not found',
      disablingHimself: "You can't disable yourself",
      revokingOwnPermission: "You can't revoke your own owner permission",
    },
  },

  companyUser: {
    doUpdateSuccess: 'User saved successfully',
    errors: {
      userAlreadyExists: 'User with this email already exists',
      userNotFound: 'User not found',
    },
  },

  user: {
    fields: {
      id: 'Id',
      companyId: 'Company Id',
      accountType: 'Account Type',
      admin: 'Admin',
      advertiser: 'Advertiser',
      authenticationUid: 'Authentication Uid',
      avatars: 'Avatar',
      confirmPassword: 'Confirm Password',
      createdAt: 'Created at',
      createdAtRange: 'Created at',
      currentPassword: 'Current Password',
      disabled: 'Disabled',
      email: 'Email',
      emails: 'Email(s)',
      firstName: 'First Name',
      fullName: 'Full Name',
      groupName: 'Permission Group',
      host: 'Host',
      lastActivity: 'Last activity',
      lastName: 'Last Name',
      name: 'Name',
      newPassword: 'New Password',
      partner: 'Partner',
      anonymous: 'Anonymous',
      password: 'Password',
      phoneNumber: 'Phone Number',
      rememberMe: 'Remember me',
      role: 'Role',
      roles: 'Roles',
      roleUser: 'Role/User',
      status: 'Status',
      updatedAt: 'Updated at',
    },
    enabled: 'Enabled',
    disabled: 'Disabled',
    validations: {
      // eslint-disable-next-line
      email: 'Email ${value} is invalid',
    },
    addOwner: 'Add Owner',
  },

  auditLog: {
    menu: 'Audit Logs',
    title: 'Audit Logs',
    exporterFileName: 'audit_log_export',
    entityNamesHint: 'Separate multiple entities using the comma character.',
    fields: {
      id: 'Id',
      timestampRange: 'Period',
      entityName: 'Entity',
      entityNames: 'Entities',
      entityId: 'Entity ID',
      action: 'Action',
      values: 'Values',
      timestamp: 'Date',
      createdByEmail: 'User Email',
    },
  },
  settings: {
    title: 'Settings',
    menu: 'Settings',
    save: {
      success:
        'Settings saved successfully. The page will reload in {0} seconds for changes to take effect.',
    },
    fields: {
      primary: 'Primary Color',
      secondary: 'Secondary Color',
      shade: 'Shade',
    },
  },
  home: {
    menu: 'Home',
    message: 'Fake data for now.',
    charts: {
      day: 'Day',
      red: 'Red',
      green: 'Green',
      yellow: 'Yellow',
      grey: 'Grey',
      blue: 'Blue',
      orange: 'Orange',
      months: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
      },
      eating: 'Eating',
      drinking: 'Drinking',
      sleeping: 'Sleeping',
      designing: 'Designing',
      coding: 'Coding',
      cycling: 'Cycling',
      running: 'Running',
      customer: 'Customer',
    },
  },
  dashboard: {
    menu: 'Dashboard',
  },
  errors: {
    backToHome: 'Back to home',
    backToTheDirectory: 'Back to the Directory',
    403: "Sorry, you don't have access to this page",
    404: 'This page is not available.',
    title_404: 'Oops! Something went wrong.',
    500: 'Sorry, the server is reporting an error',
    forbidden: {
      message: 'Forbidden',
    },
    validation: {
      message: 'An error occurred',
    },
    defaultErrorMessage: "Sorry, that didn't work. Check your input and try again.",
  },
  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path} is invalid',
      required: '${path} is required',
      oneOf: '${path} must be one of the following values: ${values}',
      notOneOf: '${path} must not be one of the following values: ${values}',
      notType: ({ path, type, value, originalValue }) => {
        return `${path} must be a ${type}`;
      },
    },
    string: {
      length: '${path} must be exactly ${length} characters',
      min: '${path} must be at least ${min} characters',
      max: '${path} must be at most ${max} characters',
      matches: '${path} must match the following: "${regex}"',
      email: '${path} must be a valid email',
      url: '${path} must be a valid URL',
      trim: '${path} must be a trimmed string',
      lowercase: '${path} must be a lowercase string',
      uppercase: '${path} must be a upper case string',
      selected: '${path} must be selected',
    },
    number: {
      min: '${path} must be greater than or equal to ${min}',
      max: '${path} must be less than or equal to ${max}',
      lessThan: '${path} must be less than ${less}',
      moreThan: '${path} must be greater than ${more}',
      notEqual: '${path} must be not equal to ${notEqual}',
      positive: '${path} must be a positive number',
      negative: '${path} must be a negative number',
      integer: '${path} must be an integer',
    },
    date: {
      min: '${path} field must be later than ${min}',
      max: '${path} field must be at earlier than ${max}',
    },
    boolean: {},
    object: {
      noUnknown: '${path} field cannot have keys not specified in the object shape',
    },
    array: {
      min: '${path} field must have at least ${min} items',
      max: '${path} field must have less than or equal to ${max} items',
    },
  },
  social: {
    facebook: 'Facebook',
    twitter: 'Twitter',
    linkedin: 'LinkedIn',
    instagram: 'Instagaram',
    youtube: 'YouTube',
  },
  /* eslint-disable */
  fileUploader: {
    upload: 'Upload Inventory File',
    image: 'You must upload an image',
    size: 'File is too big. Max allowed size is {0}',
    formats: `Invalid format. Must be '{0}'.`,
  },
  importer: {
    line: 'Line',
    status: 'Status',
    pending: 'Pending',
    imported: 'Imported',
    error: 'Error',
    total: `{0} imported, {1} pending and {2} with error`,
    importedMessage: `Processed {0} of {1}.`,
    noNavigateAwayMessage: 'Do not navigate away from this page or import will be stopped.',
    completed: {
      success: 'Import completed. All rows were successfully imported.',
      someErrors: 'Processing completed, but some rows were unable to be imported.',
      allErrors: 'Import failed. There are no valid rows.',
    },
    form: {
      downloadTemplate: 'EXPORT INVENTORY TEMPLATE',
      hint: 'Click or drag the file to this area to continue',
    },
    list: {
      discardConfirm: 'Are you sure? Non-imported data will be lost.',
    },
    errors: {
      invalidFileEmpty: 'The file is empty',
      invalidFileExcel: 'Only Excel (.xlsx) files are allowed',
      invalidFileExcelOrCSV: 'Only Excel (.xlsx/.xls) or CSV (.csv) files are allowed',
      invalidFileUpload: 'Invalid file. Make sure you are using the last version of the template.',
      importHashRequired: 'Import hash is required',
      importHashExistent: 'Data has already been imported',
    },
  },

  autocomplete: {
    loading: 'Loading...',
    noOptions: 'No data found',
  },

  imagesViewer: {
    noImage: 'No image',
  },

  table: {
    noData: 'No records found',
    loading: 'Loading...',
  },

  pagination: {
    labelDisplayedRows: '{0}-{1} of {2}',
    labelRowsPerPage: 'Per page:',
  },

  cart: {
    alert: 'Select one or more inventory items below to create a campaign.',
    addToCartTitle: 'Inventory added to the cart successfully',
    addToCartError: 'Your attempt to add inventory to the cart failed. Please try again.',
  },
};

export default en;
