import { FilestackRef, FilestackRefModel } from '../filestack/Filestack';
import { PartnerRef, PartnerRefModel } from '../partner/Partner';
import { AgencyCampaign, AgencyCampaignModel } from './AgencyCampaign';

export class PortalRFPDataModel {
  isValid: boolean;
  requestForProposalId: string;
  supplyPartner: PartnerRefModel;
  agencyCampaign: AgencyCampaignModel;
  hasResponded: boolean;
  responseFile: FilestackRefModel;
  mediaFiles: FilestackRefModel[];
}

export class PortalRFPData {
  static create(model: PortalRFPDataModel): PortalRFPData {
    const {
      isValid,
      requestForProposalId,
      supplyPartner,
      agencyCampaign,
      hasResponded,
      responseFile,
      mediaFiles,
    } = model;
    return new PortalRFPData(
      isValid,
      requestForProposalId,
      PartnerRef.create(supplyPartner),
      AgencyCampaign.create(agencyCampaign),
      hasResponded,
      responseFile ? FilestackRef.create(responseFile) : undefined,
      mediaFiles.length ? mediaFiles.map((file) => FilestackRef.create(file)) : [],
    );
  }

  constructor(
    public isValid: boolean,
    public requestForProposalId: string,
    public supplyPartner: PartnerRef,
    public agencyCampaign: AgencyCampaign,
    public hasResponded: boolean,
    public responseFile: FilestackRef,
    public mediaFiles: FilestackRef[],
  ) {}
}
