const ptBR = {
  common: {
    or: 'ou',
    cancel: 'Cancelar',
    reset: 'Limpar',
    save: 'Salvar',
    search: 'Buscar',
    edit: 'Editar',
    remove: 'Remover',
    approve: 'Approve',
    approved: 'Approved',
    deny: 'Deny',
    denied: 'Denied',
    expired: 'Expired',
    new: 'Novo',
    export: 'Exportar para Excel',
    noDataToExport: 'Não há dados para exportar',
    import: 'Importar',
    inventory: 'Inventory',
    confirm: 'Confirm',
    fileFormat: {
      asCSV: '.CSV',
      asXLSX: '.XLSX',
    },
    discard: 'Descartar',
    yes: 'Sim',
    no: 'Não',
    pause: 'Pausar',
    areYouSure: 'Tem certeza?',
    view: 'Visualizar',
    destroy: 'Deletar',
    mustSelectARow: 'Selecine uma linha',
    start: 'Inicio',
    end: 'Fim',
    streetView: 'Street View',
    embedSectionOnSite: 'Embed Section On Your Website',
  },

  app: {
    title: 'Aplicação',
  },

  entities: {
    content: {
      name: 'Content',
      label: 'Contents',
      menu: 'Contents',
      exporterFileName: 'Content_exportados',
      list: {
        menu: 'Contents',
        title: 'Contents',
      },
      create: {
        success: 'Content salvo com sucesso',
      },
      update: {
        success: 'Content salvo com sucesso',
      },
      destroy: {
        success: 'Content deletado com sucesso',
      },
      destroyAll: {
        success: 'Content(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Content',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        description: 'Description',
        media: 'Media',
        tags: 'Tags',
        organizationOwner: 'Owner (Organization)',
        cta: 'Cta',
        transactions: 'Transactions',
        analyticsEvents: 'Analytics Events',
        minBidAmountRange: 'Min Bid Amount',
        minBidAmount: 'Min Bid Amount',
        maxBidAmountRange: 'Max Bid Amount',
        maxBidAmount: 'Max Bid Amount',
        activeFromRange: 'Active From Date',
        activeFrom: 'Active From Date',
        activeToRange: 'Active To Date',
        activeTo: 'Active To Date',
        ongoing: 'Ongoing',
        status: 'Status',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {},
      new: {
        title: 'Novo Content',
      },
      view: {
        title: 'Visualizar Content',
      },
      importer: {
        title: 'Importar Contents',
        fileName: 'content_template_importacao',
        hint: 'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    storefront: {
      name: 'Storefront',
      label: 'Storefronts',
      menu: 'Storefronts',
      exporterFileName: 'Storefront_exportados',
      list: {
        menu: 'Storefronts',
        title: 'Storefronts',
      },
      create: {
        success: 'Storefront salvo com sucesso',
      },
      update: {
        success: 'Storefront salvo com sucesso',
      },
      destroy: {
        success: 'Storefront deletado com sucesso',
      },
      destroyAll: {
        success: 'Storefront(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Storefront',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        url: 'Url',
        physicalAddress: 'PhysicalAddress',
        content: 'Content',
        customerProfiles: 'CustomerProfiles',
        device: 'Device',
        currentBalanceRange: 'Current Balance',
        currentBalance: 'Current Balance',
        tags: 'Tags',
        users: 'Users',
        owner: 'Owner',
        organizationType: 'Organization Type',
        organizationCategory: 'Organization Categories',
        totalRevenueRange: 'Total Revenue',
        totalRevenue: 'Total Revenue',
        transactions: 'Transactions',
        status: 'Status',
        analyticsEvents: 'Analytics Events',
        organizationSector: 'Organization Sector',
        availability: 'Available dates',
        geopathId: 'Geopath ID',
        unitNumber: 'Unit #',
        description: 'Description',
        notes: 'Additional Notes',
        dimensions: 'Dimensions',
        inventoryType: 'Inventory Type',
        facing: {
          label: 'Facing',
          N: 'North',
          NW: 'NorthWest',
          W: 'West',
          SW: 'SouthWest',
          S: 'South',
          SE: 'SouthEast',
          E: 'East',
          NE: 'NorthEast',
        },
        position: {
          label: 'LHR/RHR',
          LHR: 'Left',
          RHR: 'Right',
        },
        weeklyAdultImpressionsCount: 'Weekly A18+ Impressions',
        uniqueAdultAdReachedCount: 'A18+ Reach',
        averageAdultFrequencyReach: 'A18+ Frequency',
        grp: 'GRP (Gross Rating Points)',
        spotLength: 'Spot Length',
        loopLength: 'Loop Length',
        sov: 'SOV%',
        advertisersCount: 'Current # of Advertisers',
        fourWeekRateNegotiatedCost: '4 Week Rate Negotiated Cost',
        fourWeekRateCardCost: '4 Week Rate Card Cost',
        productionCost: 'Production Cost',
        installCost: 'Install Cost',
        panelSize: 'Panel Size',
        vinylSize: 'Vinyl Size',
        shippingAddress: 'Shipping Address',
        illuminated: {
          label: 'Illuminated',
          yes: 'Yes',
          no: 'No',
        },
        staticImageAd: {
          label: 'Static Image Ad',
          yes: 'Yes',
          no: 'No',
        },
        motionGraphicAd: {
          label: 'Motion Graphic Ad',
          yes: 'Yes',
          no: 'No',
        },
        audioAd: {
          label: 'Audio Ad',
          yes: 'Yes',
          no: 'No',
        },
        lengthLimit: 'Length Limit',
        fileSizeLimitMB: 'Size Limit',
        categoryRestrictions: 'Category Restrictions',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {},
      new: {
        title: 'Novo Storefront',
      },
      view: {
        title: 'Visualizar Storefront',
      },
      importer: {
        title: 'Importar Storefronts',
        fileName: 'storefront_template_importacao',
        hint: 'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
        fieldMapperPage: 'Check all the fields you want to import',
      },
    },

    availability: {
      fields: {
        id: 'Id',
        price: 'Price',
        available: 'Available',
        date: 'Date',
        purchased: 'Purchased',
      },
    },

    organization: {
      name: 'Organization',
      label: 'Organizations',
      menu: 'Organizations',
      exporterFileName: 'Organization_exportados',
      list: {
        menu: 'Organizations',
        title: 'Organizations',
      },
      create: {
        success: 'Organization salvo com sucesso',
      },
      update: {
        success: 'Organization salvo com sucesso',
      },
      destroy: {
        success: 'Organization deletado com sucesso',
      },
      destroyAll: {
        success: 'Organization(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Organization',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        url: 'Url',
        balanceRange: 'Balance',
        balance: 'Balance',
        users: 'Users',
        owner: 'Owner',
        organizationCategory: 'Organization Categories',
        organizationType: 'Organization Type',
        status: 'Status',
        active: 'Active',
        contentOwned: 'Content Owned',
        transactions: 'Transactions',
        analyticsEvents: 'Analytics Events',
        organizationSector: 'Organization Sector',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {},
      new: {
        title: 'Novo Organization',
      },
      view: {
        title: 'Visualizar Organization',
      },
      importer: {
        title: 'Importar Organizations',
        fileName: 'organization_template_importacao',
        hint: 'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    customerProfile: {
      name: 'Customer Profile',
      label: 'CustomerProfiles',
      menu: 'CustomerProfiles',
      exporterFileName: 'Customer Profile_exportados',
      list: {
        menu: 'CustomerProfiles',
        title: 'CustomerProfiles',
      },
      create: {
        success: 'Customer Profile salvo com sucesso',
      },
      update: {
        success: 'Customer Profile salvo com sucesso',
      },
      destroy: {
        success: 'Customer Profile deletado com sucesso',
      },
      destroyAll: {
        success: 'Customer Profile(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Customer Profile',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        birthdateRange: 'Birthdate',
        birthdate: 'Birthdate',
        gender: 'Gender',
        tags: 'Interest Tags',
        audienceProfile: 'Customer Audience Profile',
        analyticsEvents: 'Analytics Events',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {
        gender: {
          male: 'Male',
          female: 'Female',
        },
      },
      new: {
        title: 'Novo Customer Profile',
      },
      view: {
        title: 'Visualizar Customer Profile',
      },
      importer: {
        title: 'Importar CustomerProfiles',
        fileName: 'customerProfile_template_importacao',
        hint: 'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    customerAudience: {
      name: 'Customer Audience',
      label: 'Customer Audiences',
      menu: 'Customer Audiences',
      exporterFileName: 'Customer Audience_exportados',
      list: {
        menu: 'Customer Audiences',
        title: 'Customer Audiences',
      },
      create: {
        success: 'Customer Audience salvo com sucesso',
      },
      update: {
        success: 'Customer Audience salvo com sucesso',
      },
      destroy: {
        success: 'Customer Audience deletado com sucesso',
      },
      destroyAll: {
        success: 'Customer Audience(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Customer Audience',
      },
      fields: {
        id: 'Id',
        slug: 'Slug',
        name: 'Name',
        demographic: 'Demographic',
        income: 'Income',
        active: 'Active',
        tags: 'Audience Tags',
        sector: 'Sector',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {},
      new: {
        title: 'Novo Customer Audience',
      },
      view: {
        title: 'Visualizar Customer Audience',
      },
      importer: {
        title: 'Importar Customer Audiences',
        fileName: 'customerAudience_template_importacao',
        hint: 'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    callToAction: {
      name: 'Customer Call To Action',
      label: 'Customer Calls To Action',
      menu: 'Customer Calls To Action',
      exporterFileName: 'Customer Call To Action_exportados',
      list: {
        menu: 'Customer Calls To Action',
        title: 'Customer Calls To Action',
      },
      create: {
        success: 'Customer Call To Action salvo com sucesso',
      },
      update: {
        success: 'Customer Call To Action salvo com sucesso',
      },
      destroy: {
        success: 'Customer Call To Action deletado com sucesso',
      },
      destroyAll: {
        success: 'Customer Call To Action(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Customer Call To Action',
      },
      fields: {
        id: 'Id',
        type: 'Type',
        label: 'Label',
        details: 'Details',
        url: 'Url',
        action: 'Action',
        tags: 'Tags',
        analyticsEvents: 'Analytics Events',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {
        type: {
          phone: 'Phone',
          sms: 'Sms',
          url: 'Url',
          qrc: 'Qrc',
        },
      },
      new: {
        title: 'Novo Customer Call To Action',
      },
      view: {
        title: 'Visualizar Customer Call To Action',
      },
      importer: {
        title: 'Importar Customer Calls To Action',
        fileName: 'callToAction_template_importacao',
        hint: 'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    ooh: {
      name: 'Directory',
      label: 'Directory',
      menu: 'Directory',
      exporterFileName: 'ooh_export',
      list: {
        menu: 'Directory',
        title: 'Directory',
        notCompanyListed: "Don't see your company listed?",
      },
      create: {
        success: 'Partner Directory Page saved successfully',
      },
      update: {
        success: 'Partner Directory Page saved successfully',
      },
      destroy: {
        success: 'Partner Directory Page deleted successfully',
      },
      destroyAll: {
        success: 'Partner Directory Page(s) deleted successfully',
      },
      edit: {
        title: 'Edit Partner Directory Page',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        logo: 'Logo',
        url: 'Url',
        description: 'Description',

        street: 'Address Street',
        city: 'Address City',
        state: 'Address State',
        zip: 'Address Zip',
        country: 'Address Country',

        address: 'Address',
        phone: 'Phone',
        contact: 'Contact',
        locations: 'Locations',
        markets: 'Markets',
        dsp: 'Demand Side Platform (DSP)',
        staticImageAd: 'Supports Static Ad',
        motionGraphicAd: 'Supports Video Ad',
        audioAd: 'Supports Audio in Ad',
        budgetRequirements: 'Budget Requirements',
        campaignType: 'Campaign Type',
        campaignTypes: 'Campaign Types',
        inventoryType: 'Inventory Type',
        inventoryTypes: 'Inventory Types',
        tier: 'TierTypes',
        serviceType: 'Service Type',
        serviceTypes: 'Service Types',
        creativeRequirements: 'Creative Reqs',
        benefits: 'Benefits', // Renamed from 'pros' TODO
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
        multiple: 'Created at',
        partnerRegionLocationStates: 'Partner Region Location States',
        timeToLaunchDetails: 'Launch campaign details',
        internalNotes: 'Internal Notes',
        timeToLaunch: 'Time to launch the campaign',
        cc: 'Carbon copy emails',

        contactName: 'Contact name',
        contactEmail: 'Contact email',
        contactTitle: 'Contact Job Title',
        contactPhone: 'Contact phone',
      },
      enumerators: {
        timeToLaunch: {
          lessThanWeek: 'Less than week',
          moreThanWeek: 'More than week',
        },
      },
      new: {
        title: 'New OOH',
      },
      view: {
        title: 'View OOH',
        yourCompany: 'Your company?',
        claimIt: 'Claim it!',
      },
      importer: {
        title: 'Import Partner Inventory Template File',
        fileName: 'locationGroup_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    analyticsEvent: {
      name: 'Analytics Event',
      label: 'AnalyticsEvents',
      menu: 'AnalyticsEvents',
      exporterFileName: 'Analytics Event_exportados',
      list: {
        menu: 'AnalyticsEvents',
        title: 'AnalyticsEvents',
      },
      create: {
        success: 'Analytics Event salvo com sucesso',
      },
      update: {
        success: 'Analytics Event salvo com sucesso',
      },
      destroy: {
        success: 'Analytics Event deletado com sucesso',
      },
      destroyAll: {
        success: 'Analytics Event(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Analytics Event',
      },
      fields: {
        id: 'Id',
        event: 'Event',
        payload: 'Payload',
        type: 'Type',
        device: 'Device',
        channel: 'Channel',
        storefront: 'Storefront',
        content: 'Content',
        organization: 'Organization',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {},
      new: {
        title: 'Novo Analytics Event',
      },
      view: {
        title: 'Visualizar Analytics Event',
      },
      importer: {
        title: 'Importar AnalyticsEvents',
        fileName: 'analyticsEvent_template_importacao',
        hint: 'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    transaction: {
      name: 'Transaction',
      label: 'Transactions',
      menu: 'Transactions',
      exporterFileName: 'Transaction_exportados',
      list: {
        menu: 'Transactions',
        title: 'Transactions',
      },
      create: {
        success: 'Transaction salvo com sucesso',
      },
      update: {
        success: 'Transaction salvo com sucesso',
      },
      destroy: {
        success: 'Transaction deletado com sucesso',
      },
      destroyAll: {
        success: 'Transaction(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Transaction',
      },
      fields: {
        id: 'Id',
        amountRange: 'Amount',
        amount: 'Amount',
        whenRange: 'When',
        when: 'When',
        content: 'Content',
        organization: 'Organization',
        storefront: 'Storefront',
        device: 'Device',
        analyticsEvent: 'AnalyticsEvent',
        category: 'Category',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {},
      new: {
        title: 'Novo Transaction',
      },
      view: {
        title: 'Visualizar Transaction',
      },
      importer: {
        title: 'Importar Transactions',
        fileName: 'transaction_template_importacao',
        hint: 'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    market: {
      name: 'Market',
      label: 'Markets',
      menu: 'Markets',
      exporterFileName: 'Market_exportados',
      list: {
        menu: 'Markets',
        title: 'Markets',
      },
      create: {
        success: 'Market salvo com sucesso',
      },
      update: {
        success: 'Market salvo com sucesso',
      },
      destroy: {
        success: 'Market deletado com sucesso',
      },
      destroyAll: {
        success: 'Market(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Market',
      },
      fields: {
        id: 'Id',
        content: 'Content',
        organization: 'Organization',
        transactions: 'Transactions',
        analytics: 'Analytics',
        netValueRange: 'Net Value',
        netValue: 'Net Value',
        contentBidDateRange: 'ContentBidDate',
        contentBidDate: 'ContentBidDate',
        contentBidValueRange: 'ContentBidValue',
        contentBidValue: 'ContentBidValue',
        elapsedLiveTimeRange: 'Elapsed Live Time',
        elapsedLiveTime: 'Elapsed Live Time',
        payload: 'Payload',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {},
      new: {
        title: 'Novo Market',
      },
      view: {
        title: 'Visualizar Market',
      },
      importer: {
        title: 'Importar Markets',
        fileName: 'market_template_importacao',
        hint: 'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    channel: {
      name: 'Channel',
      label: 'Channels',
      menu: 'Channels',
      exporterFileName: 'Channel_exportados',
      list: {
        menu: 'Channels',
        title: 'Channels',
      },
      create: {
        success: 'Channel salvo com sucesso',
      },
      update: {
        success: 'Channel salvo com sucesso',
      },
      destroy: {
        success: 'Channel deletado com sucesso',
      },
      destroyAll: {
        success: 'Channel(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Channel',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        slug: 'Slug',
        url: 'Url',
        socketStream: 'SocketStream',
        devices: 'Devices',
        content: 'Content',
        marketplaceListing: 'Marketplace Listing',
        analyticsEvents: 'Analytics Events',
        fromRange: 'From',
        from: 'From',
        toRange: 'To',
        to: 'To',
        type: 'Type',
        active: 'Active',
        tags: 'Tags',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {},
      new: {
        title: 'Novo Channel',
      },
      view: {
        title: 'Visualizar Channel',
      },
      importer: {
        title: 'Importar Channels',
        fileName: 'channel_template_importacao',
        hint: 'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    tag: {
      name: 'Tag',
      label: 'Tags',
      menu: 'Tags',
      exporterFileName: 'Tag_exportados',
      list: {
        menu: 'Tags',
        title: 'Tags',
      },
      create: {
        success: 'Tag salvo com sucesso',
      },
      update: {
        success: 'Tag salvo com sucesso',
      },
      destroy: {
        success: 'Tag deletado com sucesso',
      },
      destroyAll: {
        success: 'Tag(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Tag',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        label: 'Label',
        type: 'Type',
        analyticsTriggers: 'Analytics Triggers',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {
        type: {
          partner: 'Partner ',
          general: 'General',
          restricted: 'Restricted',
          internal: 'Internal',
          content: 'Content',
          customer: 'Customer',
          device: 'Device',
          stream: 'Stream',
        },
      },
      new: {
        title: 'Novo Tag',
      },
      view: {
        title: 'Visualizar Tag',
      },
      importer: {
        title: 'Importar Tags',
        fileName: 'tag_template_importacao',
        hint: 'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    status: {
      name: 'Status',
      label: 'Statuses',
      menu: 'Statuses',
      exporterFileName: 'Status_exportados',
      list: {
        menu: 'Statuses',
        title: 'Statuses',
      },
      create: {
        success: 'Status salvo com sucesso',
      },
      update: {
        success: 'Status salvo com sucesso',
      },
      destroy: {
        success: 'Status deletado com sucesso',
      },
      destroyAll: {
        success: 'Status(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Status',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        value: 'Value',
        role: 'Role',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {
        role: {
          Role1: 'Role1',
          Role2: 'Role2',
          Role3Role3: 'Role3Role3',
        },
      },
      new: {
        title: 'Novo Status',
      },
      view: {
        title: 'Visualizar Status',
      },
      importer: {
        title: 'Importar Statuses',
        fileName: 'status_template_importacao',
        hint: 'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    device: {
      name: 'Device',
      label: 'Devices',
      menu: 'Devices',
      exporterFileName: 'Device_exportados',
      list: {
        menu: 'Devices',
        title: 'Devices',
      },
      create: {
        success: 'Device salvo com sucesso',
      },
      update: {
        success: 'Device salvo com sucesso',
      },
      destroy: {
        success: 'Device deletado com sucesso',
      },
      destroyAll: {
        success: 'Device(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Device',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        channels: 'Channels',
        assigned: 'Assigned',
        status: 'Status',
        lastSeenRange: 'LastSeen',
        lastSeen: 'LastSeen',
        ip: 'Ip',
        gps: 'Gps',
        deviceProfile: 'Device Profile',
        deviceNetwork: 'Device Network',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {},
      new: {
        title: 'Novo Device',
      },
      view: {
        title: 'Visualizar Device',
      },
      importer: {
        title: 'Importar Devices',
        fileName: 'device_template_importacao',
        hint: 'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    deviceProfile: {
      name: 'Device Profile',
      label: 'Device Profiles',
      menu: 'Device Profiles',
      exporterFileName: 'Device Profile_exportados',
      list: {
        menu: 'Device Profiles',
        title: 'Device Profiles',
      },
      create: {
        success: 'Device Profile salvo com sucesso',
      },
      update: {
        success: 'Device Profile salvo com sucesso',
      },
      destroy: {
        success: 'Device Profile deletado com sucesso',
      },
      destroyAll: {
        success: 'Device Profile(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Device Profile',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        productImage: 'Product Image',
        capabilitiesAvailable: 'Capabilities Available',
        status: 'Status',
        version: 'Version',
        capabilitiesEnabled: 'Capabilities Enabled',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {},
      new: {
        title: 'Novo Device Profile',
      },
      view: {
        title: 'Visualizar Device Profile',
      },
      importer: {
        title: 'Importar Device Profiles',
        fileName: 'deviceProfile_template_importacao',
        hint: 'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    deviceCapability: {
      name: 'Device Capability',
      label: 'Device Capabilities',
      menu: 'Device Capabilities',
      exporterFileName: 'Device Capability_exportados',
      list: {
        menu: 'Device Capabilities',
        title: 'Device Capabilities',
      },
      create: {
        success: 'Device Capability salvo com sucesso',
      },
      update: {
        success: 'Device Capability salvo com sucesso',
      },
      destroy: {
        success: 'Device Capability deletado com sucesso',
      },
      destroyAll: {
        success: 'Device Capability(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Device Capability',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        value: 'Value',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {},
      new: {
        title: 'Novo Device Capability',
      },
      view: {
        title: 'Visualizar Device Capability',
      },
      importer: {
        title: 'Importar Device Capabilities',
        fileName: 'deviceCapability_template_importacao',
        hint: 'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    deviceNetwork: {
      name: 'Device Network',
      label: 'Device Networks',
      menu: 'Device Networks',
      exporterFileName: 'Device Network_exportados',
      list: {
        menu: 'Device Networks',
        title: 'Device Networks',
      },
      create: {
        success: 'Device Network salvo com sucesso',
      },
      update: {
        success: 'Device Network salvo com sucesso',
      },
      destroy: {
        success: 'Device Network deletado com sucesso',
      },
      destroyAll: {
        success: 'Device Network(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Device Network',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        twilioSid: 'Twilio SIM ID',
        twilioRatePlan: 'Twilio Rate Plan',
        type: 'Type',
        status: 'Status',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {},
      new: {
        title: 'Novo Device Network',
      },
      view: {
        title: 'Visualizar Device Network',
      },
      importer: {
        title: 'Importar Device Networks',
        fileName: 'deviceNetwork_template_importacao',
        hint: 'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    organizationType: {
      name: 'Organization Type',
      label: 'Organization Types',
      menu: 'Organization Types',
      exporterFileName: 'Organization Type_exportados',
      list: {
        menu: 'Organization Types',
        title: 'Organization Types',
      },
      create: {
        success: 'Organization Type salvo com sucesso',
      },
      update: {
        success: 'Organization Type salvo com sucesso',
      },
      destroy: {
        success: 'Organization Type deletado com sucesso',
      },
      destroyAll: {
        success: 'Organization Type(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Organization Type',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        value: 'Value',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {},
      new: {
        title: 'Novo Organization Type',
      },
      view: {
        title: 'Visualizar Organization Type',
      },
      importer: {
        title: 'Importar Organization Types',
        fileName: 'organizationType_template_importacao',
        hint: 'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    category: {
      name: 'Category',
      label: 'Categories',
      menu: 'Categories',
      exporterFileName: 'Category_exportados',
      list: {
        menu: 'Categories',
        title: 'Categories',
      },
      create: {
        success: 'Category salvo com sucesso',
      },
      update: {
        success: 'Category salvo com sucesso',
      },
      destroy: {
        success: 'Category deletado com sucesso',
      },
      destroyAll: {
        success: 'Category(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Category',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        tags: 'Tags',
        status: 'Status',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {},
      new: {
        title: 'Novo Category',
      },
      view: {
        title: 'Visualizar Category',
      },
      importer: {
        title: 'Importar Categories',
        fileName: 'category_template_importacao',
        hint: 'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    sector: {
      name: 'Sector',
      label: 'Sectors',
      menu: 'Sectors',
      exporterFileName: 'Sector_exportados',
      list: {
        menu: 'Sectors',
        title: 'Sectors',
      },
      create: {
        success: 'Sector salvo com sucesso',
      },
      update: {
        success: 'Sector salvo com sucesso',
      },
      destroy: {
        success: 'Sector deletado com sucesso',
      },
      destroyAll: {
        success: 'Sector(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Sector',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        value: 'Value',
        classification: 'Classification',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {},
      new: {
        title: 'Novo Sector',
      },
      view: {
        title: 'Visualizar Sector',
      },
      importer: {
        title: 'Importar Sectors',
        fileName: 'sector_template_importacao',
        hint: 'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    priceRequest: {
      fields: {
        id: 'Id',
        unadjustedPrice: 'Initial Price',
        requestedPrice: 'Requested price',
        storefrontName: 'Inventory name',
        createdAt: 'Requested At',
        requestExpiresAt: 'Discount Available Until',
        ownerApproved: 'Approve/Deny',
      },
      submit: {
        success: 'Request successfully submitted!',
        error: 'Failed submitting request!',
      },
    },

    invoice: {
      paymentOptions: {
        ach_credit_transfer: 'ACH',
        card: 'Credit Card',
        paper_check: 'Paper Check',
        osai_financing: 'OneScreen.AI Financing',
      },
    },
  },

  auth: {
    profile: {
      title: 'Editar Perfil',
      success: 'Perfil atualizado com sucesso',
    },
    createAnAccount: 'Criar uma conta',
    rememberMe: 'Lembrar-me',
    forgotPassword: 'Esqueci minha senha',
    signin: 'Entrar',
    signup: 'Registrar',
    signout: 'Sair',
    alreadyHaveAnAccount: 'Já possui uma conta? Entre.',
    signinWithAnotherAccount: 'Entrar com outra conta',
    emailUnverified: {
      message: 'Por favor, confirme seu email em <strong>{0}</strong> para continuar.',
      submit: 'Reenviar confirmação por email',
    },
    emptyPermissions: {
      message:
        'Você ainda não possui permissões. Aguarde o administrador conceder seus privilégios.',
    },
    passwordResetEmail: {
      message: 'Enviar email de redefinição de senha',
      error: 'Email não encontrado',
    },
    passwordReset: {
      message: 'Alterar senha',
    },
    emailAddressVerificationEmail: {
      error: 'Email não encontrado',
    },
    verificationEmailSuccess: 'Verificação de email enviada com sucesso',
    passwordResetEmailSuccess: 'Email de redefinição de senha enviado com sucesso',
    passwordResetSuccess: 'Senha alterada com sucesso',
    verifyEmail: {
      success: 'Email verificado com sucesso',
      message: 'Aguarde um momento, seu email está sendo verificado...',
    },
  },

  roles: {
    owner: {
      label: 'Proprietário',
      description: 'Acesso completo a todos os recursos',
    },
    editor: {
      label: 'Editor',
      description: 'Acesso para edição a todos os recursos',
    },
    viewer: {
      label: 'Visualizador',
      description: 'Acesso de visualização a todos os recursos',
    },
    auditLogViewer: {
      label: 'Visualizador de Registros de Autoria',
      description: 'Acesso de visualização dos registros de autoria',
    },
    iamSecurityReviewer: {
      label: 'Revisor de segurança',
      description: 'Acesso total para gerenciar as funções do usuário',
    },
    entityEditor: {
      label: 'Editor de Entidades',
      description: 'Acesso de edição a todas as entidades',
    },
    entityViewer: {
      label: 'Visualizador de Entidades',
      description: 'Acesso de visualização a todas as entidades',
    },
    contentEditor: {
      label: 'Editor de Contents',
      description: 'Acesso de edição aos Contents',
    },
    contentViewer: {
      label: 'Visualizador de Contents',
      description: 'Acesso de visualização aos Contents',
    },
    storefrontEditor: {
      label: 'Editor de Storefronts',
      description: 'Acesso de edição aos Storefronts',
    },
    storefrontViewer: {
      label: 'Visualizador de Storefronts',
      description: 'Acesso de visualização aos Storefronts',
    },
    organizationEditor: {
      label: 'Editor de Organizations',
      description: 'Acesso de edição aos Organizations',
    },
    organizationViewer: {
      label: 'Visualizador de Organizations',
      description: 'Acesso de visualização aos Organizations',
    },
    customerProfileEditor: {
      label: 'Editor de CustomerProfiles',
      description: 'Acesso de edição aos CustomerProfiles',
    },
    customerProfileViewer: {
      label: 'Visualizador de CustomerProfiles',
      description: 'Acesso de visualização aos CustomerProfiles',
    },
    customerAudienceEditor: {
      label: 'Editor de Customer Audiences',
      description: 'Acesso de edição aos Customer Audiences',
    },
    customerAudienceViewer: {
      label: 'Visualizador de Customer Audiences',
      description: 'Acesso de visualização aos Customer Audiences',
    },
    callToActionEditor: {
      label: 'Editor de Customer Calls To Action',
      description: 'Acesso de edição aos Customer Calls To Action',
    },
    callToActionViewer: {
      label: 'Visualizador de Customer Calls To Action',
      description: 'Acesso de visualização aos Customer Calls To Action',
    },
    analyticsEventEditor: {
      label: 'Editor de AnalyticsEvents',
      description: 'Acesso de edição aos AnalyticsEvents',
    },
    analyticsEventViewer: {
      label: 'Visualizador de AnalyticsEvents',
      description: 'Acesso de visualização aos AnalyticsEvents',
    },
    transactionEditor: {
      label: 'Editor de Transactions',
      description: 'Acesso de edição aos Transactions',
    },
    transactionViewer: {
      label: 'Visualizador de Transactions',
      description: 'Acesso de visualização aos Transactions',
    },
    marketEditor: {
      label: 'Editor de Markets',
      description: 'Acesso de edição aos Markets',
    },
    marketViewer: {
      label: 'Visualizador de Markets',
      description: 'Acesso de visualização aos Markets',
    },
    channelEditor: {
      label: 'Editor de Channels',
      description: 'Acesso de edição aos Channels',
    },
    channelViewer: {
      label: 'Visualizador de Channels',
      description: 'Acesso de visualização aos Channels',
    },
    tagEditor: {
      label: 'Editor de Tags',
      description: 'Acesso de edição aos Tags',
    },
    tagViewer: {
      label: 'Visualizador de Tags',
      description: 'Acesso de visualização aos Tags',
    },
    statusEditor: {
      label: 'Editor de Statuses',
      description: 'Acesso de edição aos Statuses',
    },
    statusViewer: {
      label: 'Visualizador de Statuses',
      description: 'Acesso de visualização aos Statuses',
    },
    deviceEditor: {
      label: 'Editor de Devices',
      description: 'Acesso de edição aos Devices',
    },
    deviceViewer: {
      label: 'Visualizador de Devices',
      description: 'Acesso de visualização aos Devices',
    },
    deviceProfileEditor: {
      label: 'Editor de Device Profiles',
      description: 'Acesso de edição aos Device Profiles',
    },
    deviceProfileViewer: {
      label: 'Visualizador de Device Profiles',
      description: 'Acesso de visualização aos Device Profiles',
    },
    deviceCapabilityEditor: {
      label: 'Editor de Device Capabilities',
      description: 'Acesso de edição aos Device Capabilities',
    },
    deviceCapabilityViewer: {
      label: 'Visualizador de Device Capabilities',
      description: 'Acesso de visualização aos Device Capabilities',
    },
    deviceNetworkEditor: {
      label: 'Editor de Device Networks',
      description: 'Acesso de edição aos Device Networks',
    },
    deviceNetworkViewer: {
      label: 'Visualizador de Device Networks',
      description: 'Acesso de visualização aos Device Networks',
    },
    organizationTypeEditor: {
      label: 'Editor de Organization Types',
      description: 'Acesso de edição aos Organization Types',
    },
    organizationTypeViewer: {
      label: 'Visualizador de Organization Types',
      description: 'Acesso de visualização aos Organization Types',
    },
    categoryEditor: {
      label: 'Editor de Categories',
      description: 'Acesso de edição aos Categories',
    },
    categoryViewer: {
      label: 'Visualizador de Categories',
      description: 'Acesso de visualização aos Categories',
    },
    sectorEditor: {
      label: 'Editor de Sectors',
      description: 'Acesso de edição aos Sectors',
    },
    sectorViewer: {
      label: 'Visualizador de Sectors',
      description: 'Acesso de visualização aos Sectors',
    },
  },

  iam: {
    title: 'Gerenciamento de usuários e permissões',
    menu: 'IAM',
    disable: 'Desabilitar',
    disabled: 'Desabilitado',
    enabled: 'Habilitado',
    enable: 'Habilitar',
    doEnableSuccess: 'Usuário habilitado com sucesso',
    doDisableSuccess: 'Usuário desabilitado com sucesso',
    doDisableAllSuccess: 'Usuário(s) desabilitado(s) com sucesso',
    doEnableAllSuccess: 'Usuário(s) habilidatos com sucesso',
    doAddSuccess: 'Usuário(s) salvos com sucesso',
    doUpdateSuccess: 'Usuário salvo com sucesso',
    viewBy: 'Visualizar por',
    users: {
      name: 'users',
      label: 'Usuários',
      exporterFileName: 'usuarios_exportados',
      doRemoveAllSelectedSuccess: 'Permissões removidas com sucesso',
    },
    roles: {
      label: 'Perfis',
      doRemoveAllSelectedSuccess: 'Permissões removidas com sucesso',
    },
    edit: {
      title: 'Editar usuário',
    },
    new: {
      title: 'Novo(s) Usuário(s)',
      titleModal: 'Novo Usuário',
      emailsHint: 'Separe múltiplos endereços de e-mail usando a vírgula.',
    },
    view: {
      title: 'Visualizar Usuário',
      activity: 'Atividades',
    },
    importer: {
      title: 'Importar Usuários',
      fileName: 'usuarios_template_importacao',
      hint: 'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
    },
    errors: {
      userAlreadyExists: 'Usuário com este email já existe',
      userNotFound: 'Usuário não encontrado',
      disablingHimself: 'Você não pode desativar-se',
      revokingOwnPermission: 'Você não pode revogar sua própria permissão de proprietário',
    },
  },

  user: {
    fields: {
      id: 'Id',
      authenticationUid: 'Id de autenticação',
      avatars: 'Avatar',
      email: 'Email',
      emails: 'Email(s)',
      fullName: 'Nome',
      firstName: 'Nome',
      lastName: 'Sobrenome',
      status: 'Estado',
      disabled: 'Desativado',
      phoneNumber: 'Telefone',
      role: 'Perfil',
      createdAt: 'Criado em',
      updatedAt: 'Atualizado em',
      roleUser: 'Perfil/Usuário',
      roles: 'Perfis',
      createdAtRange: 'Criado em',
      password: 'Senha',
      rememberMe: 'Lembrar-me',
    },
    enabled: 'Habilitado',
    disabled: 'Desabilitado',
    validations: {
      // eslint-disable-next-line
      email: 'Email ${value} é inválido',
    },
  },

  auditLog: {
    menu: 'Registros de Auditoria',
    title: 'Registros de Auditoria',
    exporterFileName: 'registros_autoria_exportados',
    entityNamesHint: 'Separe múltiplas entidades por vírgula',
    fields: {
      id: 'Id',
      timestampRange: 'Período',
      entityName: 'Entidade',
      entityNames: 'Entidades',
      entityId: 'ID da Entidade',
      action: 'Ação',
      values: 'Valores',
      timestamp: 'Data',
      createdByEmail: 'Email do Usuário',
    },
  },
  settings: {
    title: 'Configurações',
    menu: 'Configurações',
    save: {
      success:
        'Configurações salvas com sucesso. A página irá recarregar em {0} para que as alterações tenham efeito.',
    },
    fields: {
      primary: 'Cor Primária',
      secondary: 'Cor Secundária',
      shade: 'Tom',
    },
  },
  home: {
    menu: 'Inicial',
    message:
      'Esta página usa dados falsos apenas para fins de demonstração. Você pode editá-la em frontend/view/home/HomePage.js.',
    charts: {
      day: 'Dia',
      red: 'Vermelho',
      green: 'Verde',
      yellow: 'Amarelho',
      grey: 'Cinza',
      blue: 'Azul',
      orange: 'Laranja',
      months: {
        1: 'Janeiro',
        2: 'Fevereiro',
        3: 'Março',
        4: 'Abril',
        5: 'Maio',
        6: 'Junho',
        7: 'Julho',
      },
      eating: 'Comendo',
      drinking: 'Bebendo',
      sleeping: 'Dormindo',
      designing: 'Projetando',
      coding: 'Codificando',
      cycling: 'Pedalando',
      running: 'Correndo',
      customer: 'Cliente',
    },
  },
  errors: {
    backToHome: 'Voltar a página inicial',
    403: 'Desculpe, você não tem acesso a esta página',
    404: 'Desculpe, a página que você visitou não existe',
    500: 'Desculpe, o servidor está relatando um erro',
    forbidden: {
      message: 'Acesso negado',
    },
    validation: {
      message: 'Ocorreu um erro',
    },
    defaultErrorMessage: 'Ops, ocorreu um erro',
  },
  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path} é inválido',
      required: '${path} é obrigatório',
      oneOf: '${path} deve ser um dos seguintes valores: ${values}',
      notOneOf: '${path} não deve ser um dos seguintes valores: ${values}',
      notType: ({ path, type, value, originalValue }) => {
        return `${path} deve ser um ${type}`;
      },
    },
    string: {
      length: '${path} deve possuir ${length} caracteres',
      min: '${path} deve possuir ao menos ${min} caracteres',
      max: '${path} deve possui no máximo ${max} caracteres',
      matches: '${path} deve respeitar o padrão: "${regex}"',
      email: '${path} deve ser um email válido',
      url: '${path} deve ser uma URL válida',
      trim: '${path} deve ser uma palavra sem espaços em branco',
      lowercase: '${path} deve ser minúsculo',
      uppercase: '${path} deve ser maiúsculo',
      selected: '${path} deve ser selecionado',
    },
    number: {
      min: '${path} deve ser maior ou igual a ${min}',
      max: '${path} deve ser menor ou igual a ${max}',
      lessThan: '${path} deve ser menor que ${less}',
      moreThan: '${path} deve ser maior que ${more}',
      notEqual: '${path} não deve ser igual a ${notEqual}',
      positive: '${path} deve ser um número positivo',
      negative: '${path} deve ser um número negativo',
      integer: '${path} deve ser um inteiro',
    },
    date: {
      min: '${path} deve ser posterior a ${min}',
      max: '${path} deve ser mais cedo do que ${max}',
    },
    boolean: {},
    object: {
      noUnknown: '${path} não pode ter atributos não especificados no formato do objeto',
    },
    array: {
      min: '${path} deve possuir ao menos ${min} itens',
      max: '${path} deve possuir no máximo ${max} itens',
    },
  },
  /* eslint-disable */
  fileUploader: {
    upload: 'Upload',
    image: 'Você deve fazer upload de uma imagem',
    size: 'O arquivo é muito grande. O tamanho máximo permitido é {0}',
    formats: `Formato inválido. Deve ser: '{0}'.`,
  },
  importer: {
    line: 'Linha',
    status: 'Estado',
    pending: 'Pendente',
    imported: 'Importado',
    error: 'Erro',
    total: `{0} importado, {1} pendente e {2} com erro`,
    importedMessage: `Processados {0} de {1}.`,
    noNavigateAwayMessage: 'Não saia desta página ou a importação será interrompida.',
    completed: {
      success: 'Importação concluída. Todas as linhas foram importadas com sucesso.',
      someErrors: 'O processamento foi concluído, mas algumas linhas não puderam ser importadas.',
      allErrors: 'Importação falhou. Não há linhas válidas.',
    },
    form: {
      downloadTemplate: 'Baixe o modelo',
      hint: 'Clique ou arraste o arquivo para esta área para continuar.',
    },
    list: {
      discardConfirm: 'Você tem certeza? Dados não importados serão perdidos.',
    },
    errors: {
      invalidFileEmpty: 'O arquivo está vazio',
      invalidFileExcel: 'Apenas arquivos Excel (.xlsx) são permitidos',
      invalidFileUpload:
        'Arquivo inválido. Verifique se você está usando a última versão do modelo.',
      importHashRequired: 'Hash de importação é necessário',
      importHashExistent: 'Dados já foram importados',
    },
  },

  autocomplete: {
    loading: 'Carregando...',
    noOptions: 'Não foram encontrados resultados',
  },

  imagesViewer: {
    noImage: 'Sem imagem',
  },

  table: {
    noData: 'Nenhum Registro Encontrado',
    loading: 'Carregando...',
  },

  pagination: {
    labelDisplayedRows: '{0}-{1} de {2}',
    labelRowsPerPage: 'Por página:',
  },
};

export default ptBR;
