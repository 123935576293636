import { Axios } from '../shared/axios/axios';
import config from 'config';
import {
  AgencyCampaign,
  Campaign,
  CampaignStatusMetadata,
  RequestForProposal,
  RFPResponse,
  RFPStats,
  RFPUpload,
} from '~/types/models/campaign';
import { format } from 'date-fns';
import authAxios from '../shared/axios/authAxios';
import { PreviewEmail } from '~/view/campaign/agency/wizard/steps/EmailPreview';
import { UploaderRecord } from '~/components/UploadFiles/Uploader';
import { RFPInventory, RFPInventoryModel } from '~/types/models/campaign/RFPInventory';
import { RFPInventoryPricing } from '~/types/models/campaign/RFPInventoryPricing';
import { AxiosResponse } from 'axios';
import { VendorContact, VendorContactModel } from '~/types/models/campaign/VendorContact';
import Qs from 'qs';
import { EditProps } from '~/view/campaign/agency/rfpPortal/EditInventory';
import { TrackingPixel, TrackingPixelModel } from '~/types/models/analytics/TrackingPixel';
import { PlanTrackingCreatePixelData } from '~/types/models/plan';
import {
  TrackingPixelDetail,
  TrackingPixelDetailModel,
} from '~/types/models/analytics/TrackingPixelDetail';
import { PortalRFPData, PortalRFPDataModel } from '~/types/models/campaign/PortalRFPData';
import { WithRowsAndCount } from '~/types/modules/common';

const formatDateForBackend = (dateValue: Date): string => {
  return format(dateValue, 'MM-dd-yyyy');
};

export class CampaignService {
  static async find(id): Promise<Campaign> {
    const res = await authAxios.get<Campaign>(`/campaigns/${id}`);
    return res.data;
  }

  static async list(): Promise<Campaign[]> {
    const res = await authAxios.get<Campaign[]>(`/campaigns`);
    return res.data;
  }

  static async create(data: Campaign): Promise<Campaign> {
    const res = await authAxios.post<Campaign>(`/campaigns`, data);
    return res.data;
  }

  // we need to add conflicts in for this specific usage
  static async update(
    id: string,
    data: AgencyCampaign & { conflicts: boolean[] },
  ): Promise<Campaign> {
    const dataClean = structuredClone(data);
    delete dataClean.conflicts;
    const res = await authAxios.patch<Campaign>(`/campaigns/${id}`, dataClean);
    return res.data;
  }

  static async delete(id: string): Promise<Campaign> {
    const res = await authAxios.delete<Campaign>(`/campaigns/${id}`);
    return res.data;
  }

  static async getStatusMetadata(): Promise<CampaignStatusMetadata> {
    const res = await authAxios.get<CampaignStatusMetadata>(`/campaigns/statuses`);
    return res.data;
  }

  static async print(id: string, campaignName: string): Promise<void> {
    window.open(`${config.backendUrl}/campaigns/${id}/print/${campaignName}.pdf`);
  }
}

export interface CampaignTrackingPixelCountResponse {
  date: string;
  count: number;
}

export class CampaignTrackingService {
  static async getPixel(pixelId: string, planDocumentId: string): Promise<TrackingPixelDetail> {
    const res = await authAxios.get<TrackingPixelDetailModel>(
      `/campaign-tracking/pixel/${pixelId}/${planDocumentId}`,
    );
    return TrackingPixelDetail.create(res.data);
  }

  static async getPixels(): Promise<TrackingPixel[]> {
    const res = await authAxios.get<TrackingPixelModel[]>(`/campaign-tracking/pixels`);
    return res.data.map((model: TrackingPixelModel) => TrackingPixel.create(model));
  }

  static async getPixelCount(
    pixelId: string,
    planDocumentId: string,
  ): Promise<CampaignTrackingPixelCountResponse[]> {
    const res = await authAxios.get<CampaignTrackingPixelCountResponse[]>(
      `/campaign-tracking/pixel/${pixelId}/count/${planDocumentId}`,
    );
    return res.data;
  }

  static async linkPlansToPixel(pixelId: string, planIds: string[]): Promise<void> {
    await authAxios.post(`/campaign-tracking/pixel/${pixelId}/link-plans`, planIds);
  }

  static async createPixel(data: PlanTrackingCreatePixelData): Promise<TrackingPixel> {
    const res = await authAxios.post<TrackingPixelModel>(`/campaign-tracking/pixel`, data);
    return TrackingPixel.create(res.data);
  }

  static async exportPixelToBigQuery(
    pixelId: string,
    planDocumentId: string,
  ): Promise<TrackingPixelDetail> {
    const response = await authAxios.post(
      `/campaign-tracking/pixel/${pixelId}/export-to-bigquery/${planDocumentId}`,
    );
    return TrackingPixelDetail.create(response.data);
  }
}

export class RFPManagementService {
  static async getRFPRequests(agencyCampaignId: string): Promise<RequestForProposal[]> {
    const res = await authAxios.get<RequestForProposal[]>(
      `/agency-campaign/${agencyCampaignId}/request-for-proposal`,
    );
    return res.data;
  }

  static async getRFPResponseAggregateInventory(
    agencyCampaignId: string,
    RFPIds: string[],
  ): Promise<RFPResponse[]> {
    const results = await Promise.all(
      RFPIds.map((RFPId) =>
        authAxios.get<RFPResponse[]>(
          `/agency-campaign/${agencyCampaignId}/request-for-proposal/${RFPId}/responses`,
        ),
      ),
    );

    return results.map((item) => item.data).flat();
  }

  static async sendRFPResponseHolds(
    agencyCampaignId: string,
    id: string,
    RFPIds: string[],
  ): Promise<void> {
    await authAxios
      .post<void>(
        `/agency-campaign/${agencyCampaignId}/request-for-proposal/${id}/responses/hold`,
        { requestForProposalResponseIds: RFPIds },
      )
      .then(() => Promise.resolve())
      .catch((error) => Promise.reject(error));

    // There is no need for a response for the client logic.
    // If an error occurs the response will be caught by the caller.
  }
}

export class AgencyCampaignService {
  static async find(id): Promise<AgencyCampaign> {
    const res = await authAxios.get<AgencyCampaign>(`/agency-campaign/${id}`);
    return res.data;
  }

  static async get(
    limit?: number,
    offset?: number,
    search?: string,
  ): Promise<WithRowsAndCount<AgencyCampaign>> {
    const res = await authAxios.get<WithRowsAndCount<AgencyCampaign>>(`/agency-campaign`, {
      paramsSerializer: (params) => Qs.stringify({ ...params }, { arrayFormat: 'comma' }),
      params: {
        limit,
        offset,
        search,
      },
    });
    return res.data;
  }

  static async getCompanies(searchValue: string, limit: number): Promise<AgencyCampaign[]> {
    const res = await authAxios.get<AgencyCampaign[]>(
      `/agency-campaign/autocomplete?searchValue=${searchValue}&limit=${limit}`,
    );
    return res.data;
  }

  static async getStats(agencyCampaignIds: string[]): Promise<RFPStats[]> {
    const res = await authAxios.post<RFPStats[]>(`/agency-campaign/request-for-proposal/stats`, {
      agencyCampaignIds,
    });
    return res.data;
  }

  static async create(data: AgencyCampaign): Promise<AgencyCampaign> {
    const res = await authAxios.post<AgencyCampaign>(`/agency-campaign`, data);
    return res.data;
  }

  static async update(id: string, data: unknown): Promise<AgencyCampaign> {
    const res = await authAxios.patch<AgencyCampaign>(`/agency-campaign/${id}`, data);
    return res.data;
  }

  static async getEmailPreview(agencyCampaignId: string): Promise<PreviewEmail> {
    const res = await authAxios.get<PreviewEmail>(
      `/agency-campaign/${agencyCampaignId}/request-for-proposal/preview-email`,
    );
    return res.data;
  }

  static async sendEmail(agencyCampaignId: string): Promise<void> {
    await authAxios.post(`/agency-campaign/${agencyCampaignId}/request-for-proposal/send-email`);
  }

  static async getRequestForProposals(agencyCampaignId: string): Promise<RequestForProposal[]> {
    const res = await authAxios.get<RequestForProposal[]>(
      `/agency-campaign/${agencyCampaignId}/request-for-proposal`,
    );
    return res.data;
  }

  static async getDMAs(agencyCampaignId: string): Promise<string[]> {
    const res = await authAxios.get<string[]>(`/agency-campaign/${agencyCampaignId}/dmas`);
    return res.data;
  }

  static async getInventoryTypes(agencyCampaignId: string): Promise<string[]> {
    const res = await authAxios.get<string[]>(
      `/agency-campaign/${agencyCampaignId}/inventory-types`,
    );
    return res.data;
  }

  static async replaceRequestForProposals(
    agencyCampaignId: string,
    data: RequestForProposal[],
    isSendAdditionalRFPsMode: boolean,
  ): Promise<RequestForProposal> {
    const res = await authAxios[isSendAdditionalRFPsMode ? 'put' : 'post']<RequestForProposal>(
      `/agency-campaign/${agencyCampaignId}/request-for-proposal/bulk`,
      data,
    );
    return res.data;
  }

  static async replaceDMAs(agencyCampaignId: string, data: string[]): Promise<string[]> {
    const res = await authAxios.put<string[]>(`/agency-campaign/${agencyCampaignId}/dmas`, data);
    return res.data;
  }

  static async replaceInventoryTypes(agencyCampaignId: string, data: string[]): Promise<string[]> {
    const res = await authAxios.put<string[]>(
      `/agency-campaign/${agencyCampaignId}/inventory-types`,
      data,
    );
    return res.data;
  }

  static async createPlanBuilder(agencyCampaignId: string) {
    return await authAxios.post<{ documentId: string }>(
      `/agency-campaign/${agencyCampaignId}/plan/create`,
      {
        agencyCampaignId,
      },
    );
  }

  static async getPlanBuilder(agencyCampaignId: string) {
    const res = await authAxios.get(`plan?agencyCampaignId=${agencyCampaignId}`);
    return res;
  }
}

export class RFPService {
  static async doFetch(id: string): Promise<PortalRFPData> {
    const res = await Axios.get<PortalRFPDataModel>(`/portal/rfp/${id}`);
    return PortalRFPData.create(res.data);
  }

  static async generateMediaFilesZipLink(identifier: string): Promise<string> {
    const res = await Axios.get<string>(`/portal/rfp/${identifier}/mediafilesDownload`);
    return res.data;
  }

  static async sendRFPUpload(id: string, data: UploaderRecord): Promise<T> {
    const res = await authAxios.post<RFPUpload>(`/portal/rfp/${id}/response`, { file: data });
    return res.data;
  }

  static async getRFPResponses(id: string, statusIds = [1, 2, 3, 4]): Promise<RFPResponse[]> {
    const res = await Axios.get<RFPResponse[]>(`/portal/rfp/${id}/responses`, {
      params: { statusIds: statusIds.join(',') },
    });
    return res.data;
  }

  static async sendRFPApprovedResponses(
    id: string,
    requestForProposalResponseIds: string[],
    date: Date,
  ): Promise<RFPResponse> {
    const res = await Axios.post<RFPResponse>(`/portal/rfp/${id}/response/approve`, {
      requestForProposalResponseIds,
      holdExpirationDate: formatDateForBackend(date),
    });
    return res.data;
  }

  static async sendRFPRejectedResponses(
    id: string,
    requestForProposalResponseIds: string[],
  ): Promise<RFPResponse> {
    const res = await Axios.post<RFPResponse>(`/portal/rfp/${id}/response/reject`, {
      requestForProposalResponseIds,
    });
    return res.data;
  }

  static async sendUpdatedDateResponse(
    id: string,
    requestForProposalResponseIds: string,
    date: Date,
  ): Promise<RFPResponse> {
    const res = await Axios.put<RFPResponse>(
      `/portal/rfp/${id}/response/${requestForProposalResponseIds}/hold-expiration-date`,
      {
        requestForProposalResponseIds,
        holdExpirationDate: formatDateForBackend(date),
      },
    );
    return res.data;
  }

  static async sendRFPCommentResponses(
    id: string,
    requestForProposalResponseIds: string,
    comment: string,
  ): Promise<RFPResponse> {
    const res = await Axios.post<RFPResponse>(
      `/portal/rfp/${id}/response/${requestForProposalResponseIds}/comment`,
      {
        comment,
      },
    );
    return res.data;
  }

  static async deleteRFPCommentResponses(
    id: string,
    requestForProposalResponseIds: string,
    commentId: string,
  ): Promise<RFPResponse> {
    const res = await Axios.delete<RFPResponse>(
      `/portal/rfp/${id}/response/${requestForProposalResponseIds}/comment/${commentId}`,
    );
    return res.data;
  }

  static async getRFPInventories(rfpId: string): Promise<RFPInventory[]> {
    const res = await Axios.get<RFPInventoryModel[]>(`/portal/rfp/${rfpId}/inventories`);
    return res.data.map((element: RFPInventoryModel) => {
      return RFPInventory.create(element);
    });
  }

  static async setRFPInventoriesPricingAndAvailability(
    rfpId: string,
    inventories: RFPInventoryPricing[],
  ): Promise<RFPInventory[]> {
    const res = await Axios.post<RFPInventoryModel[]>(
      `/portal/rfp/${rfpId}/inventories/pricing-and-availability`,
      inventories.map((inventory) => inventory.serialize()),
    );
    return res.data?.map((inventoryModel) => RFPInventory.create(inventoryModel));
  }

  static async setRFPInventoriesUnavailable(
    id: string,
    rfpInventoryIds: string[],
  ): Promise<RFPInventory[]> {
    const res = await Axios.post<RFPInventoryModel[]>(
      `/portal/rfp/${id}/inventories/mark-unavailable`,
      { rfpInventoryIds },
    );
    return res.data?.map((inventoryModel) => RFPInventory.create(inventoryModel));
  }

  static async getInventoryTypes() {
    const res = await authAxios.get(`inventoryType/autocomplete?query=&limit=500`);
    return res.data;
  }

  static async postEditInventory(identifier: string, rfpInventoryId: string, data: EditProps) {
    const res = await authAxios.post(
      `/portal/rfp/${identifier}/inventories/${rfpInventoryId}/edit`,
      data,
    );
    return res.data;
  }
}

export class ConfirmSelectionsService {
  static async postInventoryList(agencyCampaignId: string, data: string[]) {
    const res = await authAxios.post(
      `/agency-campaign/${agencyCampaignId}/request-for-proposal/inventories`,
      { inventoryIds: data },
    );
    return res.data;
  }

  static async getInventoryList(agencyCampaignId: string): Promise<RFPInventory[]> {
    const res: AxiosResponse<RFPInventoryModel[]> = await authAxios.get(
      `/agency-campaign/${agencyCampaignId}/request-for-proposal/inventories`,
    );
    return res.data.map((inventoryModel) => RFPInventory.create(inventoryModel));
  }

  static async postVendorList(data: string[]): Promise<VendorContact[]> {
    const res: AxiosResponse<VendorContactModel[]> = await authAxios.post(`/vendor/contacts`, {
      vendorIds: data,
    });
    return res.data.map((vendorContactModel) => VendorContact.create(vendorContactModel));
  }
}

export class AddInventoryToCampaignService {
  static async addInventories(agencyCampaignId: string, data: string[]) {
    const res = await authAxios.post(
      `/agency-campaign/${agencyCampaignId}/request-for-proposal/add-inventory-to-campaign`,
      { inventoryIds: data },
    );
    return res.data;
  }
}
