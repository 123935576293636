const noop = () => {
  // no-ip
};

export const useAnalytics = () => {
  return {
    track: noop,
    identify: noop,
    page: noop,
  };
};
