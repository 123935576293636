import React, { useEffect, useState } from 'react';

import { Box, DialogTitle, generateUtilityClasses, Popover, SxProps, Theme } from '@mui/material';
import { OSAIButton } from '../osaiButton';
import clsx from 'clsx';

const classes: Record<string, string> = {
  ...generateUtilityClasses('OSAIPopover', [
    'root',
    'backdropVisible',
    'buttonsContainer',
    'cancelButton',
    'closeButton',
    'container',
    'paper',
    'titleContainer',
    'headerContainer',
    'footerContainer',
  ]),
};

const styles: SxProps<Theme> = {
  [`& .${classes.paper}.${classes.backdropVisible}`]: {
    left: '50%!important',
    transform: 'translateX(-50%)!important',
  },
  [`& .${classes.container}`]: {
    margin: '0 1em',
  },
  [`& .${classes.headerContainer}, & .${classes.footerContainer}`]: {
    padding: 0,
    margin: '0 0.67em',
  },
  [`& .${classes.titleContainer}`]: {
    padding: '24px',
    fontWeight: '700',
    fontSize: '24px',
    paddingLeft: 0,
    marginLeft: '0.67em',
  },
  [`& .${classes.buttonsContainer}`]: {
    display: 'flex',
    flexDirection: 'horizontal',
    margin: '1em',
  },
  [`& .${classes.closeButton}`]: {
    marginRight: '1.5em',
  },
  ['& hr']: {
    backgroundColor: (theme) => theme.palette.other.divider,
    border: 'none',
    height: '1px',
  },
} as SxProps<Theme>;

export enum AnchorVerticalAlignment {
  top = 0,
  center,
  bottom,
}

export enum AnchorHorizontalAlignment {
  left = 0,
  center,
  right,
}

export enum OnCloseTarget {
  Close = 0,
  Cancel,
}

interface AnchorAlignment {
  horizontal: AnchorHorizontalAlignment;
  vertical: AnchorVerticalAlignment;
}

type horizontalAlignment = 'left' | 'center' | 'right';
type verticalAlignment = 'top' | 'center' | 'bottom';

export interface OSAIPopoverProps {
  sx?: SxProps<Theme>;
  className?: string;
  anchorEl?: Element;
  anchorAlignment?: AnchorAlignment;
  disableCloseButton?: boolean;
  hideBackdrop?: boolean;
  open: boolean;
  title?: string;
  closeButtonText?: string;
  cancelButtonText?: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  onClose?: (target: OnCloseTarget | undefined) => void;
}

export const OSAIPopover: React.FC<OSAIPopoverProps> = ({
  className,
  sx,
  children,
  anchorEl,
  anchorAlignment,
  disableCloseButton = false,
  hideBackdrop = true,
  open,
  closeButtonText,
  cancelButtonText,
  title,
  header,
  footer,
  onClose,
}) => {
  const [closeState, setCloseState] = useState<OnCloseTarget>(undefined);

  const handleSetCloseState = (target) => {
    setCloseState(target);
  };

  useEffect(() => {
    if (closeState !== undefined) {
      onClose?.(closeState);
      setCloseState(undefined);
    }
  }, [closeState]);

  const invokeOnClose = (target: OnCloseTarget | undefined) => () => {
    handleSetCloseState(target);
  };

  const popupButtons = React.useMemo(() => {
    const areButtonsEnabled = !footer && (closeButtonText || cancelButtonText);
    return areButtonsEnabled ? (
      <Box className={clsx(classes.container, classes.buttonsContainer)}>
        {closeButtonText ? (
          <OSAIButton
            className={classes.closeButton}
            active={true}
            disabled={disableCloseButton}
            onClick={invokeOnClose(OnCloseTarget.Close)}
          >
            {closeButtonText}
          </OSAIButton>
        ) : null}
        {cancelButtonText ? (
          <OSAIButton
            className={classes.cancelButton}
            active={false}
            onClick={invokeOnClose(OnCloseTarget.Cancel)}
          >
            {cancelButtonText}
          </OSAIButton>
        ) : null}
      </Box>
    ) : null;
  }, [closeButtonText, cancelButtonText, footer, disableCloseButton]);

  if (!open) {
    return null;
  }

  return (
    <Popover
      className={className}
      classes={{
        root: classes.root,
        paper: clsx({
          [classes.paper]: true,
          [classes.backdropVisible]: !hideBackdrop,
        }),
      }}
      sx={(Array.isArray(sx) ? sx.concat([styles]) : { ...styles, ...sx }) as SxProps<Theme>}
      open={true}
      anchorEl={anchorEl}
      onClose={invokeOnClose(OnCloseTarget.Close)}
      hideBackdrop={hideBackdrop}
      BackdropProps={hideBackdrop ? undefined : { hidden: false }}
      anchorOrigin={
        anchorAlignment
          ? {
              vertical: AnchorVerticalAlignment[anchorAlignment.vertical] as verticalAlignment, //bottom
              horizontal: AnchorHorizontalAlignment[
                anchorAlignment.horizontal
              ] as horizontalAlignment, //left
            }
          : undefined
      }
    >
      {header ? (
        <>
          <Box className={classes.headerContainer}>{header}</Box>
          {children || footer || closeButtonText || cancelButtonText ? <hr /> : null}
        </>
      ) : title ? (
        <>
          <DialogTitle className={classes.titleContainer}>{title}</DialogTitle>
          {children || footer || closeButtonText || cancelButtonText ? <hr /> : null}
        </>
      ) : null}
      <Box className={classes.container}>{children}</Box>
      {children ? <hr /> : null}
      {footer ? (
        <>
          <Box className={clsx(classes.container, classes.footerContainer)}>{footer}</Box>
        </>
      ) : (
        popupButtons
      )}
    </Popover>
  );
};

export { classes as OSAIPopoverClasses };
