import Roles from '../security/roles';

const roles = Roles.values;

class Permissions {
  static get values() {
    return {
      iamEdit: {
        id: 'iamEdit',
        allowedRoles: [roles.owner, roles.iamSecurityReviewer, roles.editor],
        allowedStorageFolders: ['user'],
      },
      iamCreate: {
        id: 'iamCreate',
        allowedRoles: [roles.owner, roles.iamSecurityReviewer, roles.editor],
      },
      iamImport: {
        id: 'iamImport',
        allowedRoles: [roles.owner, roles.iamSecurityReviewer, roles.editor],
      },
      iamRead: {
        id: 'iamRead',
        allowedRoles: [roles.owner, roles.iamSecurityReviewer, roles.editor, roles.viewer],
      },
      iamUserAutocomplete: {
        id: 'iamUserAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,

          roles.storefrontEditor,
          roles.storefrontViewer,
          roles.organizationEditor,
          roles.organizationViewer,
        ],
      },
      auditLogRead: {
        id: 'auditLogRead',
        allowedRoles: [roles.owner, roles.auditLogViewer, roles.viewer],
      },
      settingsEdit: {
        id: 'settingsEdit',
        allowedRoles: [roles.owner],
      },
      contentImport: {
        id: 'contentImport',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.contentEditor],
      },
      contentCreate: {
        id: 'contentCreate',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.contentEditor],
        allowedStorageFolders: ['content'],
      },
      contentEdit: {
        id: 'contentEdit',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.contentEditor],
        allowedStorageFolders: ['content'],
      },
      contentDestroy: {
        id: 'contentDestroy',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.contentEditor],
        allowedStorageFolders: ['content'],
      },
      contentRead: {
        id: 'contentRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.contentEditor,
          roles.contentViewer,
        ],
      },
      contentAutocomplete: {
        id: 'contentAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.contentEditor,
          roles.contentViewer,
          roles.storefrontEditor,
          roles.storefrontViewer,
          roles.organizationEditor,
          roles.organizationViewer,
          roles.analyticsEventEditor,
          roles.analyticsEventViewer,
          roles.transactionEditor,
          roles.transactionViewer,
          roles.marketEditor,
          roles.marketViewer,
          roles.channelEditor,
          roles.channelViewer,
        ],
      },

      storefrontImport: {
        id: 'storefrontImport',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.storefrontEditor],
      },
      storefrontCreate: {
        id: 'storefrontCreate',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.storefrontEditor],
        allowedStorageFolders: ['storefront'],
      },
      storefrontEdit: {
        id: 'storefrontEdit',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.storefrontEditor],
        allowedStorageFolders: ['storefront'],
      },
      storefrontDestroy: {
        id: 'storefrontDestroy',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.storefrontEditor],
        allowedStorageFolders: ['storefront'],
      },
      storefrontRead: {
        id: 'storefrontRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.storefrontEditor,
          roles.storefrontViewer,
          roles.inventoryDirectoryV2,
          roles.inventoryDirectoryV3,
        ],
      },
      storefrontAutocomplete: {
        id: 'storefrontAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.storefrontEditor,
          roles.storefrontViewer,
          roles.analyticsEventEditor,
          roles.analyticsEventViewer,
          roles.transactionEditor,
          roles.transactionViewer,
        ],
      },

      organizationImport: {
        id: 'organizationImport',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.organizationEditor],
      },
      organizationCreate: {
        id: 'organizationCreate',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.organizationEditor],
        allowedStorageFolders: ['organization'],
      },
      organizationEdit: {
        id: 'organizationEdit',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.organizationEditor],
        allowedStorageFolders: ['organization'],
      },
      organizationDestroy: {
        id: 'organizationDestroy',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.organizationEditor],
        allowedStorageFolders: ['organization'],
      },
      organizationRead: {
        id: 'organizationRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.organizationEditor,
          roles.organizationViewer,
        ],
      },
      organizationAutocomplete: {
        id: 'organizationAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.organizationEditor,
          roles.organizationViewer,
          roles.contentEditor,
          roles.contentViewer,
          roles.analyticsEventEditor,
          roles.analyticsEventViewer,
          roles.transactionEditor,
          roles.transactionViewer,
          roles.marketEditor,
          roles.marketViewer,
        ],
      },
      crmCompanyList: {
        id: 'crmCompanyList',
        allowedRoles: [], // todo
      },
      crmCompanyAdd: {
        id: 'crmCompanyAdd',
        allowedRoles: [], // todo
      },
      crmCompanyEdit: {
        id: 'crmCompanyEdit',
        allowedRoles: [], // todo
      },
      admin: {
        id: 'admin',
        allowedRoles: [roles.owner, roles.admin],
      },
      inventoryDirectoryV2: {
        id: 'inventoryDirectoryV2',
        allowedRoles: [roles.owner, roles.inventoryDirectoryV2],
      },
      inventoryDirectoryV3: {
        id: 'inventoryDirectoryV3',
        allowedRoles: [roles.owner, roles.inventoryDirectoryV3],
      },
      campaignList: {
        id: 'campaignList',
        allowedRoles: [roles.owner, roles.campaignViewer],
      },
      campaignEdit: {
        id: 'campaignEdit',
        allowedRoles: [roles.owner, roles.campaignEditor],
      },
      campaignDemand: {
        id: 'campaignDemand',
        allowedRoles: [roles.owner, roles.agencyCampaignViewer],
      },
      customerProfileImport: {
        id: 'customerProfileImport',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.customerProfileEditor],
      },
      customerProfileCreate: {
        id: 'customerProfileCreate',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.customerProfileEditor],
        allowedStorageFolders: ['customerProfile'],
      },
      customerProfileEdit: {
        id: 'customerProfileEdit',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.customerProfileEditor],
        allowedStorageFolders: ['customerProfile'],
      },
      customerProfileDestroy: {
        id: 'customerProfileDestroy',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.customerProfileEditor],
        allowedStorageFolders: ['customerProfile'],
      },
      customerProfileRead: {
        id: 'customerProfileRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.customerProfileEditor,
          roles.customerProfileViewer,
        ],
      },
      customerProfileAutocomplete: {
        id: 'customerProfileAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.customerProfileEditor,
          roles.customerProfileViewer,
          roles.storefrontEditor,
          roles.storefrontViewer,
        ],
      },

      customerAudienceImport: {
        id: 'customerAudienceImport',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.customerAudienceEditor],
      },
      customerAudienceCreate: {
        id: 'customerAudienceCreate',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.customerAudienceEditor],
        allowedStorageFolders: ['customerAudience'],
      },
      customerAudienceEdit: {
        id: 'customerAudienceEdit',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.customerAudienceEditor],
        allowedStorageFolders: ['customerAudience'],
      },
      customerAudienceDestroy: {
        id: 'customerAudienceDestroy',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.customerAudienceEditor],
        allowedStorageFolders: ['customerAudience'],
      },
      customerAudienceRead: {
        id: 'customerAudienceRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.customerAudienceEditor,
          roles.customerAudienceViewer,
        ],
      },
      customerAudienceAutocomplete: {
        id: 'customerAudienceAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.customerAudienceEditor,
          roles.customerAudienceViewer,
          roles.customerProfileEditor,
          roles.customerProfileViewer,
        ],
      },

      callToActionImport: {
        id: 'callToActionImport',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.callToActionEditor],
      },
      callToActionCreate: {
        id: 'callToActionCreate',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.callToActionEditor],
        allowedStorageFolders: ['callToAction'],
      },
      callToActionEdit: {
        id: 'callToActionEdit',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.callToActionEditor],
        allowedStorageFolders: ['callToAction'],
      },
      callToActionDestroy: {
        id: 'callToActionDestroy',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.callToActionEditor],
        allowedStorageFolders: ['callToAction'],
      },
      callToActionRead: {
        id: 'callToActionRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.callToActionEditor,
          roles.callToActionViewer,
        ],
      },
      callToActionAutocomplete: {
        id: 'callToActionAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.callToActionEditor,
          roles.callToActionViewer,
          roles.contentEditor,
          roles.contentViewer,
        ],
      },

      analyticsEventImport: {
        id: 'analyticsEventImport',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.analyticsEventEditor],
      },
      analyticsEventCreate: {
        id: 'analyticsEventCreate',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.analyticsEventEditor],
        allowedStorageFolders: ['analyticsEvent'],
      },
      analyticsEventEdit: {
        id: 'analyticsEventEdit',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.analyticsEventEditor],
        allowedStorageFolders: ['analyticsEvent'],
      },
      analyticsEventDestroy: {
        id: 'analyticsEventDestroy',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.analyticsEventEditor],
        allowedStorageFolders: ['analyticsEvent'],
      },
      analyticsEventRead: {
        id: 'analyticsEventRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.analyticsEventEditor,
          roles.analyticsEventViewer,
        ],
      },
      analyticsEventAutocomplete: {
        id: 'analyticsEventAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.analyticsEventEditor,
          roles.analyticsEventViewer,
          roles.contentEditor,
          roles.contentViewer,
          roles.storefrontEditor,
          roles.storefrontViewer,
          roles.organizationEditor,
          roles.organizationViewer,
          roles.customerProfileEditor,
          roles.customerProfileViewer,
          roles.callToActionEditor,
          roles.callToActionViewer,
          roles.transactionEditor,
          roles.transactionViewer,
          roles.marketEditor,
          roles.marketViewer,
          roles.channelEditor,
          roles.channelViewer,
          roles.tagEditor,
          roles.tagViewer,
        ],
      },

      transactionImport: {
        id: 'transactionImport',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.transactionEditor],
      },
      transactionCreate: {
        id: 'transactionCreate',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.transactionEditor],
        allowedStorageFolders: ['transaction'],
      },
      transactionEdit: {
        id: 'transactionEdit',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.transactionEditor],
        allowedStorageFolders: ['transaction'],
      },
      transactionDestroy: {
        id: 'transactionDestroy',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.transactionEditor],
        allowedStorageFolders: ['transaction'],
      },
      transactionRead: {
        id: 'transactionRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.transactionEditor,
          roles.transactionViewer,
        ],
      },
      transactionAutocomplete: {
        id: 'transactionAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.transactionEditor,
          roles.transactionViewer,
          roles.contentEditor,
          roles.contentViewer,
          roles.storefrontEditor,
          roles.storefrontViewer,
          roles.organizationEditor,
          roles.organizationViewer,
          roles.marketEditor,
          roles.marketViewer,
        ],
      },

      marketImport: {
        id: 'marketImport',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.marketEditor],
      },
      marketCreate: {
        id: 'marketCreate',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.marketEditor],
        allowedStorageFolders: ['market'],
      },
      marketEdit: {
        id: 'marketEdit',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.marketEditor],
        allowedStorageFolders: ['market'],
      },
      marketDestroy: {
        id: 'marketDestroy',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.marketEditor],
        allowedStorageFolders: ['market'],
      },
      marketRead: {
        id: 'marketRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.marketEditor,
          roles.marketViewer,
        ],
      },
      marketAutocomplete: {
        id: 'marketAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.marketEditor,
          roles.marketViewer,
          roles.channelEditor,
          roles.channelViewer,
        ],
      },

      channelImport: {
        id: 'channelImport',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.channelEditor],
      },
      channelCreate: {
        id: 'channelCreate',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.channelEditor],
        allowedStorageFolders: ['channel'],
      },
      channelEdit: {
        id: 'channelEdit',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.channelEditor],
        allowedStorageFolders: ['channel'],
      },
      channelDestroy: {
        id: 'channelDestroy',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.channelEditor],
        allowedStorageFolders: ['channel'],
      },
      channelRead: {
        id: 'channelRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.channelEditor,
          roles.channelViewer,
        ],
      },
      channelAutocomplete: {
        id: 'channelAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.channelEditor,
          roles.channelViewer,
          roles.analyticsEventEditor,
          roles.analyticsEventViewer,
          roles.deviceEditor,
          roles.deviceViewer,
        ],
      },
      tagEditor: {
        id: 'tagEditor',
        allowedRoles: [roles.owner, roles.tagEditor],
      },
      tagImport: {
        id: 'tagImport',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.tagEditor],
      },
      tagCreate: {
        id: 'tagCreate',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.tagCreate],
        allowedStorageFolders: ['tag'],
      },
      tagEdit: {
        id: 'tagEdit',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.tagEditor],
        allowedStorageFolders: ['tag'],
      },
      tagDestroy: {
        id: 'tagDestroy',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.tagEditor],
        allowedStorageFolders: ['tag'],
      },
      tagRead: {
        id: 'tagRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.tagEditor,
          roles.tagViewer,
        ],
      },
      tagAutocomplete: {
        id: 'tagAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.tagEditor,
          roles.tagViewer,
          roles.contentEditor,
          roles.contentViewer,
          roles.storefrontEditor,
          roles.storefrontViewer,
          roles.customerProfileEditor,
          roles.customerProfileViewer,
          roles.customerAudienceEditor,
          roles.customerAudienceViewer,
          roles.callToActionEditor,
          roles.callToActionViewer,
          roles.channelEditor,
          roles.channelViewer,
          roles.categoryEditor,
          roles.categoryViewer,
        ],
      },

      statusImport: {
        id: 'statusImport',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.statusEditor],
      },
      statusCreate: {
        id: 'statusCreate',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.statusEditor],
        allowedStorageFolders: ['status'],
      },
      statusEdit: {
        id: 'statusEdit',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.statusEditor],
        allowedStorageFolders: ['status'],
      },
      statusDestroy: {
        id: 'statusDestroy',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.statusEditor],
        allowedStorageFolders: ['status'],
      },
      statusRead: {
        id: 'statusRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.statusEditor,
          roles.statusViewer,
        ],
      },
      statusAutocomplete: {
        id: 'statusAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.statusEditor,
          roles.statusViewer,
          roles.contentEditor,
          roles.contentViewer,
          roles.storefrontEditor,
          roles.storefrontViewer,
          roles.organizationEditor,
          roles.organizationViewer,
          roles.deviceEditor,
          roles.deviceViewer,
          roles.deviceProfileEditor,
          roles.deviceProfileViewer,
          roles.deviceNetworkEditor,
          roles.deviceNetworkViewer,
          roles.categoryEditor,
          roles.categoryViewer,
        ],
      },

      deviceImport: {
        id: 'deviceImport',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.deviceEditor],
      },
      deviceCreate: {
        id: 'deviceCreate',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.deviceEditor],
        allowedStorageFolders: ['device'],
      },
      deviceEdit: {
        id: 'deviceEdit',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.deviceEditor],
        allowedStorageFolders: ['device'],
      },
      deviceDestroy: {
        id: 'deviceDestroy',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.deviceEditor],
        allowedStorageFolders: ['device'],
      },
      deviceRead: {
        id: 'deviceRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.deviceEditor,
          roles.deviceViewer,
        ],
      },
      deviceAutocomplete: {
        id: 'deviceAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.deviceEditor,
          roles.deviceViewer,
          roles.storefrontEditor,
          roles.storefrontViewer,
          roles.analyticsEventEditor,
          roles.analyticsEventViewer,
          roles.transactionEditor,
          roles.transactionViewer,
          roles.channelEditor,
          roles.channelViewer,
        ],
      },

      deviceProfileImport: {
        id: 'deviceProfileImport',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.deviceProfileEditor],
      },
      deviceProfileCreate: {
        id: 'deviceProfileCreate',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.deviceProfileEditor],
        allowedStorageFolders: ['deviceProfile'],
      },
      deviceProfileEdit: {
        id: 'deviceProfileEdit',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.deviceProfileEditor],
        allowedStorageFolders: ['deviceProfile'],
      },
      deviceProfileDestroy: {
        id: 'deviceProfileDestroy',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.deviceProfileEditor],
        allowedStorageFolders: ['deviceProfile'],
      },
      deviceProfileRead: {
        id: 'deviceProfileRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.deviceProfileEditor,
          roles.deviceProfileViewer,
        ],
      },
      deviceProfileAutocomplete: {
        id: 'deviceProfileAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.deviceProfileEditor,
          roles.deviceProfileViewer,
          roles.deviceEditor,
          roles.deviceViewer,
        ],
      },

      deviceCapabilityImport: {
        id: 'deviceCapabilityImport',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.deviceCapabilityEditor],
      },
      deviceCapabilityCreate: {
        id: 'deviceCapabilityCreate',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.deviceCapabilityEditor],
        allowedStorageFolders: ['deviceCapability'],
      },
      deviceCapabilityEdit: {
        id: 'deviceCapabilityEdit',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.deviceCapabilityEditor],
        allowedStorageFolders: ['deviceCapability'],
      },
      deviceCapabilityDestroy: {
        id: 'deviceCapabilityDestroy',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.deviceCapabilityEditor],
        allowedStorageFolders: ['deviceCapability'],
      },
      deviceCapabilityRead: {
        id: 'deviceCapabilityRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.deviceCapabilityEditor,
          roles.deviceCapabilityViewer,
        ],
      },
      deviceCapabilityAutocomplete: {
        id: 'deviceCapabilityAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.deviceCapabilityEditor,
          roles.deviceCapabilityViewer,
          roles.deviceProfileEditor,
          roles.deviceProfileViewer,
        ],
      },

      deviceNetworkImport: {
        id: 'deviceNetworkImport',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.deviceNetworkEditor],
      },
      deviceNetworkCreate: {
        id: 'deviceNetworkCreate',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.deviceNetworkEditor],
        allowedStorageFolders: ['deviceNetwork'],
      },
      deviceNetworkEdit: {
        id: 'deviceNetworkEdit',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.deviceNetworkEditor],
        allowedStorageFolders: ['deviceNetwork'],
      },
      deviceNetworkDestroy: {
        id: 'deviceNetworkDestroy',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.deviceNetworkEditor],
        allowedStorageFolders: ['deviceNetwork'],
      },
      deviceNetworkRead: {
        id: 'deviceNetworkRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.deviceNetworkEditor,
          roles.deviceNetworkViewer,
        ],
      },
      deviceNetworkAutocomplete: {
        id: 'deviceNetworkAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.deviceNetworkEditor,
          roles.deviceNetworkViewer,
          roles.deviceEditor,
          roles.deviceViewer,
        ],
      },

      organizationTypeImport: {
        id: 'organizationTypeImport',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.organizationTypeEditor],
      },
      organizationTypeCreate: {
        id: 'organizationTypeCreate',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.organizationTypeEditor],
        allowedStorageFolders: ['organizationType'],
      },
      organizationTypeEdit: {
        id: 'organizationTypeEdit',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.organizationTypeEditor],
        allowedStorageFolders: ['organizationType'],
      },
      organizationTypeDestroy: {
        id: 'organizationTypeDestroy',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.organizationTypeEditor],
        allowedStorageFolders: ['organizationType'],
      },
      organizationTypeRead: {
        id: 'organizationTypeRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.organizationTypeEditor,
          roles.organizationTypeViewer,
        ],
      },
      organizationTypeAutocomplete: {
        id: 'organizationTypeAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.organizationTypeEditor,
          roles.organizationTypeViewer,
          roles.storefrontEditor,
          roles.storefrontViewer,
          roles.organizationEditor,
          roles.organizationViewer,
        ],
      },

      categoryImport: {
        id: 'categoryImport',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.categoryEditor],
      },
      categoryCreate: {
        id: 'categoryCreate',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.categoryEditor],
        allowedStorageFolders: ['category'],
      },
      categoryEdit: {
        id: 'categoryEdit',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.categoryEditor],
        allowedStorageFolders: ['category'],
      },
      categoryDestroy: {
        id: 'categoryDestroy',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.categoryEditor],
        allowedStorageFolders: ['category'],
      },
      categoryRead: {
        id: 'categoryRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.categoryEditor,
          roles.categoryViewer,
        ],
      },
      categoryAutocomplete: {
        id: 'categoryAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.categoryEditor,
          roles.categoryViewer,
          roles.storefrontEditor,
          roles.storefrontViewer,
          roles.organizationEditor,
          roles.organizationViewer,
          roles.transactionEditor,
          roles.transactionViewer,
        ],
      },

      sectorImport: {
        id: 'sectorImport',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.sectorEditor],
      },
      sectorCreate: {
        id: 'sectorCreate',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.sectorEditor],
        allowedStorageFolders: ['sector'],
      },
      sectorEdit: {
        id: 'sectorEdit',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.sectorEditor],
        allowedStorageFolders: ['sector'],
      },
      sectorDestroy: {
        id: 'sectorDestroy',
        allowedRoles: [roles.owner, roles.editor, roles.entityEditor, roles.sectorEditor],
        allowedStorageFolders: ['sector'],
      },
      sectorRead: {
        id: 'sectorRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.sectorEditor,
          roles.sectorViewer,
        ],
      },
      sectorAutocomplete: {
        id: 'sectorAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.sectorEditor,
          roles.sectorViewer,
          roles.storefrontEditor,
          roles.storefrontViewer,
          roles.organizationEditor,
          roles.organizationViewer,
          roles.customerAudienceEditor,
          roles.customerAudienceViewer,
        ],
      },
      companiesRead: {
        id: 'companiesRead',
        allowedRoles: [roles.owner, roles.editor, roles.partnerViewer],
      },
      companiesCreate: {
        id: 'companiesCreate',
        allowedRoles: [roles.owner, roles.editor, roles.partnerEditor, roles.partnerViewer],
      },
      companiesEdit: {
        id: 'companiesEdit',
        allowedRoles: [roles.owner, roles.editor, roles.partnerEditor, roles.partnerViewer],
      },
      crmView: {
        id: 'crmView',
        allowedRoles: [roles.partnerEditor, roles.partnerViewer],
      },
      vendorContactViewer: {
        id: 'vendorContactViewer',
        allowedRoles: [roles.preferredVendorContactViewer],
      },
      toolsOverviewPage: {
        id: 'toolsOverviewPage',
        allowedRoles: [roles.toolsOverviewPage, roles.owner],
      },
      toolsPersonaBuilder: {
        id: 'toolsPersonaBuilder',
        allowedRoles: [roles.toolsPersonaBuilder, roles.owner],
      },
      toolsPlaceRank: {
        id: 'toolsPlaceRank',
        allowedRoles: [roles.toolsPlaceRank, roles.owner],
      },
      toolsRecipes: {
        id: 'toolsRecipes',
        allowedRoles: [roles.toolsRecipes, roles.owner],
      },
      webliftAttribution: {
        id: 'webliftAttribution',
        allowedRoles: [roles.webliftAttribution, roles.admin, roles.owner],
      },
      toolsSlideBuilder: {
        id: 'toolsSlideBuilder',
        allowedRoles: [roles.toolsSlideBuilder, roles.owner],
      },
      toolsCampaignPixelTracking: {
        id: 'toolsCampaignPixelTracking',
        allowedRoles: [roles.toolsCampaignPixelTracking, roles.owner],
      },
      toolsThreads: {
        id: 'toolsThreads',
        allowedRoles: [roles.toolsThreads, roles.owner],
      },
      toolsFindMarket: {
        id: 'toolsFindMarket',
        allowedRoles: [roles.toolsFindMarketsByProfessions, roles.owner],
      },
      toolsTracker: {
        id: 'toolsTracker',
        allowedRoles: [roles.toolsTracker, roles.owner],
      },
      toolsMaps: {
        id: 'toolsMaps',
        allowedRoles: [roles.toolsMaps, roles.owner],
      },
      toolsDeals: {
        id: 'toolsDeals',
        allowedRoles: [roles.toolsDeals, roles.owner],
      },
      leadGenerator: {
        id: 'leadGenerator',
        allowedRoles: [roles.leadGenerator],
      },
    };
  }

  static get asArray() {
    return Object.keys(this.values).map((value) => this.values[value]);
  }
}

export default Permissions;
