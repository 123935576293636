import develop from './develop';
import localhost from './localhost';
import production from './production';
import staging from './staging';
import testenv from './testenv';

const configs = {
  develop,
  localhost,
  production,
  staging,
  testenv,
};

export default configs[process.env.REACT_APP_ENVIRONMENT];
