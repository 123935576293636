/* eslint-disable react/no-multi-comp */
import camelCase from 'lodash/camelCase';
import LabelIcon from '@mui/icons-material/Label';
import LockOpenIcon from '@mui/icons-material/LockOpenOutlined';
import ReceiptIcon from '@mui/icons-material/ReceiptOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { colors, Drawer, List, ListSubheader } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { i18n } from 'i18n';
import authSelectors from 'modules/auth/authSelectors';
import PropTypes from 'prop-types';
import { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import { baseRouts } from 'view/routes';
import Label from './Label';
import NavItem from './NavItem';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import RateReviewIcon from '@mui/icons-material/RateReview';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CalendarOutlinesIcon from '@mui/icons-material/CalendarTodayOutlined';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import MergeIcon from '@mui/icons-material/Merge';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import InfoIcon from '@mui/icons-material/Info';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { useIsAnonymous, useIsSuperAdmin, useMarketplaceCapabilities } from '~/utils/hooks';
import { OSAIButtonTertiary } from '../shared/osaiButton';
import PermissionChecker from 'modules/auth/permissionChecker';
import Permissions from '~/security/permissions';

const permissions = Permissions.values;

const isProduction = process.env.REACT_APP_ALGOLIA_INDEX_PARTNER?.includes('prod') ?? true; // hacky but should works for now

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  navbarContainer: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    zIndex: 1100,
    [`& $desktopDrawer`]: {
      flex: '1 1 auto',
    },
    [`& $bugReportButton`]: {
      margin: '0.5em',
      alignSelf: 'flex-end',
    },
  },
  mobileDrawer: {
    [theme.breakpoints.up('lg')]: {
      display: 'none !important',
    },
  },
  mobileDrawerPaper: {
    width: 256,
  },
  desktopDrawer: {
    [theme.breakpoints.down('lg')]: {
      display: 'none !important',
    },
    ['& $desktopDrawerPaper']: {
      width: 256,
      top: '64px',
      height: 'calc(100% - 64px)',
      position: 'relative',
      zIndex: '1000',
    },
  },
  desktopDrawerPaper: {},
  navigation: {
    overflow: 'auto',
    padding: theme.spacing(0, 2, 2, 2),
    flexGrow: 1,
  },
  profile: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
  badgeDot: {
    height: 9,
    minWidth: 9,
  },
  onlineBadge: {
    backgroundColor: colors.green[600],
  },
  awayBadge: {
    backgroundColor: colors.orange[600],
  },
  busyBadge: {
    backgroundColor: colors.red[600],
  },
  offlineBadge: {
    backgroundColor: colors.grey[300],
  },
  avatar: {
    cursor: 'pointer',
    width: 40,
    height: 40,
  },
  details: {
    marginLeft: theme.spacing(2),
  },
  moreButton: {
    marginLeft: 'auto',
    color: colors.blueGrey[200],
  },
  bugReportButton: {},
}));

function renderNavItems({
  // eslint-disable-next-line react/prop-types
  items,
  subheader,
  key,
  permissionChecker,
  ...rest
}) {
  return (
    <List key={key}>
      {Boolean(subheader) && <ListSubheader disableSticky>{subheader}</ListSubheader>}
      {/* eslint-disable-next-line react/prop-types */}
      {items.reduce(
        // eslint-disable-next-line no-use-before-define
        (acc, item, index) => reduceChildRoutes({ acc, item, index, permissionChecker, ...rest }),
        [],
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0, index, permissionChecker }) {
  const userRolesMap = permissionChecker?.userRoles?.length
    ? permissionChecker?.userRoles?.map((d) => d.name)
    : [];
  const hasPermissions = userRolesMap.some((ai) =>
    item?.permissionRequired?.allowedRoles?.includes(ai),
  );

  if (
    (item?.hasOwnProperty('permissionRequired') &&
      item?.hasOwnProperty('isHidden') &&
      item?.isHidden === false &&
      hasPermissions === true) ||
    (item?.hasOwnProperty('permissionRequired') &&
      !item?.hasOwnProperty('isHidden') &&
      hasPermissions === true) ||
    (!item?.hasOwnProperty('permissionRequired') &&
      item?.hasOwnProperty('isHidden') &&
      item?.isHidden === false)
  ) {
    if (item.items) {
      const open = matchPath(pathname, {
        path: item.href,
        exact: false,
      });

      acc.push(
        <NavItem
          data-testid={`nav-left-category-${camelCase(item.title)}`}
          depth={depth}
          icon={item.icon}
          key={`nav-left-category-${item.href}${index}`}
          label={item.label}
          className={item.className}
          open={Boolean(open)}
          title={item.title}
        >
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items,
            label: item.label,
            permissionChecker,
          })}
        </NavItem>,
      );
    } else {
      if (item.className === 'navSidebarCategoryName') {
        acc.push(
          <Fragment key={`cat-${index}`}>
            <br />
            <span
              className={item.className}
              style={{
                fontFamily: 'proxima-nova',
                fontStyle: 'normal',
                fontWeight: '700',
                fontSize: '14px',
                textTransform: 'uppercase',
                padding: '0px 24px 8px 12px',
              }}
            >
              {item.title}
            </span>
            <br />
          </Fragment>,
        );
      } else if (item.className === 'break') {
        acc.push(
          <span key={`span-${index}`}>
            <hr
              key={`break-${index}`}
              style={{
                display: 'block',
                height: '1px',
                border: '0',
                borderTop: '1px solid #ececec',
                marginTop: '20px',
                width: '90%',
              }}
            />
          </span>,
        );
      } else {
        acc.push(
          <NavItem
            data-testid={`nav-left-category-${camelCase(item.label)}`}
            depth={depth}
            href={item.href}
            icon={item.icon}
            key={`nav-item-${item.href}${index}`}
            label={item.label}
            className={item.className}
            title={item.title}
          />,
        );
      }
    }
  }

  return acc;
}

function NavBar({ userRoles, openMobile, onMobileClose, doHide, className, currentUser, ...rest }) {
  if (!currentUser) {
    doHide();
    return null;
  }
  const permissionChecker = new PermissionChecker(currentUser);

  const classes = useStyles();
  const location = useLocation();
  const { tosAgreed, accountType } = currentUser;
  const anonymousUser = useIsAnonymous();
  const isAdmin = useIsSuperAdmin();
  const isSupplySidePartner = useMarketplaceCapabilities() === 'supply' ? true : false;
  const isDemandSidePartner = useMarketplaceCapabilities() === 'demand' ? true : false;
  const isResellerSidePartner = useMarketplaceCapabilities() === 'reseller' ? true : false;

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }

    if (anonymousUser) {
      doHide();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  let partnerSlug = null;
  if (currentUser?.partnerName?.slug) {
    partnerSlug = currentUser.partnerName.slug;
  }

  const mergePermission = (permissions) => {
    if (!permissions || permissions.length === 0) return [];
    let value = {};
    value['id'] = permissions.map((item) => item.id).join('-');
    value['allowedRoles'] = permissions.reduce((prevValue, currValue) => {
      return [...prevValue, ...currValue.allowedRoles];
    }, []);
    return value;
  };

  /*
    Two important visibility and permission toggled:
      isHidden: true or false, if false, reads specific permissions needed to access Page
      permissionRequired: checks logged in user for any permission matching in array of required permissions against what user actually has inherited from their group

    Example block:

      sectionName: {
        icon: HomeIcon,
        title: sectionFullText,
        href: '/href',
        isHidden: false,
        permissionRequired: permissions.campaignList
      }

  */

  const menuBlocks = {
    // campaigns: {
    //   title: 'Campaigns',
    //   href: `/${baseRouts.campaign}/list`,
    //   icon: CalendarOutlinesIcon,
    //   isHidden: isDemandSidePartner,
    //   permissionRequired: permissions.campaignList,
    // },
    campaignsDemand: {
      title: 'Campaigns Manager',
      href: `/${baseRouts.campaign}/agency`,
      icon: CalendarOutlinesIcon,
      isHidden: isSupplySidePartner,
      permissionRequired: permissions.campaignDemand,
    },
    myInventory: {
      title: 'My Inventory', // Ex Storefront
      href: `/my-inventory`,
      icon: StorefrontIcon,
      isHidden: !isSupplySidePartner,
    },
    transaction: {
      title: 'Transaction',
      href: '/transaction',
      icon: ReceiptIcon,
      isHidden: !isAdmin,
    },
    crm: {
      title: 'Customers & Vendors',
      href: `/${baseRouts.crm}`,
      icon: PeopleOutlineIcon,
      permissionRequired: mergePermission([permissions.crmView, permissions.vendorContactViewer]),
      isHidden: false,
      items: [
        {
          title: i18n('entities.crm.contacts'),
          href: `/${baseRouts.crm}/contacts/list`,
          permissionRequired: permissions.crmView,
          isHidden: false,
        },
        {
          title: i18n('entities.crm.customers'),
          href: `/${baseRouts.crm}/company/list`,
          permissionRequired: permissions.crmView,
          isHidden: false,
        },
        {
          title: i18n('entities.crm.preferredVendorContacts'),
          href: `/${baseRouts.crm}/vendor/list`,
          permissionRequired: permissions.vendorContactViewer,
          isHidden: false,
        },
      ],
    },
    leadgenerator: {
      title: 'Lead Generator',
      href: `/sales/${baseRouts.leadgen}`,
      icon: ListAltIcon,
      permissionRequired: permissions.leadGenerator,
    },
    tools: {
      title: 'Planning Tools',
      icon: HomeRepairServiceIcon,
      permissionRequired: permissions.toolsOverviewPage,
      items: [
        {
          title: 'Overview',
          href: `/tools/overview`,
          icon: InfoIcon,
          permissionRequired: permissions.toolsOverviewPage,
        },
        {
          title: 'Persona Builder',
          href: `/plan/persona`,
          icon: PersonSearchIcon,
          permissionRequired: permissions.toolsPersonaBuilder,
        },
        {
          title: 'PlaceRank Markets',
          href: `/placerank-market`,
          icon: ManageSearchIcon,
          permissionRequired: permissions.toolsPlaceRank,
        },
        {
          title: 'Recipes',
          href: `/recipes`,
          icon: MenuBookIcon,
          permissionRequired: permissions.toolsRecipes,
        },
        {
          title: 'Plan Builder',
          href: `/sales/${baseRouts.slidegen}`,
          icon: RateReviewIcon,
          permissionRequired: permissions.toolsSlideBuilder,
        },
        {
          title: 'Threads',
          href: `/threads`,
          icon: MarkEmailUnreadIcon,
          permissionRequired: permissions.toolsThreads,
        },
        {
          title: 'Find Markets by Professions',
          href: `/plan/profession`,
          icon: FindInPageIcon,
          permissionRequired: permissions.toolsPlaceRank,
        },
        {
          title: 'Find Market CPMs',
          href: `/plan/marketcpms`,
          icon: FindInPageIcon,
          permissionRequired: permissions.toolsPlaceRank,
        },
      ],
    },
    analytics: {
      title: 'Analytics',
      icon: EqualizerIcon,
      permissionRequired: permissions.toolsOverviewPage,
      items: [
        {
          title: 'Overview',
          href: `/${baseRouts.analytics}/overview`,
          icon: InfoIcon,
          permissionRequired: permissions.toolsOverviewPage,
        },
        {
          title: 'PinPoint',
          href: `/${baseRouts.analytics}/pinpoint`,
          icon: PersonSearchIcon,
          permissionRequired: permissions.toolsPersonaBuilder,
        },
        {
          title: 'Unique Maids',
          href: `/${baseRouts.analytics}/unique-maids`,
          icon: MergeIcon,
          permissionRequired: permissions.toolsCampaignPixelTracking,
        },
        {
          title: 'Pixel',
          href: `/${baseRouts.analytics}/pixel`,
          icon: ManageSearchIcon,
          permissionRequired: permissions.toolsCampaignPixelTracking,
        },
        {
          title: 'Weblift Attribution',
          href: `/${baseRouts.analytics}/weblift`,
          icon: MenuBookIcon,
          permissionRequired: permissions.webliftAttribution,
        },
      ],
    },
    tagging: {
      title: 'Tagging',
      href: '/tag',
      icon: LabelIcon,
      isHidden: false,
      permissionRequired: permissions.tagEditor,
      items: [
        {
          title: 'Tag',
          href: '/tag',
          isHidden: false,
          permissionRequired: permissions.tagEditor,
        },
        {
          title: 'Category',
          href: '/category',
          isHidden: false,
          permissionRequired: permissions.tagEditor,
        },
        {
          title: 'Status',
          href: '/status',
          isHidden: false,
          permissionRequired: permissions.tagEditor,
        },
        {
          title: 'Organization Type',
          href: '/organization-type',
          isHidden: false,
          permissionRequired: permissions.tagEditor,
        },
        {
          title: 'Organization Sector',
          href: '/sector',
          isHidden: false,
          permissionRequired: permissions.tagEditor,
        },
      ],
    },
    settings: {
      title: i18n('settings.menu'),
      href: '/settings',
      icon: SettingsIcon,
      isHidden: false,
      items: [
        {
          title: 'Profile',
          href: '/profile',
          isHidden: false,
        },
      ],
    },
    security: {
      title: 'Security',
      href: '#',
      icon: LockOpenIcon,
      isHidden: false,
      items: [
        {
          title: 'Users',
          href: `/${baseRouts.iam}`,
          isHidden: false,
        },
        {
          title: 'Groups',
          href: '/permission',
          isHidden: !isAdmin,
        },
        {
          title: i18n('auditLog.menu'),
          href: '/audit-logs',
          isHidden: !isAdmin,
        },
      ],
    },
  };

  // Define order of blocks to add to rendered menu
  const dynamicLoadedMenuOnPermissions = [];
  dynamicLoadedMenuOnPermissions.push(menuBlocks.home);
  dynamicLoadedMenuOnPermissions.push(menuBlocks.myOohPage);
  dynamicLoadedMenuOnPermissions.push(menuBlocks.myInventory);
  dynamicLoadedMenuOnPermissions.push(menuBlocks.storefront);
  dynamicLoadedMenuOnPermissions.push(menuBlocks.campaignsDemand);
  dynamicLoadedMenuOnPermissions.push(menuBlocks.campaigns);
  dynamicLoadedMenuOnPermissions.push(menuBlocks.crm);
  dynamicLoadedMenuOnPermissions.push(menuBlocks.leadgenerator);
  dynamicLoadedMenuOnPermissions.push(menuBlocks.tools);
  dynamicLoadedMenuOnPermissions.push(menuBlocks.oohgpt);
  dynamicLoadedMenuOnPermissions.push(menuBlocks.analytics);
  dynamicLoadedMenuOnPermissions.push(menuBlocks.invoices);
  dynamicLoadedMenuOnPermissions.push(menuBlocks.tagging);
  dynamicLoadedMenuOnPermissions.push(menuBlocks.settings);
  dynamicLoadedMenuOnPermissions.push(menuBlocks.security);

  dynamicLoadedMenuOnPermissions.push(
    {
      title: ``,
      className: 'break',
      href: '#',
      icon: null,
      isHidden: false,
    },
    {
      title: `Public View`,
      className: 'navSidebarCategoryName',
      href: '#',
      icon: null,
      isHidden: false,
    },
    {
      title: 'Partner Directory',
      href: `/${baseRouts.ooh}`,
      icon: PeopleAltIcon,
      isHidden: false,
    },
    // {
    //   title: 'Inventory Directory',
    //   href: `/${baseRouts.location}`,
    //   icon: ViewComfyIcon,
    //   isHidden: false,
    // },
    {
      title: 'Inventory Directory',
      href: `/inventory-directory`,
      icon: TravelExploreIcon,
      isHidden: false,
      permissionRequired: permissions.inventoryDirectoryV3,
    },
  );

  const getLoggedInAsText = () => {
    let text = '';
    if (isSupplySidePartner) {
      text = 'supply partner';
    } else if (isDemandSidePartner) {
      text = 'demand partner';
    } else if (isResellerSidePartner) {
      text = 'reseller';
    } else {
      text = 'employee';
    }

    if (isAdmin) {
      text = 'employee';
    }

    return text;
  };

  const navConfig = [
    {
      subheader: '',
      items: dynamicLoadedMenuOnPermissions,
    },
    {
      subheader: '',
      isHidden: false,
      items: [
        {
          title: 'Welcome',
          icon: null,
          isHidden: false,
          label: () => (
            <Label key="welcome-01" color={colors.green['500']}>
              {getLoggedInAsText()}
            </Label>
          ),
        },
      ],
    },
  ];
  // eslint-disable-next-line unused-imports/no-unused-vars
  const { userAccountType, dispatch, ...contentRestProps } = { ...rest };
  const content = (
    <div {...contentRestProps} className={clsx(classes.root, className)}>
      <nav className={classes.navigation}>
        {navConfig.map((list, index) =>
          renderNavItems({
            items: list.items,
            subheader: list.subheader,
            pathname: location.pathname,
            key: `${list.subheader}${index}`,
            permissionChecker,
          }),
        )}
      </nav>
    </div>
  );
  const noContent = <></>;

  if (anonymousUser) {
    return noContent;
  }

  const BUG_REPORT_URL = process.env.REACT_APP_BUG_REPORT_URL;
  const onBugReportButtonCLicked = () => {
    window.showDialog?.();
  };
  const setShowDialog = (showCollectorDialog) => {
    // Using global scope because of issues with local scope
    window.showDialog = showCollectorDialog;
  };
  window['ATL_JQ_PAGE_PROPS'] = {
    triggerFunction: setShowDialog,
    fieldValues: {
      // NOTE: The parameter 'customfield_10031' is really dependent from
      // the issue collection's specific instance that was created.
      // If another instance is created in prod, for example, this value has to be updated.
      customfield_10031: '10020',
    },
  };

  useEffect(() => {
    if (!window.showDialog && BUG_REPORT_URL) {
      const head = document.querySelector('head');
      const script = document.createElement('script');

      script.type = 'text/javascript';
      script.src = BUG_REPORT_URL;

      head.appendChild(script);
    }
  }, []);

  const generateReportBugButton = () => {
    if (permissionChecker.rolesMatchOneOf('reportABug')) {
      return (
        <OSAIButtonTertiary className={classes.bugReportButton} onClick={onBugReportButtonCLicked}>
          Report a bug
        </OSAIButtonTertiary>
      );
    }
    return '';
  };

  return (
    <div className={classes.navbarContainer}>
      <Drawer
        anchor="left"
        className={classes.mobileDrawer}
        classes={{
          paper: classes.mobileDrawerPaper,
        }}
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
      >
        {tosAgreed || isSupplySidePartner ? content : noContent}
        {generateReportBugButton()}
      </Drawer>
      <Drawer
        anchor="left"
        className={classes.desktopDrawer}
        classes={{
          paper: classes.desktopDrawerPaper,
        }}
        open
        variant="persistent"
      >
        {tosAgreed || isSupplySidePartner ? content : noContent}
      </Drawer>
      {generateReportBugButton()}
    </div>
  );
}

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  doHide: PropTypes.func,
  openMobile: PropTypes.bool,
  userRoles: PropTypes.array,
  userAccountType: PropTypes.string,
  currentUser: PropTypes.shape({
    tosAgreed: PropTypes.bool,
    accountType: PropTypes.string,
    email: PropTypes.string,
    partnerName: PropTypes.shape({
      slug: PropTypes.string,
    }),
    groupName: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
};

function select(state) {
  return {
    userRoles: authSelectors.selectRoles(state),
    userAccountType: authSelectors.selectAccountType(state),
    currentUser: authSelectors.selectCurrentUser(state),
  };
}

// Export default NavBar;

export default connect(select)(NavBar);
